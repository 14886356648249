import React from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import {BrowserRouter, Routes} from "react-router-dom";
import App from "./App";

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
document.head.appendChild(link);

document.addEventListener('DOMContentLoaded', function () {
    createRoot(document.body.appendChild(document.createElement('root')))
        .render(
            /*<React.StrictMode>*/
                <BrowserRouter>
                        <App/>
                </BrowserRouter>
            /*</React.StrictMode>*/
        )
})
