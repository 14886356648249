import React, {useEffect, useRef, useState, usePrevious} from "react";
import axios from 'axios';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

const apiUrl = process.env.REACT_APP_API_URL;

let current_idx_file=0;
let refresh_send=false;

function DeviceFilesTransfer() {
    const [updatesend, setUpdatesend] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [selectedFilesListStatus, setselectedFilesListStatus] = useState([]);    
    
    const inputFileRef = useRef(null);
    const cb_enginRef = useRef(null); 
    const cb_pietonRef = useRef(null); 
    const tf_serialRef = useRef();       


    useEffect(() => {
        if(updatesend===true)
        {
            setUpdatesend(false);
            if(selectedFilesListStatus.length>0)
            {

                current_idx_file = 9999;
                for (var i = 0; i < selectedFilesListStatus.length; i++) {
                    if(selectedFilesListStatus[i].status==1)
                        current_idx_file = i;             
                }
                if(current_idx_file!==9999)
                    console.log('Sending : '+selectedFilesListStatus[current_idx_file].filename);
    
                if(current_idx_file!==9999)
                {
                    const SendFile = async () => {
                        const data = new FormData();
                        data.append('filename', selectedFilesListStatus[current_idx_file].filename);                    
                        data.append('file', selectedFiles[current_idx_file]);
                        await axios.post(apiUrl + "/api/admin/devFileUpload", data, {
                                headers: {'x-access-token': sessionStorage.getItem("token")}
                            }).then(res => {
                                if (res.statusText === 'OK') {
                                    selectedFilesListStatus[current_idx_file].status = 2;
                                } else {
                                    alert("Firmware send error");
                                    selectedFilesListStatus[current_idx_file].status = 3;
                                }
                                setUpdatesend(true);
                            })
                      };
                      SendFile();
                }
            }
        }
    }, [updatesend]);

    useEffect(() => {

    }, [selectedFilesListStatus]); 


    useEffect(() => {
        if(selectedFiles!=null)
        {
            const array = [];
            for (var i = 0; i < selectedFiles.length; i++) {
                array.push(
                    {
                        filename : selectedFiles[i].name,
                        status : 0
                    });                
            }
            setselectedFilesListStatus(array);        
        }
    }, [selectedFiles]); 

    
    const ButtonSelectFolderHandler = () => {
        inputFileRef.current.click();               
    }

    const onSelectedFolderChangeHandler = (event) => {
        setSelectedFiles(event.target.files);
    }

    const ButtonUploadHandler = () => { 
        
        var regex = /[0-9A-Fa-f]{6}/g;

        if((!cb_pietonRef.current.checked)&&(!cb_enginRef.current.checked))
            alert('Selectionner au moins un type de terminal');
        else if((cb_pietonRef.current.checked)&&(cb_enginRef.current.checked))
            alert('Selectionner un seul type de terminal');  
        else if(tf_serialRef.current.value.length != 6)
            alert('Le numero de serie doit etre composé de 6 caracteres');
        else if(!tf_serialRef.current.value.match(regex))
            alert('Le format du numero de serie n\'est pas valide');        
        else
        {
            var startstr='';
            if(cb_enginRef.current.checked)
                startstr = tf_serialRef.current.value.toUpperCase()+'-E';
            else if(cb_pietonRef.current.checked)
                startstr = tf_serialRef.current.value.toUpperCase()+'-K';
            
            const tab = selectedFilesListStatus;
            selectedFilesListStatus.forEach(element => {
                element.filename = startstr+element.filename;
                element.status=1;
            });
            setUpdatesend(true);
        }                   
    }
    

    

    return (
        <div>
            <input type='file' id='file' ref={inputFileRef} style={{display: 'none'}} onChange={(event) => onSelectedFolderChangeHandler(event)} multiple/>
            <div >
                <div style={{display: "flex"}}>
                    <Button variant="contained" onClick={(event) => ButtonSelectFolderHandler()}>Select Files </Button>
                    <TextField inputRef={tf_serialRef} style={{marginLeft: '50px'}} id="textfield_serial" label="Numéro de série" variant="outlined" />
                    <FormControlLabel style={{marginLeft: '10px'}} control={<Checkbox inputRef={cb_enginRef} defaultChecked/>} label="Engin" />
                    <FormControlLabel control={<Checkbox inputRef={cb_pietonRef} />} label="Pieton" />                
                </div>
                {selectedFilesListStatus.length>0?<div style={{marginTop: '20px',textAlign: 'center'}}><Button size="medium" variant="contained" color="secondary" onClick={(event) => ButtonUploadHandler()}>Upload </Button></div>:""}
                {selectedFilesListStatus.length>0?
                <div style={{textAlign: 'center'}}>
                    <div style={{marginTop: '30px',display:'inline-block'}}>
                        <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800,minWidth: 800 }} aria-label="simple table">
                                <TableHead>
                                <TableRow sx={{backgroundColor: 'rgba(127, 127, 127, 0.5)'}}>
                                    <TableCell>Filename</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {selectedFilesListStatus.map((item) => (
                                    <TableRow key={item.filename} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">{item.filename}</TableCell>
                                        <TableCell align="right">
                                        
                                            {item.status === 0 ? <PauseCircleOutlineIcon />:""}
                                            {item.status === 1 ? <CircularProgress />:""}
                                            {item.status === 2 ? <CheckCircleOutlineIcon/>:""}
                                            {item.status === 3 ? <ErrorOutlineIcon />:""}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                :""
                }
            </div>
            
        </div>

    );
}

export default DeviceFilesTransfer;