import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Timeline from 'fix-react-vis-timeline';
import Card from '@mui/material/Card';
import TooltipMaterial from '@mui/material/Tooltip';
import CardContent from '@mui/material/CardContent';
import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Input } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Button from "@mui/material/Button";
import Loader from 'Public/images/loader.gif';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { Modal } from "react-bootstrap";
import "../user/DashboardProduction/DashboardProduction.css";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const apiUrl = process.env.REACT_APP_API_URL;

function Sim() {
    const [selectedStopDate, setSelectedStopDate] = useState(new Date());
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWorksite, setSelectedWorksite] = useState("");
    const [UserSerialsandNames, setUserSerialsAndNames] = useState([]);
    const [dailyDatas, setDailyDatas] = useState([]);
    const [dailyUserDatas, setDailyUserDatas] = useState([]);
    const [Worksite, setWorksite] = useState([]);
    const [Picto, setPicto] = useState([]);
    const [Rotation, setRotation] = useState([]);
    const [DatasModal, setDatasModal] = useState([]);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showRotationModal, setShowRotationModal] = useState(false);
    const [showConsommationModal, setShowConsommationModal] = useState(false);
    const [showPeriodModal, setShowPeriodModal] = useState(false);
    const [rotationSerialModal, setRotationSerialModal] = useState([]);
    const [itemPeriod, setItemPeriod] = useState([]);
    const [consommationSerialModal, setConsommationSerialModal] = useState([]);
    const [deviceName, setDeviceName] = useState([]);
    const [backgroundColors, setBackgroundColors] = useState([]);
    const sortedUserDatas = [...dailyUserDatas];
    const [email, setEmail] = useState('');
    const [numberOfDays, setNumberOfDays] = useState(1);
    const [changeDate, setChangeDate] = useState(false);

    sortedUserDatas.sort((a, b) => {
        // Comparez d'abord par famille
        const familleComparison = a.familyName.localeCompare(b.familyName);
        // Si les familles sont différentes, retournez la comparaison par famille
        if (familleComparison !== 0) {
            return familleComparison;
        }

        // Si les familles sont les mêmes, comparez par ordre alphanumérique des noms d'appareils
        const nameA = getNamebySerial(a.serial);
        const nameB = getNamebySerial(b.serial);
        return nameA.localeCompare(nameB);
    });

    useEffect(() => {
        if (selectedWorksite !== "") {
            var start, stop;
            start = selectedStartDate;
            stop = selectedStopDate;

            const dateStart = new Date(selectedStartDate);
            const dateStop = new Date(selectedStopDate);

            dateStart.setHours(0, 0, 1);
            start = new Date(dateStart);

            dateStop.setHours(23, 59, 59);
            stop = new Date(dateStop);

            if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
                axios.get(apiUrl + `/api/admin/serialsforadmin`, {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                    .then(userRes => {
                        // Extraire les numéros de série de l'utilisateur à partir de la réponse
                        const userSerialNumbers = userRes.data.map(user => user.username);
                        // Requête Axios POST pour récupérer les numéros de série de la période
                        axios.post(apiUrl + `/api/user/serialsperiod`,
                            { startdate: start, stopdate: stop, worksiteid: selectedWorksite },
                            { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                            .then(periodRes => {
                                setDailyUserDatas("");
                                setDailyDatas("");
                                const filteredSerialObjects = periodRes.data.filter(obj => userSerialNumbers.includes(obj.serial));
                                setUserSerialsAndNames(filteredSerialObjects);
                            })
                    })
            } else {
                axios.get(apiUrl + `/api/user/serialsforuser`, {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                    .then(userRes => {
                        // Extraire les numéros de série de l'utilisateur à partir de la réponse
                        const userSerialNumbers = userRes.data.map(user => user.username);
                        // Requête Axios POST pour récupérer les numéros de série de la période
                        axios.post(apiUrl + `/api/user/serialsperiod`,
                            { startdate: start, stopdate: stop, worksiteid: selectedWorksite },
                            { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                            .then(periodRes => {
                                setDailyUserDatas("");
                                setDailyDatas("");
                                const filteredSerialObjects = periodRes.data.filter(obj => userSerialNumbers.includes(obj.serial));
                                setUserSerialsAndNames(filteredSerialObjects);
                            })
                    })
            }
        }
    }, [selectedStartDate, selectedStopDate, selectedWorksite]);

    useEffect(() => {
        if (selectedWorksite !== "") {
            setIsLoading(true);
            var start, stop;
            start = selectedStartDate;
            stop = selectedStopDate;

            const dateStart = new Date(selectedStartDate);
            const dateStop = new Date(selectedStopDate);

            dateStart.setHours(0, 0, 1);
            start = new Date(dateStart);

            dateStop.setHours(23, 59, 59);
            stop = new Date(dateStop);


            axios.post(apiUrl + `/api/user/dashboardproduction`, {
                startdate: start, stopdate: stop, selectedWorksite: selectedWorksite
            }, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(async res => {
                    if (res.data.length > 0 && UserSerialsandNames.length > 0) {
                        const aggregatedData = res.data.filter(obj => {
                            const userSerial = UserSerialsandNames.find(userObj => userObj.serial === obj.serial);
                            return userSerial;
                        });
                        const timeDifference = Math.max.apply(
                            Math,
                            aggregatedData.map(function (o) {
                                return o.timeDifference;
                            })
                        );
                        const familyData = {};

                        for (const data of aggregatedData) {
                            const FamilyName = await getFamilyByIdDeviceType(data.device_type);
                            data.familyName = FamilyName;

                            if (!familyData[FamilyName]) {
                                familyData[FamilyName] = {
                                    totalDistance: 0,
                                    count: 0,
                                    maxDistance: 0
                                };
                            }


                            familyData[FamilyName].totalDistance += data.totalDistance;
                            familyData[FamilyName].count += 1;

                            if (data.totalDistance > familyData[FamilyName].maxDistance) {
                                familyData[FamilyName].maxDistance = data.totalDistance;
                            }
                        }


                        // Calculer la moyenne des distances par famille
                        for (const FamilyName in familyData) {
                            familyData[FamilyName].averageDistance = familyData[FamilyName].totalDistance / familyData[FamilyName].count;
                        }

                        aggregatedData.sort((a, b) => a.familyName.localeCompare(b.familyName));


                        const timeDifferenceFirstMoveAndMinDate = aggregatedData
                            .map((o) => o.timeDifference)
                            .filter((timeDifference) => timeDifference >= 0);

                        // Calcul de la somme des valeurs
                        const sum = timeDifferenceFirstMoveAndMinDate.reduce((acc, currentValue) => acc + currentValue, 0);


                        // Calcul de la moyenne
                        const averageTimeDifference = sum / timeDifferenceFirstMoveAndMinDate.length;

                        const totalDistance = aggregatedData.reduce((acc, data) => acc + data.totalDistance, 0);
                        const totalLoadTransported = aggregatedData.reduce((acc, data) => acc + data.loadTransported, 0);
                        const totalMoving_cnt = aggregatedData.reduce((acc, data) => acc + data.moving_cnt, 0);
                        const totalStop_cnt = aggregatedData.reduce((acc, data) => acc + data.stop_cnt, 0);
                        let totalSecondesMovingTime = 0;
                        let totalSecondesStopTime = 0;
                        aggregatedData.forEach(item => {
                            totalSecondesMovingTime += dureeEnSecondes(item.formattedMovingTime);
                            totalSecondesStopTime += dureeEnSecondes(item.formattedStopTime);
                        })
                        const totalDureeMovingTime = secondesEnDuree(totalSecondesMovingTime);
                        const totalDureeStopTime = secondesEnDuree(totalSecondesStopTime);
                        const maxSpeedValue = Math.max.apply(
                            Math,
                            aggregatedData.map(function (o) {
                                return o.maxSpeed;
                            })
                        );
                        const DailyDatas = [{
                            maxspeed: maxSpeedValue,
                            formattedMovingTime: totalDureeMovingTime,
                            formattedStopTime: totalDureeStopTime,
                            totalDistance: totalDistance,
                            totalDistanceByFamily: familyData,
                            moving_cnt: totalMoving_cnt,
                            stop_cnt: totalStop_cnt,
                            totalLoadTransported: totalLoadTransported,
                            timeDifferenceFirstMoveAndMinDate: millisecondesEnDuree(timeDifference),
                            averageTimeDifferenceFirstMoveAndMinDate: millisecondesEnDuree(averageTimeDifference)
                        }];
                        setDailyUserDatas(aggregatedData);
                        setDailyDatas(DailyDatas);
                        setIsLoading(false);
                        setChangeDate(false)
                    } else {
                        setDailyUserDatas("");
                        setDailyDatas("");
                        setIsLoading(false);
                        setChangeDate(false)
                    }
                })
        }
    }, [UserSerialsandNames]);


    function getPictoByTypeDevice(type) {

        const picto = Picto.find(device => device.id_device_type === type);

        if (picto)
            return picto.picto;
    }

    const dureeEnSecondes = (duree) => {
        const parties = duree.split(/[hms]/).filter((partie) => partie !== "");
        const heures = parseInt(parties[0]) || 0;
        const minutes = parseInt(parties[1]) || 0;
        const secondes = parseInt(parties[2]) || 0;
        return heures * 3600 + minutes * 60 + secondes;
    };

    async function getRatioColorbyFamily(device_type, move, stop) {
        if (UserSerialsandNames.length > 0) {
            const worksite = Worksite.find(worksite => worksite.worksiteid === selectedWorksite);
            const worksiteParameters = JSON.parse(worksite.worksiteparameters);
            const FamilyName = await getFamilyByIdDeviceType(device_type);

            const total = move + stop;
            const percentage = ((stop / total) * 100);
            if (worksiteParameters !== null && FamilyName !== undefined) {
                if (worksiteParameters.production.families_ratio !== undefined) {
                    if (worksiteParameters.production.families_ratio[FamilyName] > percentage)
                        return 'rgba(255, 99, 132, 0.2)';
                    else {
                        return "red"
                    }
                } else
                    return 'rgba(255, 99, 132, 0.2)'
            } else {
                return 'rgba(255, 99, 132, 0.2)';
            }
        }

    }

    function getFamilyByIdDeviceType(idDeviceType) {
        return axios.post(apiUrl + `/api/user/familybyDevice`, {
            id_device_type: idDeviceType
        }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                // Renvoyer les données nécessaires
                return res.data[0].device_types_family.family.family_name;
            })
    }

    function getTotalTransportedforSerial(serial, rotation) {
        const Device = deviceName.find(device => device.username === serial);
        if (Device)
            return (Device.payload * rotation) / 1000
    }


    const millisecondesEnDuree = (millisecondes) => {
        const secondsDifference = Math.floor(millisecondes / 1000);
        const heures = Math.floor(secondsDifference / 3600);
        const minutes = Math.floor((secondsDifference % 3600) / 60);
        const secondesRestantes = secondsDifference % 60;
        let duree;

        if (heures === 0)
            duree = `${minutes.toString().padStart(2, "0")}m${secondesRestantes.toString().padStart(2, "0")}s`;
        if (minutes === 0)
            duree = `${secondesRestantes.toString().padStart(2, "0")}s`;
        if (heures > 0)
            duree = `${heures.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}m${secondesRestantes.toString().padStart(2, "0")}s`;

        return duree;

    }

    const secondesEnDuree = (secondes) => {
        const heures = Math.floor(secondes / 3600);
        const minutes = Math.floor((secondes % 3600) / 60);
        const secondesRestantes = secondes % 60;
        return `${heures.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}m${secondesRestantes.toString().padStart(2, "0")}s`;
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalPeriodClose = () => {
        setShowPeriodModal(false);
    };

    const handleOpenRotationModal = (serial) => {
        setRotationSerialModal(GetProdEventsForSerial(serial))
        setShowRotationModal(true);
    };
    

    const handleOpenModalPeriod = (item) => {
        setItemPeriod(item)
        setShowPeriodModal(true);
    };

    const handleOpenConsommationModal = (Consommation, Serial) => {
        const ConsommationWithSerial = [
            {
                serial: Serial,
                consommation: Consommation
            }
        ]
        setConsommationSerialModal(ConsommationWithSerial)

        setShowConsommationModal(true);
    };

    const handleModalRotationClose = () => {
        setShowRotationModal(false);
    };

    const handleModalConsommationClose = () => {
        setShowConsommationModal(false);
    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        setSelectedStopDate(date);
    };


    function getNamebySerial(serial) {
        if (UserSerialsandNames.length > 0) {
            const device = UserSerialsandNames.find(device => device.serial === serial);
            let selectedDevice = "noname";
            let NameDevice = [];
            if (device)
                NameDevice = JSON.parse(device.device.name_device);
            let StartDate = selectedStartDate.setHours(0, 0, 1);
            if (NameDevice.length > 1) {

                for (const item of NameDevice) {
                    const itemDate = new Date(item.timestamp);
                    const startDate = new Date(StartDate);

                    if (itemDate < startDate) {
                        selectedDevice = item.nameDevice;
                    }
                }
            } else {
                const itemDate = new Date(NameDevice.timestamp);
                const startDate = new Date(StartDate);
                if (itemDate < startDate)
                    selectedDevice = NameDevice.nameDevice;
            }
            let str = selectedDevice;
            if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN"))
                str = str + '(' + serial + ')';
            return str;
        }
    }


    useEffect(() => {
        axios.get(apiUrl + `/api/user/worksite`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {

                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
            })

        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setPicto(res.data);
            })
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })
        } else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })
        }
    }, []);


    useEffect(() => {
        if (Worksite.length > 0) {
            setSelectedWorksite(Worksite[0].worksiteid)
        }
    }, [Worksite]);


    useEffect(() => {
        var start, stop;
        start = selectedStartDate;
        stop = selectedStopDate;

        const dateStart = new Date(selectedStartDate);
        const dateStop = new Date(selectedStopDate);

        start = dateStart.setHours(0, 0, 1);
        stop = dateStop.setHours(23, 59, 59);
        if (selectedWorksite !== "") {

            axios.post(apiUrl + `/api/user/rotation`, {
                startdate: start, stopdate: stop, selectedWorksite: selectedWorksite
            }, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    console.log(res);
                    const transformedData = res.data[0].rotationAlgo.map(algo => {
                        const serial = algo.serial;
                        const cumul_list = [];
                        const uniqueKeys = new Set();
                      
                        for (const [key, value] of Object.entries(algo.materialStock)) {
                          const [material, stock] = key.split('_');
                          if (!uniqueKeys.has(key)) {
                            uniqueKeys.add(key);
                            cumul_list.push({
                              stock: stock,
                              material: material,
                              rotation_count: algo.materialStockCounts[key],
                              task_duration: algo.cumulativeTime[key]
                            });
                          }
                        }
                      
                        const last_rotation_ts = Math.max(...Object.values(algo.materialStock).flat().map(item => new Date(item.timestamp).getTime()));
                      
                        return {
                          serial: serial,
                          cumul_list: cumul_list,
                          total_rotcnt: algo.count,
                          last_rotation_ts: last_rotation_ts
                        };
                      });
                      

                    res.data[1].prod_result.forEach(prod => {
                        // Vérifier si l'élément existe déjà dans transformedData
                        const exists = transformedData.some(item => item.serial === prod.serial);
                    
                        // Si l'élément n'existe pas, l'ajouter à transformedData
                        if (!exists) {
                            transformedData.push(prod);
                        }
                    });

                    setRotation(transformedData)
                })
        }

    }, [selectedStartDate, selectedStopDate, selectedWorksite]);

    const handleWorksiteChange = (event) => {
        setSelectedWorksite(event.target.value);
    };

    function formatDateWithTime(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        let dateFormat = <div><span>{day}/{month}/{year}<br />{hours}:{minutes}:{seconds}</span></div>;
        if (selectedStartDate.toDateString() === selectedStopDate.toDateString() || changeDate === true)
            dateFormat = <div><span>{hours}:{minutes}:{seconds}</span></div>;


        return dateFormat;
    }

    function formatDateModal(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;
    }

    function CountRotationBySerial(serial) {
        if (Rotation.length > 0) {
            const device = Rotation.filter(rotation => rotation.serial.split(' ').join('') === serial);
            if (device.length > 0) {
                if (device[0].count > 0)
                    return device[0].count
                else
                    return 0;
            }
            return 0;
        }
        return 0;
    }

    function GetProdEventsForSerial(serial) {
        
        if (Rotation.length > 0) {
            
            const device = Rotation.filter(rotation => rotation.serial === serial);
            if (device.length > 0) {
                return device[0]
            }
            return 0;
        }
        return 0;
    }

    function CountRotation() {
        let totalCount = 0;
        if (Rotation.length > 0) {
            Rotation.forEach(rotation => {
                if (rotation.count) {
                    totalCount += rotation.count;
                }
            });
            return totalCount;
        }
        return 0;
    }

    function GetTotalLoadTransported() {
        let totalCount = 0;
        if (Rotation.length > 0) {
            Rotation.forEach(rotation => {
                if (rotation.count) {
                    totalCount += getTotalTransportedforSerial(rotation.serial, rotation.count);
                }
            });

            return totalCount;
        }
        return 0;
    }

    function Goto_ReplayWithSerial(serial) {

        let serialSelected = [];
        serialSelected.push(serial);

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            navigate('/admin/replay', {
                state: {
                    start: new Date(selectedStartDate),
                    stop: new Date(selectedStopDate),
                    worksite: selectedWorksite,
                    serials: serialSelected
                }
            });
        } else {
            navigate('/user/replay', {
                state: {
                    start: new Date(selectedStartDate),
                    stop: new Date(selectedStopDate),
                    worksite: selectedWorksite,
                    serials: serialSelected
                }
            });
        }
    }

    function Goto_Replay(date, serials_toAff, selectedWorksite) {

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            navigate('/admin/Replay', {
                state: {
                    ts: new Date(date),
                    serials: serials_toAff,
                    worksite: selectedWorksite
                }
            });
        } else {
            navigate('/user/Replay', {
                state: {
                    ts: new Date(date),
                    serials: serials_toAff,
                    worksite: selectedWorksite
                }
            });
        }
    }

    function Goto_ManageRotation(dateStart, dateStop, selectedWorksite) {

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            navigate('/admin/ManageRotation', {
                state: {
                    start: new Date(dateStart),
                    stop: new Date(dateStop),
                    worksite: selectedWorksite
                }
            });
        } else {
            navigate('/user/ManageRotation', {
                state: {
                    start: new Date(dateStart),
                    stop: new Date(dateStop),
                    worksite: selectedWorksite
                }
            });
        }
    }

    const ButtonWatchPartialReplayHandler = (serial, timestamp, selectedWorksite) => {
        let array = [];
        array.push(serial);
        Goto_Replay(timestamp, array, selectedWorksite);

    }

    useEffect(() => {

        if (dailyUserDatas.length > 0) {
            const fetchData = async () => {
                const colors = await Promise.all(
                    dailyUserDatas.map(async (item) => {
                        try {
                            return await getRatioColorbyFamily(item.device_type, item.moving_cnt, item.stop_cnt);
                        } catch (error) {
                            console.error('Error fetching color:', error);
                            return ""; // ou renvoyez une autre valeur par défaut si nécessaire
                        }
                    })
                );

                setBackgroundColors(colors);
            };

            fetchData();
        }
    }, [dailyUserDatas]);

    function formatTime(milliseconds) {
        if (milliseconds !== undefined) {
            const totalSeconds = Math.floor(milliseconds / 1000);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            let time = `${String(hours).padStart(2, '0')}h${String(minutes).padStart(2, '0')}m${String(seconds).padStart(2, '0')}s`;
            return time;
        } else
            return "0s"
    }

    function getLastDateRotation(data) {
        let derniereDate = null;
        if (data.length > 0) {
            data.forEach(rotation => {
                Object.keys(rotation.materialStock).forEach(key => {
                    const timestamps = rotation.materialStock[key].map(item => new Date(item.timestamp).getTime());
                    const maxTimestamp = Math.max(...timestamps);
                    if (derniereDate === null || maxTimestamp > derniereDate) {
                        derniereDate = maxTimestamp;
                    }
                });
            });
        }

        return derniereDate ? new Date(derniereDate) : null;
    }

    function getPostTime(period) {
        let totalDifference = 0;

        if (period.length > 0) {
            period.forEach(([start, stop]) => {
                const startTime = new Date(start);
                const stopTime = new Date(stop);
                const difference = stopTime - startTime; // en millisecondes

                totalDifference += difference;
            });
        }

        return millisecondesEnDuree(totalDifference);
    }

    function countDays(startDate, stopDate) {
        // Convertir les chaînes de date en objets Date
        const start = new Date(startDate);
        const stop = new Date(stopDate);

        // Obtenir le temps en millisecondes entre les deux dates
        const timeDifference = stop.getTime() - start.getTime();

        // Calculer le nombre de jours
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;

        return daysDifference;
    }

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSendMail = () => {

        const start = selectedStartDate.setHours(0, 0, 1);
        const end = selectedStopDate.setHours(23, 59, 59);

        axios.post(apiUrl + `/api/admin/sendmailrotation`,
            { startdate: start, stopdate: end, selectedWorksite: selectedWorksite, email },
            { headers: { 'x-access-token': sessionStorage.getItem("token") } })
            .then(res => {
                alert(res.data);
            })
    };

    function GetEndDate(stopDate) {
        const end = stopDate.setHours(23, 59, 59);
        return end;
    }

    function GetStartDate(startDate) {
        const start = startDate.setHours(0, 0, 1);
        return start;
    }

    const optionsTimeline = {
        height: '100px',
        min: GetStartDate(selectedStartDate),
        max: GetEndDate(selectedStopDate),
        stack: false,
        width: '1200px',
        tooltip: {
            followMouse: true,
        },
        rollingMode: {
            follow: false,
        }
    }

    function cumulativeConso(consommation) {

        let volume_litre = 0;
        if(consommation.length > 0){
        consommation.map((conso) => {
            volume_litre += conso.last_volume_litre;
        });
        }
        return volume_litre;

    }


    function timelineItem(periods, current_serial) {
        return periods.map((period, periodIx) => {
            const startPeriod = new Date(period[0]);
            const endPeriod = new Date(period[1]);
            const numberPeriod = periodIx + 1;
            return {
                id: 'P' + numberPeriod,
                content: 'Period ' + numberPeriod,
                start: startPeriod,
                end: endPeriod,
                type: 'range',
                className: "green",
                title: `
                Period ${numberPeriod} du
                ${formatDateModal(startPeriod)} à 
                ${formatDateModal(endPeriod)}`,
                group: current_serial
            };
        });
    }

    function ChangeDateProduction(date) {
        let dateStr = new Date(date * 1000);
        
        // Extraction des heures, minutes et secondes
        let hours = dateStr.getHours();
        let minutes = dateStr.getMinutes();
        let seconds = dateStr.getSeconds();
        
        // Extraction du jour, mois et année
        let day = dateStr.getDate();
        let month = dateStr.getMonth() + 1; // Les mois commencent à 0
        let year = dateStr.getFullYear();
        
        // Ajout des zéros devant si nécessaire
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        
        // Construction de la chaîne de date complète
        return day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
    }

    return (
        <div>
            <div>
                <label className='labelStart'>Date : </label>
                <span className='spanStart'>
                    <DatePicker showIcon
                        disabled={isLoading}
                        locale={fr}
                        dateFormat="dd/MM/yyyy"
                        selected={selectedStartDate}
                        onChange={(date) => handleStartDateChange(date)}
                    />


                </span>


                <Select className='selectWorksite' value={selectedWorksite} onChange={handleWorksiteChange}>
                    {Worksite.map((worksite, index) => (
                        <MenuItem key={index} value={worksite.worksiteid}>{worksite.worksitename}</MenuItem>
                    ))}
                </Select>

                {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ?
                    (
                        <>
                            <Input type="email" placeholder="Entrez votre e-mail" className="emailInput" value={email} onChange={handleInputChange} />
                            <Button variant='outlined' className="sendButton" onClick={handleSendMail}>Send Mail</Button>
                        </>
                    ) : ""
                }
            </div>
            {isLoading ? (
                <div className='divLoading'>
                    <img src={Loader} alt="Chargement..." className='imgLoading' />
                </div>
            ) : (
                <>
                    {dailyDatas.length > 0 ?
                        dailyDatas.map((data, index) => (
                            <div key={index} className='frameIndicator'>
                                <h2>INDICATEURS CUMULES</h2>
                                <div className='allCard'>
                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent>
                                            <TooltipMaterial title="Rotation totale de tous les appareils" arrow>
                                                <div>
                                                    <Typography variant="h5" align="center">Rotations</Typography>
                                                    <div className="titleSeparator"></div>
                                                    <Typography variant="h4"
                                                        align="center">{CountRotation()}</Typography>
                                                </div>
                                            </TooltipMaterial>

                                        </CardContent>
                                    </Card>

                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent >
                                            <TooltipMaterial
                                                title="Charges transportées totales de tous les appareils (rotation totale * tonnage totale)"
                                                arrow>
                                                <div>
                                                    <Typography variant="h5" align="center">Charges
                                                        transportées</Typography>
                                                    <div className="titleSeparator"></div>
                                                    <div className="cardData">
                                                        <Typography variant="h4" align="center">
                                                            {GetTotalLoadTransported() > 0 ? (
                                                                <Button
                                                                    onClick={() => Goto_ManageRotation(selectedStartDate, selectedStopDate, selectedWorksite)}>
                                                                    <Typography variant="h4"
                                                                        align="center">{GetTotalLoadTransported()} T</Typography>
                                                                </Button>
                                                            ) : (
                                                                <Typography variant="h4"
                                                                    align="center">{GetTotalLoadTransported()} T</Typography>
                                                            )}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TooltipMaterial>
                                        </CardContent>
                                    </Card>

                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent >
                                            <TooltipMaterial
                                                title="Distance parcourue totale de tous les appareils par rapport au site"
                                                arrow>
                                                <div>
                                                    <Typography variant="h5" align="center">Distances</Typography>
                                                    <div className="titleSeparator"></div>
                                                    <div className="cardData">
                                                        <Typography variant="h4"
                                                            align="center">{data.totalDistance.toFixed(2)} km</Typography>

                                                        {/* <Button className='btnFamily'
                                                            variant="contained"
                                                            onClick={() => handleOpenModal(data.totalDistanceByFamily)}>Voir
                                                        par Famille</Button> */}
                                                    </div>

                                                </div>
                                            </TooltipMaterial>
                                        </CardContent>
                                    </Card>

                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent>
                                            <TooltipMaterial
                                                title="Temps entre le 1er départ et la prise de poste avec la valeur la plus haute et la moyenne"
                                                arrow>
                                                <div>
                                                    <Typography variant="h5" align="center">Temps de chauffe moyen</Typography>
                                                    <div className="titleSeparator"></div>
                                                    <div className="cardData">
                                                        <Typography variant="h4"
                                                            align="center">{data.averageTimeDifferenceFirstMoveAndMinDate}</Typography>
                                                    </div>
                                                </div>
                                            </TooltipMaterial>
                                        </CardContent>
                                    </Card>

                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent>
                                            <TooltipMaterial
                                                title="Ce diagramme permet de visualiser le temps ou l'appareil est stoppé ou et en mouvement"
                                                arrow>
                                                <div>
                                                    <Pie
                                                        data={{
                                                            labels: ['roule', 'stop'],
                                                            datasets: [
                                                                {
                                                                    data: [data.moving_cnt, data.stop_cnt],
                                                                    backgroundColor: [
                                                                        'rgba(54, 162, 235, 0.2)',
                                                                        'rgba(255, 99, 132, 0.2)',
                                                                    ],
                                                                    borderColor: [
                                                                        'rgba(54, 162, 235, 1)',
                                                                        'rgba(255, 99, 132, 1)',
                                                                    ],
                                                                },
                                                            ],
                                                        }}
                                                        options={{
                                                            plugins: {
                                                                legend: {
                                                                    display: true,
                                                                },
                                                                tooltip: {
                                                                    callbacks: {
                                                                        label: (context) => {
                                                                            const label = context.label;

                                                                            if (label === 'roule') {
                                                                                return `${data.formattedMovingTime}`;
                                                                            } else if (label === 'stop') {
                                                                                return `${data.formattedStopTime}`;
                                                                            }

                                                                            return '';
                                                                        },
                                                                    },
                                                                },
                                                                datalabels: {
                                                                    color: 'black', // Couleur des libellés
                                                                    font: {
                                                                        size: 14, // Taille de police des libellés
                                                                    },
                                                                    formatter: (value, context) => {
                                                                        const total = context.dataset.data.reduce((acc, curr) => acc + curr);
                                                                        const percentage = ((value / total) * 100).toFixed(2); // Calcul du pourcentage avec 2 décimales
                                                                        return `${percentage}%`;
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </TooltipMaterial>
                                        </CardContent>
                                    </Card>
                                </div>
                                <div>
                                </div>
                            </div>
                        ))
                        :
                        (
                            <p className='pNoData'>Aucune donnée disponible.</p>
                        )
                    }
                    {
                        sortedUserDatas.length > 0 || Rotation.length > 0 ? (
                            <>
                                <TableContainer className='tableContainer'>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align={"center"}>Picto</TableCell>
                                                <TableCell align={"center"}>Vehicule</TableCell>
                                                <TableCell align={"center"}>Temps de poste</TableCell>
                                                <TableCell align={"center"}>Prise de poste</TableCell>
                                                <TableCell align={"center"}>Fin de poste</TableCell>
                                                <TableCell align={"center"}>Rotation</TableCell>
                                                <TableCell align={"center"}>Charge Transportée</TableCell>
                                                <TableCell align={"center"}>Distance parcourue</TableCell>
                                                <TableCell align={"center"}>Consommation</TableCell>
                                                <TableCell align={"center"}>Activités</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(sortedUserDatas.length > 0 ? sortedUserDatas : Rotation).map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow>
                                                        <TableCell align={"center"}>
                                                            {item.device_type ? (
                                                                <img src={require(`Public/images/picto/${getPictoByTypeDevice(item.device_type)}`)} alt="" className='imgPicto' />
                                                            ) : null}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? (
                                                                <Button onClick={() => Goto_ReplayWithSerial(item.serial)}>
                                                                    {item.serial ? `${getNamebySerial(item.serial)} (${item.serial})` : ''}
                                                                </Button>
                                                            ) : (
                                                                <Button onClick={() => Goto_ReplayWithSerial(item.serial)}>
                                                                    {getNamebySerial(item.serial)}
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.period ? item.period.length > 0 ? (
                                                                <Button onClick={() => handleOpenModalPeriod(item)} style={{ textTransform: 'lowercase' }}>
                                                                    {getPostTime(item.period)}
                                                                </Button>
                                                            ) : (
                                                                <Button onClick={() => handleOpenModalPeriod(item)} style={{ textTransform: 'lowercase' }}>
                                                                    no data click to see details
                                                                </Button>
                                                            ) : ""}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.mindate ? formatDateWithTime(item.mindate) : ''}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.laststop_ts != null ? formatDateWithTime(item.maxdate) : "Sortie de zone"}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {GetProdEventsForSerial(item.serial).total_rotcnt > 0 ? (
                                                                <Button onClick={() => handleOpenRotationModal(item.serial)}>
                                                                    {GetProdEventsForSerial(item.serial).total_rotcnt}
                                                                </Button>
                                                            ) : '0'}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {getTotalTransportedforSerial(item.serial, CountRotationBySerial(item.serial))} T
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.totalDistance !== undefined ? `${item.totalDistance.toFixed(2)} km` : ''}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.volume_litre ? cumulativeConso(item.volume_litre) > 0 ? (
                                                                <Button onClick={() => handleOpenConsommationModal(item.volume_litre, item.serial)}>
                                                                    {cumulativeConso(item.volume_litre)} L
                                                                </Button>
                                                            ) : (
                                                                cumulativeConso(item.volume_litre)
                                                            ) : ""}
                                                        </TableCell>
                                                        <TableCell align={"center"} className='tableCellPie'>
                                                        {item.moving_cnt ? <Pie
                                                                data={{
                                                                    labels: ['roule', 'stop'],
                                                                    datasets: [
                                                                        {
                                                                            data: [item.moving_cnt || 0, item.stop_cnt || 0],
                                                                            backgroundColor: [
                                                                                'rgba(54, 162, 235, 0.2)',
                                                                                backgroundColors[index] || 'rgba(255, 99, 132, 0.2)',
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(54, 162, 235, 1)',
                                                                                'rgba(255, 99, 132, 1)',
                                                                            ],
                                                                        },
                                                                    ],
                                                                }}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: true,
                                                                        },
                                                                        tooltip: {
                                                                            callbacks: {
                                                                                label: (context) => {
                                                                                    const label = context.label;

                                                                                    if (label === 'roule') {
                                                                                        return `${item.formattedMovingTime}`;
                                                                                    } else if (label === 'stop') {
                                                                                        return `${item.formattedStopTime}`;
                                                                                    }

                                                                                    return '';
                                                                                },
                                                                            },
                                                                        },
                                                                        datalabels: {
                                                                            color: 'black',
                                                                            font: {
                                                                                size: 12,
                                                                            },
                                                                            formatter: (value, context) => {
                                                                                const total = context.dataset.data.reduce((acc, curr) => acc + curr);
                                                                                const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : 0;
                                                                                return `${percentage}%`;
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                            /> : ""}
                                                        </TableCell> 
                                                    </TableRow>
                                                    {item.period ? item.period.length > 0 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={10} style={{ padding: 0, width: '100%', height: '100%' }}>
                                                                <Box display="flex" justifyContent="center">
                                                                    <Timeline
                                                                        initialItems={timelineItem(item.period, item.serial)}
                                                                        options={optionsTimeline}
                                                                        keys={{ groupIdKey: 'id', itemGroupKey: 'group' }}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : null : ""}
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Modal size="lg" show={showModal} onHide={handleModalClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Distance total par famille</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Famille</TableCell>
                                                            <TableCell>Distance(km)</TableCell>
                                                            <TableCell>Distance moyenne(km)</TableCell>
                                                            <TableCell>Distance max(km)</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Object.entries(DatasModal).map(([family, distance]) => (
                                                            <TableRow key={family}>
                                                                <TableCell>{family}</TableCell>
                                                                <TableCell>{distance.totalDistance?.toFixed(2)} km</TableCell>
                                                                <TableCell>{distance.averageDistance?.toFixed(2)} km</TableCell>
                                                                <TableCell>{distance.maxDistance?.toFixed(2)} km</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    </Modal.Footer>
                                </Modal>
                                {Object.keys(itemPeriod).length > 0 ?
                                    <Modal size="lg" show={showPeriodModal} onHide={handleModalPeriodClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Détail du temps de poste du {getNamebySerial(itemPeriod.serial)} </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <>
                                                <TableContainer className='tableContainer'>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align={"center"}>1er départ</TableCell>
                                                                <TableCell align={"center"}>Temps de chauffe</TableCell>
                                                                <TableCell align={"center"}>Dernier arrêt</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell
                                                                    align={"center"}>{itemPeriod.firstmove_ts != null ? <>
                                                                        <Button
                                                                            onClick={(event) => ButtonWatchPartialReplayHandler(itemPeriod.serial, itemPeriod.firstmove_ts, selectedWorksite)}>
                                                                            {formatDateWithTime(itemPeriod.firstmove_ts)}
                                                                        </Button>
                                                                    </> : ""}</TableCell>
                                                                <TableCell
                                                                    align={"center"}>{millisecondesEnDuree(itemPeriod.timeDifference)}</TableCell>
                                                                <TableCell align={"center"}>{itemPeriod.laststop_ts != null ? (
                                                                    <>
                                                                        <Button
                                                                            onClick={(event) => ButtonWatchPartialReplayHandler(itemPeriod.serial, itemPeriod.laststop_ts, selectedWorksite)}>{formatDateWithTime(itemPeriod.laststop_ts)}</Button>
                                                                    </>
                                                                ) : (
                                                                    "Sortie de zone"
                                                                )}</TableCell>

                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {itemPeriod.period.length > 0 ? (
                                                    <div>
                                                        <h5>Périodes :</h5>
                                                        <ul>
                                                            {itemPeriod.period.map(([start, end], index) => (
                                                                <li key={index}>
                                                                    <strong>Période {index + 1} :</strong> {formatDateModal(start)} - {formatDateModal(end)}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : ""}
                                            </>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>
                                    </Modal> : ""}
                                {rotationSerialModal.serial !== undefined ?
                                    <Modal size="lg" show={showRotationModal} onHide={handleModalRotationClose}>
                                        <Modal.Header closeButton>
                                            {/* <Modal.Title>Cumul {formatDateModal(getLastDateRotation(rotationSerialModal))} pour {getNamebySerial(rotationSerialModal[0].serial)} (sur {countDays(selectedStartDate, selectedStopDate)} j) </Modal.Title> */}
                                            <Modal.Title>test </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            { <>
                                                {rotationSerialModal.cumul_list.map((item, index) => (
                                                    <div key={index}>
                                                        {item.stock} - {item.material} : {item.rotation_count} voyage{item.rotation_count !== 1 && 's'} en {formatTime(item.task_duration)}
                                                    </div>
                                                ))}
                                            </> }
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>
                                    </Modal>
                                    : ""}

                                {consommationSerialModal.length > 0 ?
                                    <Modal size="xl" show={showConsommationModal} onHide={handleModalConsommationClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Consommation du {getNamebySerial(consommationSerialModal[0].serial)}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                {consommationSerialModal[0].consommation.length > 0 ? (
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Dernier plein GNR</TableCell>
                                                                <TableCell>Total (L)</TableCell>
                                                                <TableCell>Distance parcourue (km)</TableCell>
                                                                <TableCell>Consommation (L/100km)</TableCell>
                                                                <TableCell>Durée d'activité</TableCell>
                                                                <TableCell>Consommation (L/h)</TableCell>
                                                                <TableCell>Emission de CO2 (kg)</TableCell>
                                                                <TableCell>Coût estimé de la taxe carbone (€)</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {consommationSerialModal[0].consommation.map((conso, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{formatDateModal(conso.last_timestamp)}</TableCell>
                                                                    <TableCell>{conso.last_volume_litre}L</TableCell>
                                                                    <TableCell>{conso.totalDistance.toFixed(2)} km</TableCell>
                                                                    <TableCell>{((conso.last_volume_litre * 100) / conso.totalDistance).toFixed(2)} L/100km</TableCell>
                                                                    <TableCell>{millisecondesEnDuree(conso.time_full_volume_litre)}</TableCell>
                                                                    <TableCell>{((conso.last_volume_litre) * (3600000 / conso.time_full_volume_litre)).toFixed(1)} L/h</TableCell>
                                                                    <TableCell>{(conso.last_volume_litre * 3.07).toFixed(2)} kg CO2</TableCell>
                                                                    <TableCell>{((conso.last_volume_litre * 3.07) * 0.273).toFixed(2)} €</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>
                                    </Modal>
                                    : ""}
                            </>
                        ) : null
                    }
                </>
            )
            }

        </div>

    )
        ;
}

export default Sim;