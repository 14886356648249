import React, { useState, useEffect } from 'react';
import axios from "axios";
import useWebSocket from "react-use-websocket"
import StyledTextarea from '@mui/material/TextareaAutosize';
import { TextField } from '@mui/material';
const apiUrl = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;
const Terminal = () => {
    let tmptab = window.location.href.split('/');
    const [terminalDatas, setTerminalDatas] = useState('');
    const userid = sessionStorage.getItem("userId")
    const channelname = "terminal";
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            queryParams:{userid,channelname},
            share: false,
            shouldReconnect: () => true,
    
        },
    )
    const currentTerminalSerial = tmptab[tmptab.length - 1];
    useEffect(() => {
        if (lastJsonMessage !== null) {
            const data = lastJsonMessage;
            setTerminalDatas(prevTerminalDatas => {
                let newData = prevTerminalDatas;
                data.forEach(element => {
                    if (element.serial === currentTerminalSerial)
                        newData += element.msg;
                    else
                        console.log('bad serial for msg');
                });
                return newData;
            });
        }
    }, [lastJsonMessage])

    const sendText = (msg) => {
        axios.post(apiUrl + `/api/admin/TerminalSend`, { serial: currentTerminalSerial, cmd: msg }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                let str = terminalDatas;
                str += '>> ' + msg;
                setTerminalDatas(str);
            });
    };

    return (
        <div>
            <div>
                <p>Serial: {currentTerminalSerial} Connection status:xx</p>
            </div>
            <div>
                <StyledTextarea readOnly style={{ width: '100%' }} aria-label="minimum height" minRows={20} maxRows={20} value={terminalDatas} />
            </div>
            <div>
                <TextField id="outlined-basic" style={{ width: '100%' }} label="Type here (enter to send)" variant="outlined" onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        sendText(ev.target.value + '\r\n');
                        ev.target.value = '';
                        ev.preventDefault();
                    }
                }} />
            </div>
        </div>
    );
};


export default Terminal;



