import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Modal, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import ReactDOMServer from "react-dom/server";
import useWebSocket from "react-use-websocket"

var position_toshowInModal = 'nope';
var namedevice_toshowInModal = 'nope';
const DashboardUser = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const WS_URL = process.env.REACT_APP_WS_URL;
    const [device, setDevice] = useState([]);
    const [Picto, setPicto] = useState([]);
    const [Connected, setConnected] = useState([]);
    const [deviceWithWorksite, setDeviceWithWorksite] = useState([]);
    const [showPositionModal, setShowPositionModal] = useState(false);
    const [worksitebyclient, setWorksiteByClient] = useState([]);

    const userid = sessionStorage.getItem("userId")
    const channelname = "filterconnected";
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname },
            share: false,
            shouldReconnect: () => true,

        },
    )


    const iconHTML2 = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faMapMarkerAlt} size="3x"
        style={{ color: 'blue' }}></FontAwesomeIcon>)
    const customMarkerIconblue = new Leaflet.DivIcon({
        html: iconHTML2,
    });

    useEffect(() => {
        if (lastJsonMessage !== null) {
            setConnected(lastJsonMessage)
        }
    }, [lastJsonMessage])

    useEffect(() => {

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDevice(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })

            axios.get(apiUrl + '/api/admin/serialsforadmin', {
                headers: { 'x-access-token': sessionStorage.getItem('token') }
            })
                .then(res => {
                    const data = res.data;
                    const deviceSerial = data.map(item => item.username);
                    sendJsonMessage(deviceSerial);

                })

        }
        else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDevice(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })

            axios.get(apiUrl + '/api/user/serialsforuser', {
                headers: { 'x-access-token': sessionStorage.getItem('token') }
            })
                .then(res => {
                    const data = res.data;
                    const deviceSerial = data.map(item => item.username);
                    sendJsonMessage(deviceSerial);
                })
        }


        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setPicto(res.data)
            })

        axios.get(apiUrl + `/api/user/worksitebyclient`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                const worksitebyclient = res.data;
                setWorksiteByClient(worksitebyclient);
            })

    }, []);


    function getSerialbyWorksite(latitude, longitude) {
        let worksite = 'unknown';

        for (const work of worksitebyclient) {
            const positionRefObj = JSON.parse(work.positionref);
            const { lat, long } = positionRefObj;
            const latParsed = parseFloat(lat);
            const lonParsed = parseFloat(long);
            if (isInRadius(latParsed, lonParsed, latitude, longitude, work.rayon)) {
                worksite = work.worksitename;
            }
        }

        return worksite;
    }

    function getNamebySerial(serial) {
        if (device.length > 0) {
            const devicename = device.find(device => device.username === serial);
            let NameDevice = JSON.parse(devicename.name_device);
            let lastDevice;
            if (NameDevice.length > 1)
                lastDevice = NameDevice[NameDevice.length - 1].nameDevice;
            else
                lastDevice = NameDevice.nameDevice

            let str = lastDevice + '(' + serial + ')';
            return str;
        }
    }

    const sortedDevice = [...device];
    sortedDevice.sort((a, b) => {

        // comparez par ordre alphanumérique des noms d'appareils
        const nameA = getNamebySerial(a.username);
        const nameB = getNamebySerial(b.username);

        return nameA.localeCompare(nameB);
    });
    let DeviceEnginSorted = sortedDevice.filter((device) => [1, 2].includes(device.id_hardware_type));
    let DeviceP1Sorted = sortedDevice.filter((device) => device.id_hardware_type === 3);
    let DeviceP2Sorted = sortedDevice.filter((device) => device.id_hardware_type === 4);
    let dock = sortedDevice.filter((device) => device.id_hardware_type === 5);
    if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
        DeviceEnginSorted = sortedDevice.filter((device) => [1, 2].includes(device.hardware_type.id_hardware_type));
        DeviceP1Sorted = sortedDevice.filter((device) => device.hardware_type.id_hardware_type === 3);
        DeviceP2Sorted = sortedDevice.filter((device) => device.hardware_type.id_hardware_type === 4);
        dock = sortedDevice.filter((device) => device.hardware_type.id_hardware_type === 5);
    }



    useEffect(() => {

        const deviceWorksite = DeviceEnginSorted.map((device, index) => {
            // Ici, vous pouvez accéder aux détails de chaque appareil dans le tableau DeviceEnginSorted
            const serial = device.username;
            const deviceStatus = getDeviceStatus(serial);
            let worksite = 'unknown';
            if (deviceStatus.isNeverConnected !== true) {
                worksite = getSerialbyWorksite(deviceStatus.current_position.latitude, deviceStatus.current_position.longitude);
            }
            return {
                serial,
                deviceStatus,
                worksite

            }
        });

        if (JSON.stringify(deviceWithWorksite) !== JSON.stringify(deviceWorksite)) {
            setDeviceWithWorksite(deviceWorksite);
        }


    }, [DeviceEnginSorted])


    const getDeviceStatus = (serialNumber) => {
        let deviceInfo = null;
        for (const key in Connected) {
            if (Connected.hasOwnProperty(key)) {
                const obj = Connected[key];
                if (obj.SerialStr === serialNumber) {
                    deviceInfo = obj;
                    break;
                }
            }
        }
        if (deviceInfo) {
            const { IsConnected, connection_Date, disconnection_Date } = deviceInfo;

            if (IsConnected) {
                // L'appareil est connecté
                return {
                    isConnected: true,
                    connectionDate: new Date(connection_Date),
                    isNeverConnected: false,
                    current_position: deviceInfo.last_keepAlive.current_position,
                    last_production: deviceInfo.last_KA_production || {"total_rotation": 0},
                };
            } else {
                // L'appareil est déconnecté
                return {
                    isConnected: false,
                    disconnectionDate: new Date(disconnection_Date),
                    isNeverConnected: false,
                    current_position: deviceInfo.last_keepAlive.current_position,
                    last_production: { "total_rotation": 0 },
                };
            }
        } else {
            // L'appareil n'est pas trouvé, donc considéré comme déconnecté
            return {
                isNeverConnected: true,
            };
        }
    };



    function ChangeDate(date) {
        let dateStr = new Date(date);
        let year = dateStr.getFullYear();
        let month = dateStr.getMonth() + 1;
        let dt = dateStr.getDate();
        let hours = dateStr.getHours();
        let minutes = dateStr.getMinutes();
        let seconds = dateStr.getSeconds();

        if (hours < 10) {
            hours = '0' + hours;
        }

        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        if (seconds < 10) {
            seconds = '0' + seconds;
        }

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }

        return year + '-' + month + '-' + dt + " / " + hours + ":" + minutes + ":" + seconds;
    }

    function ChangeDateProduction(date) {
        let dateStr = new Date(date * 1000);
        let hours = dateStr.getHours();
        let minutes = dateStr.getMinutes();
        let seconds = dateStr.getSeconds();

        if (hours < 10) {
            hours = '0' + hours;
        }

        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        if (seconds < 10) {
            seconds = '0' + seconds;
        }

        return  hours + ":" + minutes + ":" + seconds;
    }

    const handleModalClose = () => {
        setShowPositionModal(false)
    }
    const handleModalOpen = (position, name) => {
        position_toshowInModal = position;
        namedevice_toshowInModal = name;
        setShowPositionModal(true)
    }

    function getPictoByTypeDevice(serial) {
        let deviceInfo = null;
        for (const key in Connected) {
            if (Connected.hasOwnProperty(key)) {
                const obj = Connected[key];
                if (obj.SerialStr === serial) {
                    deviceInfo = obj;
                    break;
                }
            }
        }
        if (deviceInfo) {
            const { IsConnected, connection_Date, disconnection_Date } = deviceInfo;

            if (deviceInfo) {
                const picto = Picto.find(device => device.id_device_type === deviceInfo.last_keepAlive.current_CB_type);
                if (picto !== undefined)
                    return picto.picto;
            }
        }
    }

    function isInRadius(lat1, lon1, lat2, lon2, rayonMetres) {
        const rayonTerreMetres = 6371000.0;

        // Conversion des latitudes et longitudes en radians
        const lat1Rad = degreeToRadian(lat1);
        const lon1Rad = degreeToRadian(lon1);
        const lat2Rad = degreeToRadian(lat2);
        const lon2Rad = degreeToRadian(lon2);

        // Calcul de la différence des latitudes et des longitudes
        const deltaLat = lat2Rad - lat1Rad;
        const deltaLon = lon2Rad - lon1Rad;

        // Formule de la distance haversine
        const a = Math.pow(Math.sin(deltaLat / 2.0), 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.pow(Math.sin(deltaLon / 2.0), 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));


        // Distance en mètres
        const distanceMetres = rayonTerreMetres * c;
        // Vérifier si la distance est inférieure ou égale au rayon de référence
        return distanceMetres <= rayonMetres;
    }

    // Fonction pour convertir les degrés en radians
    function degreeToRadian(angle) {
        return Math.PI * angle / 180.0;
    }

    const uniqueSites = [...new Set(deviceWithWorksite.map(device => device.worksite))];

    function getPositionRefWorksite(worksiteName) {

        if (worksiteName !== "unknown") {
            const worksite = worksitebyclient.find(site => site.worksitename === worksiteName);
            if (worksite) {
                // Si le site est trouvé, extraire la latitude, la longitude et le rayon
                const { lat, long } = JSON.parse(worksite.positionref);

                // Retourner une chaîne avec latitude, longitude et rayon
                return ` (Lat : ${lat},  Long: ${long})`;
            }
        }
    }

    function getTimeDifference(date) {
        const now = new Date();
        const timeDifference = now - new Date(date);

        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;

        const seconds = Math.floor((timeDifference / second) % 60);
        const minutes = Math.floor((timeDifference / minute) % 60);
        const hours = Math.floor((timeDifference / hour) % 24);
        const days = Math.floor(timeDifference / day);

        let result = '';
        if (days > 0) {
            result += `${days}j `;
        }
        if (hours > 0) {
            result += `${hours}h `;
        }
        if (minutes > 0) {
            result += `${minutes}m `;
        }
        if (seconds > 0) {
            result += `${seconds}s `;
        }

        return result.trim();
    }

    function convertSeconds(seconds) {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        let formattedTime = '';

        if (hrs > 0) {
            formattedTime += `${hrs.toString().padStart(2, '0')}:`;
        }

        if (mins > 0 || hrs > 0) {
            formattedTime += `${mins.toString().padStart(2, '0')}:`;
        }

        formattedTime += `${secs.toString().padStart(2, '0')}`;

        return formattedTime;
    }

    const isWithinFiveMinutes = (timestamp) => {
        const fiveMinutesInMs = 5 * 60 * 1000;
        const now = new Date().getTime();
        const lastDate = new Date(timestamp).getTime();
    
        return Math.abs(now - lastDate) <= fiveMinutesInMs;
      };

    return (
        <>
            <h3>Engins</h3>
            {uniqueSites.map((site, siteIndex) => (
                <div key={siteIndex}>
                    <h4>{site}{getPositionRefWorksite(site)}</h4>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {deviceWithWorksite.filter(device => device.worksite === site).sort((a, b) => {
                            // Compare les périphériques en fonction de leur état de connexion et de la date de connexion/déconnexion
                            if (a.deviceStatus.isConnected && b.deviceStatus.isConnected) {
                                // Si les deux périphériques sont connectés, les trier par date de connexion
                                return new Date(a.deviceStatus.connectionDate) - new Date(b.deviceStatus.connectionDate);
                            } else if (!a.deviceStatus.isConnected && !b.deviceStatus.isConnected) {
                                // Si les deux périphériques sont déconnectés, les trier par date de déconnexion
                                return new Date(b.deviceStatus.disconnectionDate) - new Date(a.deviceStatus.disconnectionDate);
                            } else if (a.deviceStatus.isConnected && !b.deviceStatus.isConnected) {
                                // Mettre les périphériques connectés avant les déconnectés
                                return -1;
                            } else {
                                // Mettre les périphériques déconnectés après les connectés
                                return 1;
                            }
                        }).map((device, index) => (
                            <Toast key={index} style={{ marginLeft: '1%', marginTop: "1%" }}>
                                <Toast.Header closeButton={false} style={{ backgroundColor: device.deviceStatus.isConnected ? '#00FF00' : '#ff6666', display: 'flex', alignItems: 'center' }}>
                                    {Picto.length > 0 && !device.deviceStatus.isNeverConnected ? getPictoByTypeDevice(device.serial) !== undefined ? <img style={{ width: '15%' }}
                                        src={require("Public/images/picto/" + getPictoByTypeDevice(device.serial))}
                                        alt=""></img> : "" : ""}
                                    <strong style={{ fontWeight: 'bold', color: 'black', margin: 0 }} className="me-auto"><span style={{ fontSize: '0.8em' }}> <h6>{getNamebySerial(device.serial)}</h6> </span></strong>
                                    <small style={{ fontWeight: 'bold', color: 'black' }}>{device.deviceStatus.isConnected ? ChangeDate(device.deviceStatus.connectionDate) : getTimeDifference(device.deviceStatus.disconnectionDate)}</small>
                                </Toast.Header>
                                <Toast.Body>
                                    {
                                        device.deviceStatus.isNeverConnected ? (
                                            <p>Date : No Data</p>
                                        ) : (
                                            <>
                                                <Button title='Localise device' style={{ marginLeft: '1%', width: '15%' }} onClick={() => handleModalOpen(device.deviceStatus.current_position, getNamebySerial(device.serial))}><FontAwesomeIcon icon={faMapMarkerAlt} /></Button>
                                            </>
                                        )
                                    }
                                    {
                                        !device.deviceStatus.isNeverConnected && device.deviceStatus.last_production.total_rotation > 0 && isWithinFiveMinutes(device.deviceStatus.last_production.last_timestamp)? (
                                            <>
                                                <ul>
                                                    {/*<li>Cadence journée : {(device.deviceStatus.last_production.total_time / 3600) > 1 ? (device.deviceStatus.last_production.total_rotation / (device.deviceStatus.last_production.total_time / 3600)).toFixed(2) : device.deviceStatus.last_production.total_rotation}</li>
                                                    <li>Cadence dernière heure : {device.deviceStatus.last_production.total_rotation_last_hour}</li>
                                                    <li>Dernière rotation enregistrée : {ChangeDateProduction(device.deviceStatus.last_production.last_rotation_ts)}</li> */}
                                                    <li>Compteur rotation du jour : {device.deviceStatus.last_production.total_rotation} </li>
                                                    <li>Stock Actuel : {device.deviceStatus.last_production.current_stock_str} </li>
                                                    <li>Matériau actuel : {device.deviceStatus.last_production.current_material_str} </li>
                                                    {/*
                                                    <li>Temps total :  {convertSeconds(device.deviceStatus.last_production.total_time)}</li> */}
                                                </ul>
                                            </>
                                        ) : ""
                                    }
                                </Toast.Body>
                            </Toast>
                        ))}
                    </div>
                </div>
            ))}

            <h3>Piétons</h3>
            <h4>P1</h4>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {Connected ? DeviceP1Sorted.map((device, index) => (
                    <Toast key={index} style={{ marginLeft: '1%', marginTop: "1%" }}>
                        <Toast.Header closeButton={false}
                            style={{ backgroundColor: '#D3D3D3', display: 'flex', alignItems: 'center' }}>
                            <img style={{ width: '15%' }}
                                src={require("Public/images/picto/PICT20.png")}
                                alt=""></img>
                            <h6 style={{ fontWeight: 'bold', color: 'black', margin: 0 }}>{getNamebySerial(device.username)}</h6>
                        </Toast.Header>
                        <Toast.Body>
                            {
                                getDeviceStatus(device.username).isNeverConnected ? (
                                    <p>Date : No Data</p>
                                ) : (
                                    <>
                                        <p>

                                            {getDeviceStatus(device.username).isConnected
                                                ? `Date Connexion : ${ChangeDate(getDeviceStatus(device.username).connectionDate)}`
                                                : `Date Déconnexion : ${ChangeDate(getDeviceStatus(device.username).disconnectionDate)}`
                                            }
                                        </p>
                                        <Button title='Localise device' style={{ marginLeft: '1%', width: '15%' }}
                                            onClick={() => handleModalOpen(getDeviceStatus(device.username).current_position, getNamebySerial(device.username))}><FontAwesomeIcon
                                                icon={faMapMarkerAlt} /></Button>
                                    </>
                                )
                            }

                        </Toast.Body>
                    </Toast>
                )) : ""}
            </div>
            <h4>P2</h4>
            <div style={{ display: "flex", flexWrap: "wrap" }}>

                {Connected ? DeviceP2Sorted.map((device, index) => (
                    <Toast key={index} style={{ marginLeft: '1%', marginTop: "1%" }}>
                        <Toast.Header closeButton={false}
                            style={{ backgroundColor: '#D3D3D3', display: 'flex', alignItems: 'center' }}>
                            <img style={{ width: '15%' }}
                                src={require("Public/images/picto/PICT20.png")}
                                alt=""></img>
                            <h6 style={{ fontWeight: 'bold', color: 'black', margin: '0' }}>{getNamebySerial(device.username)}</h6>
                        </Toast.Header>
                        <Toast.Body>
                            {
                                getDeviceStatus(device.username).isNeverConnected ? (
                                    <p>Date : No Data</p>
                                ) : (
                                    <>
                                        <p>

                                            {getDeviceStatus(device.username).isConnected
                                                ? `Date Connexion : ${ChangeDate(getDeviceStatus(device.username).connectionDate)}`
                                                : `Date Déconnexion : ${ChangeDate(getDeviceStatus(device.username).disconnectionDate)}`
                                            }
                                        </p>
                                        <Button title='Localise device' style={{ marginLeft: '1%', width: '15%' }}
                                            onClick={() => handleModalOpen(getDeviceStatus(device.username).current_position, getNamebySerial(device.username))}><FontAwesomeIcon
                                                icon={faMapMarkerAlt} /></Button>
                                    </>
                                )
                            }

                        </Toast.Body>
                    </Toast>
                )) : ""}
            </div>
            <h3>Station d'Accueil</h3>
            <div style={{ display: "flex", flexWrap: "wrap" }}>

                {Connected ? dock.map((device, index) => (
                    <Toast key={index} style={{ marginLeft: '1%', marginTop: "1%" }}>
                        <Toast.Header closeButton={false}
                            style={{ backgroundColor: '#D3D3D3' }}>
                            <h6 style={{ fontWeight: 'bold', color: 'black' }}>{getNamebySerial(device.username)}</h6>
                        </Toast.Header>
                        <Toast.Body>
                            {
                                getDeviceStatus(device.username).isNeverConnected ? (
                                    <p>Date : No Data</p>
                                ) : (
                                    <>
                                        <p>

                                            {getDeviceStatus(device.username).isConnected
                                                ? `Date Connexion : ${ChangeDate(getDeviceStatus(device.username).connectionDate)}`
                                                : `Date Déconnexion : ${ChangeDate(getDeviceStatus(device.username).disconnectionDate)}`
                                            }
                                        </p>
                                        <Button title='Localise device' style={{ marginLeft: '1%', width: '15%' }}
                                            onClick={() => handleModalOpen(getDeviceStatus(device.username).current_position, getNamebySerial(device.username))}><FontAwesomeIcon
                                                icon={faMapMarkerAlt} /></Button>
                                    </>
                                )
                            }

                        </Toast.Body>
                    </Toast>
                )) : ""}
            </div>

            <Modal size="lg" show={showPositionModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Position du device {namedevice_toshowInModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginLeft: '1%' }}>
                        <div>
                            <MapContainer
                                center={[position_toshowInModal.latitude, position_toshowInModal.longitude]}
                                zoom={16} scrollWheelZoom={true} style={{ width: '100%', height: '500px' }}>
                                <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={22}
                                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                                <Marker icon={customMarkerIconblue}
                                    position={[position_toshowInModal.latitude, position_toshowInModal.longitude]} />

                            </MapContainer>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default DashboardUser