import React, { useEffect, useRef, useState } from "react";
import { FeatureGroup, MapContainer, Marker, Polygon, Polyline, Popup, TileLayer, useMap } from 'react-leaflet';
import './Replay.css';
import 'leaflet/dist/leaflet.css';
import { Card } from "react-bootstrap";
import axios from "axios";
import Button from "@mui/material/Button";
import Timeline from 'fix-react-vis-timeline';
import { faPause, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import MarkerIcon from 'Public/images/dump-truck.png';
import Loader from 'Public/images/loader.gif';
import MarkerTransparent from 'Public/images/transparent.png';
import L from 'leaflet';
import { LatLng, LatLngBounds } from "leaflet/src/geo";
import { useLocation } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { deviceTemplates } from "../../utils/DeviceTemplate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

const apiUrl = process.env.REACT_APP_API_URL;
L.Marker.prototype.options.icon = DefaultIcon;

let customTimes = [];


let polylines_positions = [];
let polylines_positions_onscreen = [];

let timeline_timeAxis_step = 1000;
let old_timeline_range_second = 0;
let need_refresh_datas = false;
let timelineItems = [];
let timeline_groups = [];
let DateStartTemp = [];

let istest = false;

let need_map_bound = false;
let map_bounds;
let need_timeline_fit = false;
let replay_called_from_outside = false;
let serials_from_outside = [];

function Replay() {


    const [showPolyline, setShowPolyline] = useState(true);
    const [showHighlightPolyline, setShowHighlightPolyline] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [boolCustomTimeNow, setBoolCustomTimeNow] = useState(false);
    const [intervalValue, setIntervalValue] = useState(1);
    const [OpacityValue, setOpacityValue] = useState(5);
    const [replay, setReplay] = useState([]);
    const [polylineClick, setPolylineClick] = useState([]);
    const [isReplayPlayPauseChecked, setisReplayPlayPauseChecked] = useState(false);
    const [isMarkerChecked, setisMarkerChecked] = useState(false);
    const [selectedStopDate, setSelectedStopDate] = useState(new Date());
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [isPlaying, setIsPlaying] = useState(false);
    const [State, setState] = useState(false);
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [selectedSerialNumber, setSelectedSerialNumber] = useState([]);
    const [MarkerData, setMarkerData] = useState([]);
    const [Markers, setMarker] = useState([]);
    const [cursorDateStop, setCursorDateStop] = useState("");
    const [cursorDateStart, setCursorDateStart] = useState("");
    const [zoom, setZoom] = useState(17);
    const [cursorDatePlay, setCursorDatePlay] = useState(false);
    const [deviceName, setDeviceName] = useState([]);
    const [typeDevice, setTypeDevice] = useState([]);
    const [typeDeviceId, setTypeDeviceId] = useState([]);
    const [Picto, setPicto] = useState([]);
    const timelineRef = useRef(null);
    const leafletRef = useRef(null);
    const mapRef = useRef(null);
    const selectedSerialNumber_input_ref = useRef(null);
    const [Worksite, setWorksite] = useState([]);
    const [selectedWorksite, setSelectedWorksite] = useState("");
    const location = useLocation();
    const [numberOfDays, setNumberOfDays] = useState(1);
    const [updateData, setUpdateData] = useState(true);

    let currentTime;
    let currentPlayTime;
    let startCurrentDate;
    let periodIx = 0;


    useEffect(() => {
        if (typeDeviceId !== "") {
            axios.post(apiUrl + `/api/user/familydevicetype`,
                { id_device_type: typeDeviceId },

                {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                .then(res => {
                    setTypeDevice(res.data);

                })
        }
    }, [typeDeviceId]);

    function coordinateOffsetToTemplate(coord) {
        let deviceTemplate;
        const transformedCoordinates = coord.map((coordonnee) => {
            const type = coordonnee.type;
            axios.post(apiUrl + `/api/user/familydevicetype`,
                { id_device_type: type },

                {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                .then(res => {
                    setTypeDevice(res.data)

                })


            if (typeDevice.length > 0) {
                const typeDeviceName = typeDevice[0].nom_device_type.replace(/\s/g, "");
                deviceTemplate = deviceTemplates.find((template) => {
                    return template.name === typeDeviceName;
                })
            }

            if (deviceTemplate !== undefined) {
                const lat1Offset = coordonnee.lat + (deviceTemplate.offsetY / 6366093) * (180 / Math.PI);

                const lng1Offset = coordonnee.lng + (deviceTemplate.offsetX / 6366093) * (180 / Math.PI) / Math.cos(coordonnee.lat * Math.PI / 180);

                const lat2Offset = coordonnee.lat + (deviceTemplate.offsetY / 6366093) * (180 / Math.PI);
                const lng2Offset = coordonnee.lng + ((deviceTemplate.sizeX + deviceTemplate.offsetX) / 6366093) * (180 / Math.PI) / Math.cos(coordonnee.lat * Math.PI / 180);

                const lat3Offset = coordonnee.lat + ((deviceTemplate.offsetY + deviceTemplate.sizeY) / 6366093) * (180 / Math.PI);
                const lng3Offset = coordonnee.lng + ((deviceTemplate.offsetX) / 6366093) * (180 / Math.PI) / Math.cos(coordonnee.lat * Math.PI / 180);

                const lat4Offset = coordonnee.lat + ((deviceTemplate.offsetY + deviceTemplate.sizeY) / 6366093) * (180 / Math.PI);
                const lng4Offset = coordonnee.lng + ((deviceTemplate.offsetX + deviceTemplate.sizeX) / 6366093) * (180 / Math.PI) / Math.cos(coordonnee.lat * Math.PI / 180);

                const corner1 = L.latLng(lat1Offset, lng1Offset);
                const corner2 = L.latLng(lat2Offset, lng2Offset);
                const corner3 = L.latLng(lat3Offset, lng3Offset);
                const corner4 = L.latLng(lat4Offset, lng4Offset);
                return rotatePoints(new L.LatLng(coordonnee.lat, coordonnee.lng), [corner1, corner2, corner4, corner3], coordonnee.orientation);
            }
        })
        if (transformedCoordinates[0] === undefined)
            return transformedCoordinates[0]

        return transformedCoordinates;
    }

    function rotatePoints(center, points, yaw) {
        const res = [];
        const centerPoint = leafletRef.current.latLngToLayerPoint(center);
        const angle = yaw * (Math.PI / 180);

        for (let i = 0; i < points.length; i++) {
            const p = leafletRef.current.latLngToLayerPoint(points[i]);
            const p2 = new L.Point(p.x - centerPoint.x, p.y - centerPoint.y);
            const p3 = new L.Point(Math.cos(angle) * p2.x - Math.sin(angle) * p2.y, Math.sin(angle) * p2.x + Math.cos(angle) * p2.y);
            const p4 = new L.Point(p3.x + centerPoint.x, p3.y + centerPoint.y);
            const latLng = leafletRef.current.layerPointToLatLng(p4);
            res.push(latLng);
        }

        return res;
    }


    let selectedData = replay.sort((a, b) => {
        if (a.serial < b.serial) {
            return -1;
        } else if (a.serial > b.serial) {
            return 1;
        }
        return 0;
    });

    const customIcon = L.icon({
        iconUrl: MarkerIcon,
        iconAnchor: [12.5, 12.5],
        iconSize: [25, 25],
    });

    const createCustomIcon = (iconUrl) => {
        return L.divIcon({
            html: `<div class="custom-icon" style="background-image: url(${iconUrl});"></div>`,
            className: 'custom-icon-container',
            iconAnchor: [12.5, 12.5],
            iconSize: [30, 30]
        });
    };

    const customIconEmpty = L.icon({
        iconUrl: MarkerTransparent,
        iconAnchor: [12.5, 12.5],
        iconSize: [30, 30],

    });

    if (timelineRef.current) {
        //timelineRef.current.timeline.fit();
        currentTime = timelineRef.current.timeline.getCustomTime('0');
    }

    if (need_map_bound) {
        if (leafletRef.current) {
            leafletRef.current.fitBounds([map_bounds._southWest, map_bounds._northEast]);
            need_map_bound = false;
        }
    }

    useEffect(() => {
        axios.get(apiUrl + `/api/user/worksite`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
            })

        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                const Picto = res.data;
                setPicto(Picto);
            });
    }, []);

    useEffect(() => {
        if (Worksite.length > 0) {
            setSelectedWorksite(Worksite[0].worksiteid)
        }
    }, [Worksite]);

    useEffect(() => {
        if (isReplayPlayPauseChecked && timelineRef.current) {
            timelineRef.current.timeline.addCustomTime(cursorDateStart, "2")
            timelineRef.current.timeline.setCustomTimeMarker("Now", "2");
            timelineRef.current.timeline.setCustomTimeMarker("Start", "0");
            timelineRef.current.timeline.setCustomTimeMarker("End", "1");
            setBoolCustomTimeNow(true);
        }
        if (!isReplayPlayPauseChecked && timelineRef.current) {
            timelineRef.current.timeline.removeCustomTime("2")
            setBoolCustomTimeNow(false);
        }
    }, [isReplayPlayPauseChecked]);


    useEffect(() => {
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data);
                })
        }
        else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data);
                })
        }
    }, []);


    function getNamebySerial(serial) {
        if (deviceName.length > 0) {
            const device = deviceName.find(device => device.username === serial);
            let selectedDevice = "noname";
            if (device !== undefined) {
                let NameDevice = JSON.parse(device.name_device);
                let StartDate = new Date(selectedStartDate);
                StartDate.setHours(0, 0, 1);
                if (NameDevice.length > 1) {

                    for (const item of NameDevice) {
                        const itemDate = new Date(item.timestamp);
                        const startDate = new Date(StartDate);

                        if (itemDate < startDate) {
                            selectedDevice = item.nameDevice;
                        }
                    }
                } else {
                    const itemDate = new Date(NameDevice.timestamp);
                    const startDate = new Date(StartDate);
                    if (itemDate < startDate)
                        selectedDevice = NameDevice.nameDevice;
                }
            }
            else {

            }

            let str = selectedDevice;
            if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN"))
                str = str + '(' + serial + ')';
            return str;
        }
    }

    useEffect(() => {
        if (selectedSerialNumber.length > 0) {
            if (isReplayPlayPauseChecked && timelineRef.current) {
                timelineRef.current.timeline.removeCustomTime("2")
                setBoolCustomTimeNow(false);
                setisReplayPlayPauseChecked(false);
            }
            setIsLoading(true);
            var start, stop;
            start = selectedStartDate;
            stop = selectedStopDate;
            if (location.state) {
                if (location.state.ts) {
                    const dateStart = new Date(location.state.ts);
                    const dateStop = new Date(location.state.ts);
                    dateStart.setHours(0, 0, 1);
                    start = new Date(dateStart);

                    dateStop.setHours(23, 59, 59);
                    stop = new Date(dateStop);
                    //location.state.ts=null;
                }
                if (location.state.start) {
                    const dateStart = new Date(location.state.start);
                    const dateStop = new Date(location.state.stop);
                    dateStart.setHours(0, 0, 1);
                    start = new Date(dateStart);

                    dateStop.setHours(23, 59, 59);
                    stop = new Date(dateStop);
                }
            } else {
                const dateStart = new Date(selectedStartDate);
                const dateStop = new Date(selectedStopDate);
                dateStart.setHours(0, 0, 1);
                start = new Date(dateStart);

                dateStop.setHours(23, 59, 59);
                stop = new Date(dateStop);
            }


            axios.post(apiUrl + `/api/user/replayperiodserialsfilter`,
                { startdate: start, stopdate: stop, serials: selectedSerialNumber, worksiteid: selectedWorksite },

                {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                .then(res => {
                    const jsonString = res.data;
                    const data = jsonString.map((data) => {
                        return data.position.split('},{').map((str) => {
                            // Supprime les accolades des extrémités
                            const position = str.replace('{', '').replace('}', '');
                            // Divise les valeurs séparées par des virgules et les stocke dans un tableau
                            const values = position.split(',');
                            // Convertit les valeurs de chaîne de caractères en types appropriés
                            return {
                                serial: data.serial,
                                type: data.device_type,
                                date: convertUTCDateToLocalDate(new Date(values[0])),
                                latitude: parseFloat(values[1]),
                                longitude: parseFloat(values[2]),
                                speed: parseInt(values[3]),
                                orientation: parseInt(values[4]),
                            };
                        });
                    });
                    const allDataArray = data.reduce((acc, curr) => acc.concat(curr), []);
                    setReplay(allDataArray);
                    setIsLoading(false);
                    setCursorDateStop("");
                })
        } else
            selectedData = [];
    }, [selectedSerialNumber]);

    useEffect(() => {
        let intervalId;
        if (isPlaying) {
            intervalId = setInterval(() => {
                currentPlayTime = timelineRef.current.timeline.getCustomTime('2');
                timelineRef.current.timeline.setCustomTime(currentPlayTime.setSeconds(currentPlayTime.getSeconds() + 1), '2');
                setCursorDatePlay(timelineRef.current.timeline.getCustomTime('2'));
                const dataPlay = FiltreByDateData(cursorDatePlay)
                setMarker(dataPlay)
                let currentPositions = undefined;
                if (dataPlay.length > 0)
                    currentPositions = coordinateOffsetToTemplate(dataPlay)

                if (currentPositions !== undefined)
                    setMarkerData(currentPositions)

                if (timelineRef.current.timeline.getCustomTime('2') >= timelineRef.current.timeline.getCustomTime('1')) {
                    clearInterval(intervalId);
                    setIsPlaying(false);
                    timelineRef.current.timeline.setCustomTime(currentTime, '2');
                }
            }, 1000 / intervalValue);

        }
        return () => clearInterval(intervalId);

    }, [isPlaying, cursorDatePlay, intervalValue]);


    useEffect(() => {
        if ((cursorDateStart !== "") && (cursorDateStop !== "")) {
            set_polylines_positions_onscreen(polylines_positions, new Date(cursorDateStart), new Date(cursorDateStop));
        }
        if (need_timeline_fit) {
            need_timeline_fit = false;
            if (timelineRef.current)
                timelineRef.current.timeline.fit();
        }
    }, [cursorDateStop, cursorDateStart]);

    useEffect(() => {
        const dataPlay = FiltreByDateData(cursorDatePlay)
        setMarker(dataPlay)
        let currentPositions;

        if (dataPlay.length > 0)
            currentPositions = coordinateOffsetToTemplate(dataPlay)

        if (currentPositions !== undefined)
            setMarkerData(currentPositions)

    }, [cursorDatePlay]);


    useEffect(() => {

        if (isReplayPlayPauseChecked && timelineRef.current) {
            timelineRef.current.timeline.removeCustomTime("2")
            setBoolCustomTimeNow(false);
            setisReplayPlayPauseChecked(false);
        }
        if (updateData === true) {
            var start, stop;
            start = selectedStartDate;
            stop = selectedStopDate;
            if (location.state) {
                if (location.state.ts) {
                    start = location.state.ts;
                    stop = location.state.ts;
                    setSelectedStopDate(stop);
                    setSelectedStartDate(start);
                    if (start.toDateString() !== stop.toDateString())
                        setNumberOfDays(2);
                    else
                        setNumberOfDays(1)

                    const dateStart = new Date(location.state.ts);
                    const dateStop = new Date(location.state.ts);
                    dateStart.setHours(0, 0, 1);
                    start = new Date(dateStart);

                    dateStop.setHours(23, 59, 59);
                    stop = new Date(dateStop);


                }
                if (location.state.start) {
                    start = location.state.start;
                    stop = location.state.stop;
                    setSelectedStopDate(stop);
                    setSelectedStartDate(start);

                    if (start.toDateString() !== stop.toDateString())
                        setNumberOfDays(2);
                    else
                        setNumberOfDays(1)

                    const dateStart = new Date(location.state.start);
                    const dateStop = new Date(location.state.stop);
                    dateStart.setHours(0, 0, 1);
                    start = new Date(dateStart);

                    dateStop.setHours(23, 59, 59);
                    stop = new Date(dateStop);


                }
            } else {
                const dateStart = new Date(selectedStartDate);
                const dateStop = new Date(selectedStopDate);
                dateStart.setHours(0, 0, 1);
                start = new Date(dateStart);

                dateStop.setHours(23, 59, 59);
                stop = new Date(dateStop);
                setSelectedSerialNumber([]);
            }


            if (selectedStopDate !== "" && selectedStartDate !== "" && selectedWorksite !== "") {

                if (location.state) {
                    if (location.state.worksite) {
                        setSelectedWorksite(location.state.worksite)
                        /* location.state.worksite = null;*/
                    }
                    if (location.state.serials) {
                        setSelectedSerialNumber(location.state.serials);
                        /*location.state.serials = null;*/
                    }
                }
                if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
                    axios.get(apiUrl + `/api/admin/serialsforadmin`, {
                        headers: { 'x-access-token': sessionStorage.getItem("token") }
                    })
                        .then(userRes => {
                            // Extraire les numéros de série de l'utilisateur à partir de la réponse
                            const userSerialNumbers = userRes.data.map(user => user.username);

                            // Requête Axios POST pour récupérer les numéros de série de la période
                            axios.post(apiUrl + `/api/user/serialsperiod`,
                                { startdate: start, stopdate: stop, worksiteid: selectedWorksite },
                                { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                                .then(periodRes => {
                                    // Extraire les numéros de série de la période à partir de la réponse
                                    const periodSerialNumbers = Array.from(new Set(periodRes.data.map(data => data.serial)));
                                    // Filtrer les numéros de série de la période pour inclure uniquement ceux de l'utilisateur
                                    const filteredSerialNumbers = periodSerialNumbers.filter(serial => userSerialNumbers.includes(serial));
                                    // Mettre à jour l'état ou effectuer d'autres opérations avec les numéros de série filtrés
                                    setSerialNumbers(filteredSerialNumbers);
                                    setUpdateData(false);
                                })
                        })
                } else {
                    axios.get(apiUrl + `/api/user/serialsforuser`, {
                        headers: { 'x-access-token': sessionStorage.getItem("token") }
                    })
                        .then(userRes => {
                            // Extraire les numéros de série de l'utilisateur à partir de la réponse
                            const userSerialNumbers = userRes.data.map(user => user.username);

                            // Requête Axios POST pour récupérer les numéros de série de la période
                            axios.post(apiUrl + `/api/user/serialsperiod`,
                                { startdate: start, stopdate: stop, worksiteid: selectedWorksite },
                                { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                                .then(periodRes => {
                                    // Extraire les numéros de série de la période à partir de la réponse
                                    const periodSerialNumbers = Array.from(new Set(periodRes.data.map(data => data.serial)));
                                    // Filtrer les numéros de série de la période pour inclure uniquement ceux de l'utilisateur
                                    const filteredSerialNumbers = periodSerialNumbers.filter(serial => userSerialNumbers.includes(serial));
                                    // Mettre à jour l'état ou effectuer d'autres opérations avec les numéros de série filtrés
                                    setSerialNumbers(filteredSerialNumbers);
                                    setUpdateData(false);
                                })
                        })
                }
            }
        }
    }, [updateData, selectedStopDate, selectedStartDate, selectedWorksite]);



    /*********************************************************************************************************************************************** */
    /*********************************************************************************************************************************************** */
    /*********************************************************************************************************************************************** */

    if (selectedData.length > 0 && !isPlaying && cursorDateStop === "") {
        timeline_groups = [];
        selectedSerialNumber.forEach(element => {
            timeline_groups.push(
                {
                    id: element,
                    content: getNamebySerial(element),
                    items: []
                });
        });

        timeline_groups.sort((a, b) => {
            if (a.id < b.id) {
                return -1;
            } else if (a.id > b.id) {
                return 1;
            }
            return 0;
        });
        let startPeriod = new Date();
        const dates = selectedData.map(item => new Date(item.date)); // Conversion des dates en objets Date

        let minDate = dates.reduce((min, current) => {
            return current < min ? current : min;
        });

        let maxDate = dates.reduce((max, current) => {
            return current > max ? current : max;
        });


        if (location.state) {
            if (location.state.ts) {
                minDate = new Date(location.state.ts.getTime() - 10 * 1000);
                maxDate = new Date(location.state.ts.getTime() + 10 * 1000);
            }
            location.state = null;
        }

        startCurrentDate = minDate;

        let previous = startCurrentDate;
        let temp_positions = [];
        startPeriod = previous;
        timelineItems = [];
        polylines_positions = [];
        let previousSerial = "";
        periodIx = 0;
        let current = new Date();
        let current_serial = "";
        let polylineCoordinates = [];
        selectedData.forEach((c, index) => {

            if (c.latitude !== undefined && c.longitude !== undefined && isFinite(c.latitude) && isFinite(c.longitude))
                polylineCoordinates.push(new LatLng(c.latitude, c.longitude));

            current = c.date;
            current_serial = c.serial;

            if (index === 0) {
                // Pour la première itération, initialiser startPeriod
                startPeriod = current;
            }

            if (current_serial !== previousSerial && index !== 0) {
                // Si le numéro de série a changé et ce n'est pas la première itération, ajouter les données de la période précédente
                const color = getRandomColor();
                polylines_positions.push({
                    serial: previousSerial,
                    color: color,
                    positions: temp_positions,
                    period: 'P' + periodIx
                });

                timelineItems.push({
                    id: 'P' + periodIx,
                    content: 'Period ' + periodIx,
                    start: startPeriod,
                    end: previous,
                    type: 'background',
                    className: color,
                    group: previousSerial
                });

                periodIx++;
                startPeriod = current;
                temp_positions = [];
            }
            if (current.getTime() - previous.getTime() > 5000) {
                const color = getRandomColor();
                polylines_positions.push({
                    serial: current_serial,
                    color: color,
                    positions: temp_positions,
                    period: 'P' + periodIx
                });
                temp_positions = [];

                timelineItems.push({
                    id: 'P' + periodIx,
                    content: 'Period ' + periodIx,
                    start: startPeriod,
                    end: previous,
                    type: 'background',
                    className: color,
                    group: current_serial
                });
                periodIx++;
                startPeriod = current;
            }
            temp_positions.push({
                ts: c.date,
                lat: c.latitude,
                lng: c.longitude,
                speed: c.speed,
                orientation: c.orientation,
                type: c.type
            });
            previous = current;
            previousSerial = current_serial;
        });


        if (current !== startPeriod) {
            const color = getRandomColor();
            polylines_positions.push({
                serial: current_serial,
                color: color,
                positions: temp_positions,
                period: 'P' + periodIx
            });
            temp_positions = [];

            timelineItems.push({
                id: 'P' + periodIx,
                content: 'Period ' + periodIx,
                start: startPeriod,
                end: previous,
                type: 'background',
                className: color,
                group: current_serial
            });
        }
        customTimes = [{ id: 'start', datetime: minDate }];
        customTimes.push({ id: 'stop', datetime: maxDate });

        setCursorDateStart(minDate);
        setCursorDateStop(maxDate);
        set_polylines_positions_onscreen(polylines_positions, minDate, maxDate);
        // Bound de la map sur les datas 
        if (polylineCoordinates.length >= 2) {
            map_bounds = new LatLngBounds(polylineCoordinates);
            need_map_bound = true;
            need_timeline_fit = true;
        }
    }


    /*********************************************************************************************************************************************** */

    /*********************************************************************************************************************************************** */
    /*********************************************************************************************************************************************** */

    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    function FiltreByDateData(dateStart) {
        dateStart = new Date(dateStart);

        const filteredData = polylines_positions.reduce((result, polyline) => {
            const { serial, positions } = polyline;
            const filteredPositions = positions.filter((position) => {
                const positionDate = new Date(position.ts);
                return Math.floor(positionDate.getTime() / 1000) === Math.floor(dateStart.getTime() / 1000);
            });

            if (filteredPositions.length > 0) {
                const { lat, lng, speed, orientation, type } = filteredPositions[0];
                result.push({
                    serial,
                    lat,
                    lng,
                    speed,
                    orientation,
                    type,
                });
            }

            return result;
        }, []);

        return filteredData;
    }


    function set_polylines_positions_onscreen(datas, start_date, stop_date) {
        var tmp_pos = [];
        polylines_positions_onscreen = [];
        for (var i = 0; i < datas.length; i++) {
            for (var j = 0; j < datas[i].positions.length; j++) {
                if ((datas[i].positions[j].ts >= start_date) && (datas[i].positions[j].ts <= stop_date)) {
                    if ((isFinite(datas[i].positions[j].lat)) && (isFinite(datas[i].positions[j].lng)))
                        tmp_pos.push({ lat: datas[i].positions[j].lat, lng: datas[i].positions[j].lng });
                    else
                        datas[i].positions[j].lat = datas[i].positions[j].lat;
                }
            }
            if (tmp_pos.length > 0) {
                polylines_positions_onscreen.push({ color: datas[i].color, positions: tmp_pos });
                tmp_pos = [];
            }
        }

    }


    function getRandomColor() {
        const colors = ['red', 'blue', 'green', 'lime', 'magenta', 'yellow', 'orange', 'aqua']
        let idx = Math.floor(Math.random() * colors.length - 1);
        while (idx < 0)
            idx = Math.floor(Math.random() * colors.length - 1);
        return colors[idx];
    }

    function handleSerialNumberClick(serialNumber) {
        if (isPlaying)
            alert("Impossible de rajouter un tracé en mode lecture\nMerci de mettre pause avant");
        else {
            setSelectedSerialNumber((prevSelectedSerialNumbers) => {
                if (prevSelectedSerialNumbers.includes(serialNumber)) {
                    return prevSelectedSerialNumbers.filter((selectedSerialNumber) => selectedSerialNumber !== serialNumber);
                } else {
                    return [...prevSelectedSerialNumbers, serialNumber];
                }
            });
        }
    };


    const handleWorksiteChange = (event) => {
        setSelectedWorksite(event.target.value);
    };


    const dateHandlerCursor = (cursor) => {
        setIsPlaying(false);
        if (cursor.id === '1') {
            if (timelineRef.current.timeline.getCustomTime('1') < timelineRef.current.timeline.getCustomTime('0'))
                timelineRef.current.timeline.setCustomTime(timelineRef.current.timeline.getCustomTime('0'), '1');
            else if (timelineRef.current.timeline.customTimes.length > 2 && timelineRef.current.timeline.getCustomTime('1') < timelineRef.current.timeline.getCustomTime('2'))
                timelineRef.current.timeline.setCustomTime(timelineRef.current.timeline.getCustomTime('2'), '1');
            else
                setCursorDateStop(cursor.time);
        } else if (cursor.id === '0') {

            if (timelineRef.current.timeline.getCustomTime('0') > timelineRef.current.timeline.getCustomTime('1'))
                timelineRef.current.timeline.setCustomTime(timelineRef.current.timeline.getCustomTime('1'), '0');
            else if (timelineRef.current.timeline.customTimes.length > 2 && timelineRef.current.timeline.getCustomTime('0') > timelineRef.current.timeline.getCustomTime('2'))
                timelineRef.current.timeline.setCustomTime(timelineRef.current.timeline.getCustomTime('2'), '0');
            else
                setCursorDateStart(cursor.time)
        } else if (cursor.id === '2') {
            if (timelineRef.current.timeline.getCustomTime('2') > timelineRef.current.timeline.getCustomTime('1'))
                timelineRef.current.timeline.setCustomTime(timelineRef.current.timeline.getCustomTime('1'), '2');
            else if (timelineRef.current.timeline.getCustomTime('2') < timelineRef.current.timeline.getCustomTime('0'))
                timelineRef.current.timeline.setCustomTime(timelineRef.current.timeline.getCustomTime('0'), '2');
            else
                setCursorDatePlay(cursor.time)
        }
    }

    const handleCheckboxChange = (event) => {
        if (isPlaying)
            alert("Merci de mettre pause avant de quitter le mode replay automatique");
        else
            setisReplayPlayPauseChecked(event.target.checked);
    };

    const handleCheckboxChangeMarker = (event) => {

        setisMarkerChecked(event.target.checked);
    };


    function rangeChangeHandler(props) {
        // handle range change
        var end = timelineRef.current.timeline.range.end;
        var start = timelineRef.current.timeline.range.start;
        var seconds = (end - start) / 1000;
        if (seconds !== old_timeline_range_second) {
            old_timeline_range_second = seconds;
            if ((seconds / timelineRef.current.props.options.timeAxis.step > 18) || (seconds / timelineRef.current.props.options.timeAxis.step < 2)) {
                timeline_timeAxis_step = seconds / 6;
                timelineRef.current.props.options.timeAxis.step = timeline_timeAxis_step;
                timelineRef.current.timeline.redraw();
            }
        }
    }

    const handlePlayClick = () => {
        if (isPlaying) {
            setIsPlaying(false);
        } else {
            setIsPlaying(true);
        }
    };
    const handleStopClick = () => {
        setIsPlaying(false);
    };

    const handleUpdate = () => {
        setUpdateData(true);
    }

    const ResetHighlightPolyline = () => {
        const map = mapRef.current;

        map.eachLayer(layer => {
            if (layer instanceof L.Polyline) {
                layer.setStyle({
                    opacity: 1,
                    weight: 3
                });
                layer.bringToBack();
            }
        });
        setShowHighlightPolyline(false);


    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        if (date) {
            calculateStopDate(date, numberOfDays);
        }
    };

    const togglePolyline = () => {
        setShowPolyline(!showPolyline);
    };


    const options = {
        showCurrentTime: true,
        autoResize: true,
        timeAxis: { scale: 'second', step: timeline_timeAxis_step },
        showMinorLabels: false,
        selectable: true,
        format: {
            minorLabels: {
                second: 's',
            },
            majorLabels: {
                second: 'DD/MM HH:mm',
            }
        }
    }

    const timelineClickHandler = (event) => {
        if (event.customTime === null) {
            const periodClick = timelineItems.find(e =>
                e.group === event.group &&
                event.time >= e.start &&
                event.time <= e.end
            );
            if (periodClick) {
                setShowHighlightPolyline(true);
                const map = mapRef.current;

                const polylineClick = polylines_positions.find(data => data.period === periodClick.id);

                const positionsLatLngClick = polylineClick.positions.map(point => L.latLng(point.lat, point.lng));
                setPolylineClick(positionsLatLngClick)

                map.eachLayer(layer => {
                    if (layer instanceof L.Polyline) {
                        if (JSON.stringify(layer.getLatLngs()) === JSON.stringify(positionsLatLngClick)) {
                            layer.setStyle({
                                opacity: 1,
                                weight: 5
                            });
                            layer.bringToFront()
                        } else {
                            layer.setStyle({
                                opacity: OpacityValue / 10,
                                weight: 2
                            });
                        }
                    }
                });
            }
        }
    };

    useEffect(() => {
        const map = mapRef.current;
        if (polylineClick.length > 0) {
            map.eachLayer(layer => {
                if (layer instanceof L.Polyline) {
                    if (JSON.stringify(layer.getLatLngs()) === JSON.stringify(polylineClick)) {
                        layer.setStyle({
                            opacity: 1,
                            weight: 5
                        });
                        layer.bringToFront()
                    } else {
                        layer.setStyle({
                            opacity: OpacityValue / 10,
                            weight: 2
                        });
                    }
                }
            });
        }
    }, [OpacityValue, polylineClick]);

    const MapComponent = () => {
        const map = useMap();
        mapRef.current = map;
        return null;
    };

    const getPictoByTypeDevice = (type) => {

        const picto = Picto.find(device => device.id_device_type === type);

        if (picto)
            return picto.picto;
    }

    const handleNumberOfDaysChange = (event) => {
        const days = parseInt(event.target.value, 10);
        setNumberOfDays(days);
        if (selectedStartDate) {
            calculateStopDate(selectedStartDate, days);

        }
    };

    const calculateStopDate = (startDate, days) => {
        const stopDate = new Date(startDate);
        stopDate.setDate(stopDate.getDate() + days - 1);
        setSelectedStopDate(stopDate);
    };

    function formatDateWithTime(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return <div><p><strong>{day}/{month}/{year}<br />{hours}:{minutes}:{seconds}</strong></p></div>;
    }



    return (
        <div>
            <div className='frameReplay'>
                <div className='divReplay'>
                    <h6 className='h6ReplayWorksite'>Worksite:</h6>
                    <Select className='selectWorksite' value={selectedWorksite}
                        onChange={handleWorksiteChange}>
                        {Worksite.map((worksite, index) => (
                            <MenuItem key={index} value={worksite.worksiteid}>{worksite.worksitename}</MenuItem>
                        ))}
                    </Select>
                    <h6 className='h6ReplayStart'>Start : </h6>
                    <span className='spanDateReplay'>
                        <DatePicker showIcon
                            locale={fr}
                            dateFormat="dd/MM/yyyy"
                            selected={selectedStartDate}
                            onChange={(date) => handleStartDateChange(date)}
                            className="date-picker"
                        />
                    </span>

                    <TextField
                        style={{ marginLeft: "1%" }}
                        type="number"
                        max="2"
                        value={numberOfDays}
                        onChange={handleNumberOfDaysChange}
                        InputProps={{
                            inputProps: { min: 1, max: 2 },
                            endAdornment: <InputAdornment position="end">jours</InputAdornment>,
                        }}
                    />

                    <Button variant="outlined" size="large" sx={{ height: 55 }} style={{ marginLeft: "1%", width: "5%" }} onClick={handleUpdate}>Update</Button>

                    <h6 className="h6ReplayStart">Periode du : {selectedStartDate.toLocaleDateString("fr-FR")} au {selectedStopDate.toLocaleDateString("fr-FR")}  </h6>
                </div>

                <div className='frameMapReplay'>
                    {isLoading ? (
                        <div className='divLoadingReplay'>
                            <img src={Loader} alt="Chargement..." className='imgLoadingReplay' />
                        </div>
                    ) : serialNumbers.length > 0 ?
                        selectedSerialNumber.length > 0 ?
                            selectedData.length > 0 ?
                                <MapContainer ref={leafletRef}
                                    center={[selectedData[0].latitude, selectedData[0].longitude]} zoom={12}
                                    scrollWheelZoom={true}
                                    whenReady={(e) => {
                                        if (need_map_bound) {
                                            e.target.fitBounds([map_bounds._southWest, map_bounds._northEast]);
                                            need_map_bound = false;
                                        }
                                    }}
                                    whenCreated={mapInstance => {
                                        mapRef.current = mapInstance;
                                    }}
                                    className='mapContainerReplay'>
                                    <TileLayer
                                        url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                        maxZoom={22}
                                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                    />
                                    {showPolyline ?
                                        polylines_positions_onscreen.map((data, index) => (
                                            <Polyline key={index}
                                                pathOptions={{ color: data.color }}
                                                positions={data.positions} />
                                        ))
                                        : []
                                    }
                                    {isMarkerChecked ? Markers.length > 0 ?
                                        Markers.map((data, index) => (
                                            <Marker key={index}
                                                position={[data.lat, data.lng]}
                                                icon={createCustomIcon(require("Public/images/picto/" + getPictoByTypeDevice(data.type)))}>
                                                <Popup>{getNamebySerial(data.serial)} <br /> <img src={require("Public/images/picto/" + getPictoByTypeDevice(data.type))} alt="" style={{ width: "100%" }} />{data.speed} km/h</Popup>
                                            </Marker>)) : "" : ""
                                    }
                                    {Markers.length > 0 ?
                                        Markers.map((data, index) => (
                                            <Marker key={index}
                                                position={[data.lat, data.lng]}
                                                icon={customIconEmpty}>
                                                <Popup>{getNamebySerial(data.serial)} <br /> <img src={require("Public/images/picto/" + getPictoByTypeDevice(data.type))} alt="" style={{ width: "100%" }} /><br />{data.speed} km/h</Popup>
                                            </Marker>)) : ""
                                    }

                                    {isReplayPlayPauseChecked ? !isMarkerChecked ? MarkerData.length > 0 ?
                                        MarkerData.map((data, index) => (
                                            <Polygon key={index} positions={data} color="red" />
                                        )) : "" : "" : ""
                                    }
                                    {isReplayPlayPauseChecked ? !isMarkerChecked ? MarkerData.length > 0 ?
                                        MarkerData.map((data, index) => (
                                            <Polygon key={index} positions={[data[2], data[3]]} color="black" />
                                        )) : "" : "" : ""
                                    }
                                    <MapComponent />
                                    <div className="leaflet-bottom leaflet-left"></div>
                                    {isReplayPlayPauseChecked && boolCustomTimeNow ?
                                        <div className="leaflet-top leaflet-right" style={{
                                            backgroundColor: 'rgba(169, 169, 169, 0.7)',
                                            color: 'white',
                                            textAlign: 'center',
                                            margin: 'auto',
                                            marginTop: '1%',
                                            marginRight: '1%'
                                        }}>
                                            {formatDateWithTime(timelineRef.current.timeline.getCustomTime('2'))}
                                        </div> : ""
                                    }
                                    <FeatureGroup> </FeatureGroup>
                                </MapContainer>
                                : <div className='divAfficherReplay'>
                                    <h3 className='h3AfficherReplay'>Veuillez selectionner un véhicule
                                        pour afficher le replay</h3>
                                </div>
                            : <div className='divAfficherReplay'>
                                <h3 className='h3AfficherReplay'>Veuillez selectionner un véhicule pour
                                    afficher le replay</h3>
                            </div> : ""
                    }
                    <div className='marginLeft1Replay'>
                        {serialNumbers.length > 0 ?
                            <Card className='cardReplaySerial'>
                                <Card.Body>
                                    <div>
                                        {serialNumbers
                                            .slice() // Crée une copie pour éviter de modifier le tableau d'origine
                                            .sort((a, b) => {
                                                const nameA = getNamebySerial(a).toLowerCase();
                                                const nameB = getNamebySerial(b).toLowerCase();
                                                if (nameA < nameB) return -1;
                                                if (nameA > nameB) return 1;
                                                return 0;
                                            })
                                            .map(data => (
                                                <div key={data}>
                                                    <input
                                                        type="checkbox"
                                                        id={data}
                                                        name={data}
                                                        value={data}
                                                        onChange={() => handleSerialNumberClick(data)}
                                                        checked={selectedSerialNumber.includes(data)}
                                                        ref={selectedSerialNumber_input_ref}
                                                    />
                                                    {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ?
                                                        <label style={{ color: 'black' }}
                                                            htmlFor={data}>{getNamebySerial(data)} ({data}) </label> :
                                                        <label style={{ color: 'black' }}
                                                            htmlFor={data}>{getNamebySerial(data)}</label>}
                                                </div>
                                            ))}
                                    </div>
                                </Card.Body>
                            </Card>
                            : <p>Pas de données pour cette période</p>}
                    </div>

                </div>
                <div className='divFlexReplay'>
                    {!isLoading ? selectedSerialNumber.length > 0 ?
                        <label className='marginTop1Replay'>
                            <input
                                type="checkbox"
                                checked={isReplayPlayPauseChecked}
                                onChange={handleCheckboxChange} />
                            Replay
                        </label>
                        : "" : ""
                    }

                    {isReplayPlayPauseChecked ?
                        <label className='marginTop1Marginleft1Replay'>
                            <input
                                type="checkbox"
                                checked={isMarkerChecked}
                                onChange={handleCheckboxChangeMarker} />
                            Change marker
                        </label>
                        : ""
                    }
                    {showHighlightPolyline ?
                        <Button variant="contained" color="primary" className='btnResetHighlight'
                            onClick={ResetHighlightPolyline}>Reset Highlight</Button> : ""}

                    {showHighlightPolyline ?
                        <h5 className='marginTop1Marginleft1Replay'>{OpacityValue} opacity</h5> : ""}
                    {showHighlightPolyline ? <input className='inputOpacityReplay'
                        type="range"
                        min="0"
                        max="10"
                        value={OpacityValue}
                        onChange={(e) => setOpacityValue(parseInt(e.target.value))} /> : ""}
                    {isReplayPlayPauseChecked ?
                        <Button className='buttonPlayStop'
                            onClick={handlePlayClick}>{isPlaying ?
                                <FontAwesomeIcon icon={faPause}></FontAwesomeIcon> :
                                <FontAwesomeIcon icon={faPlay}></FontAwesomeIcon>}</Button> : ""
                    }
                    {isReplayPlayPauseChecked ?
                        <Button className='buttonPlayStop' variant="danger"
                            onClick={handleStopClick}><FontAwesomeIcon
                                icon={faStop}></FontAwesomeIcon></Button> : ""
                    }
                    {isReplayPlayPauseChecked ?
                        <h5 className='marginTop1Marginleft1Replay'>{intervalValue} x</h5> : ""}
                    {isReplayPlayPauseChecked ? <input className='inputIntervalReplay'
                        type="range"
                        min="1"
                        max="100"
                        value={intervalValue}
                        onChange={(e) => setIntervalValue(parseInt(e.target.value))} /> : ""}
                    {isReplayPlayPauseChecked ?
                        <label className='marginTop1Marginleft1Replay' htmlFor="polylineCheckbox">Afficher la
                            polyline</label> : ""}
                    {isReplayPlayPauseChecked ? <input className='marginLeft1Replay'
                        type="checkbox"
                        id="polylineCheckbox"
                        checked={showPolyline}
                        onChange={togglePolyline}
                    /> : ""}
                </div>
                {!isLoading ? selectedSerialNumber.length > 0 ? selectedData.length > 0 ?
                    <Timeline
                        ref={timelineRef}
                        initialItems={timelineItems}
                        options={options}
                        customTimes={customTimes}
                        initialGroups={timeline_groups}
                        timechangeHandler={dateHandlerCursor}
                        rangechangeHandler={rangeChangeHandler}
                        clickHandler={timelineClickHandler}
                        keys={{ groupIdKey: 'id', itemGroupKey: 'group' }}
                    /> : "" : "" : ""}
            </div>
        </div>

    );
}

export default Replay;