import React from 'react';
import './CommentList.css';

function CommentList({ comments }) {
    return (
        <div>
            <h2>Commentaires</h2>
            <ul className="comment-list">
                {comments.map((comment, index) => (
                    <li key={index} className="comment-item">
                        <span className="comment-user">{comment.user} :</span>
                        <span className="comment-text">{comment.comment}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default CommentList;
