import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import Leaflet from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer, Polygon } from 'react-leaflet';
import axios from 'axios';
import withRouter from "Hook/WithRouter";
import { Button } from 'react-bootstrap';
import MarkerTransparent from 'Public/images/transparent.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./FleetVisualization.css"
import { deviceTemplates } from "../../utils/DeviceTemplate";
import useWebSocket from "react-use-websocket"


const apiUrl = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;
let need_map_bound = false;
let stop_bound = false;
let lastData = null;
const FleetVisualization = () => {
    const [filteredConnected, setFilteredConnected] = useState([]);
    const [deviceName, setDeviceName] = useState([]);
    const [bounds, setBounds] = useState(null);
    const [Picto, setPicto] = useState([]);
    const [MarkerData, setMarkerData] = useState([]);
    const [typeDevice, setTypeDevice] = useState([]);
    const [zoomLevel, setZoomLevel] = useState(6);
    const [showPolygons, setShowPolygons] = useState(false);
    const markerRefs = useRef({});
    const leafletRef = useRef();
    const userid = sessionStorage.getItem("userId")
    const channelname = "filterconnected";

    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname },
            share: false,
            shouldReconnect: () => true,

        },
    )

    useEffect(() => {
        if (lastJsonMessage !== null) {
            setFilteredConnected(lastJsonMessage)
            calculateBounds();
        }
    }, [lastJsonMessage])



    const customIconEmpty = Leaflet.icon({
        iconUrl: MarkerTransparent,
        iconAnchor: [17.5, 17.5],
        iconSize: [35, 35],
    });

    useEffect(() => {

        sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ?
            axios.get(apiUrl + '/api/admin/serialsforadmin', {
                headers: { 'x-access-token': sessionStorage.getItem('token') }
            })
                .then(res => {
                    const data = res.data;
                    const deviceSerial = data.map(item => item.username);
                    sendJsonMessage(deviceSerial);

                }) :
            axios.get(apiUrl + '/api/user/serialsforuser', {
                headers: { 'x-access-token': sessionStorage.getItem('token') }
            })
                .then(res => {
                    const data = res.data;
                    const deviceSerial = data.map(item => item.username);
                    sendJsonMessage(deviceSerial);
                })

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    const deviceName = res.data;
                    setDeviceName(deviceName);
                })
        } else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    const deviceName = res.data;
                    setDeviceName(deviceName);
                })
        }

        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                const Picto = res.data;
                setPicto(Picto);
            });

        return () => {
            if (leafletRef.current) {
                leafletRef.current.removeEventListener('zoomend', handleZoomChange);
            }
        };
    }, []);

    const handleZoomChange = () => {
        const currentZoom = leafletRef.current.getZoom();
        setZoomLevel(currentZoom);
        if (currentZoom >= 15) {
            setShowPolygons(true);
        } else {
            setShowPolygons(false);
        }
    };

    const openPopup = (id) => {
        const marker = markerRefs.current[id];
        if (marker) {
            marker.openPopup(); // Ouvrir le popup associé au marqueur
        }
    };
    const calculateBounds = () => {
        let minLat = Number.MAX_VALUE;
        let maxLat = Number.MIN_VALUE;
        let minLon = Number.MAX_VALUE;
        let maxLon = Number.MIN_VALUE;

        Object.values(filteredConnected).forEach(({ last_keepAlive }) => {
            if (last_keepAlive.gps_status) {
                const { latitude, longitude } = last_keepAlive.current_position;
                minLat = Math.min(minLat, latitude);
                maxLat = Math.max(maxLat, latitude);
                minLon = Math.min(minLon, longitude);
                maxLon = Math.max(maxLon, longitude);
            }
        });

        // Ajoutez une marge de 1 degré dans toutes les directions
        const bounds = [
            [minLat, minLon],
            [maxLat, maxLon],
        ];


        const latlongBounds = Leaflet.latLngBounds(bounds);
        const defaultMaxValue = 1.7976931348623157e+308;
        const defaultMinValue = 5e-324;

        if (
            bounds[0][0] !== defaultMaxValue ||
            bounds[0][1] !== defaultMaxValue ||
            bounds[1][0] !== defaultMinValue ||
            bounds[1][1] !== defaultMinValue
        ) {
            setBounds(latlongBounds);
            need_map_bound = true;
        }
    }

    const getNamebySerial = (serial) => {
        if (deviceName.length > 0) {
            const device = deviceName.find(device => device.username === serial);
            let NameDevice = JSON.parse(device.name_device);
            let lastDevice;
            if (NameDevice.length > 1)
                lastDevice = NameDevice[NameDevice.length - 1].nameDevice;
            else
                lastDevice = NameDevice.nameDevice


            let str = lastDevice;
            if (str.startsWith('noname'))
                str = str + '(' + serial + ')';
            return str;
        }
    }

    const coordinateOffsetToTemplate = (coord) => {
        let deviceTemplate;
        const transformedCoordinates = coord.map((coordonnee) => {
            const type = coordonnee.type;
            const angle = coordonnee.angle;
            axios.post(apiUrl + `/api/user/familydevicetype`,
                { id_device_type: type },

                {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                })
                .then(res => {
                    const typeDevice = res.data;
                    setTypeDevice(typeDevice);

                })

            if (typeDevice.length > 0) {
                const typeDeviceName = typeDevice[0].nom_device_type.replace(/\s/g, "");
                deviceTemplate = deviceTemplates.find((template) => {
                    return template.name === typeDeviceName;
                })
            }

            if (deviceTemplate !== undefined) {
                const lat1Offset = coordonnee.lat + (deviceTemplate.offsetY / 6366093) * (180 / Math.PI);

                const lng1Offset = coordonnee.lng + (deviceTemplate.offsetX / 6366093) * (180 / Math.PI) / Math.cos(coordonnee.lat * Math.PI / 180);

                const lat2Offset = coordonnee.lat + (deviceTemplate.offsetY / 6366093) * (180 / Math.PI);
                const lng2Offset = coordonnee.lng + ((deviceTemplate.sizeX + deviceTemplate.offsetX) / 6366093) * (180 / Math.PI) / Math.cos(coordonnee.lat * Math.PI / 180);

                const lat3Offset = coordonnee.lat + ((deviceTemplate.offsetY + deviceTemplate.sizeY) / 6366093) * (180 / Math.PI);
                const lng3Offset = coordonnee.lng + ((deviceTemplate.offsetX) / 6366093) * (180 / Math.PI) / Math.cos(coordonnee.lat * Math.PI / 180);

                const lat4Offset = coordonnee.lat + ((deviceTemplate.offsetY + deviceTemplate.sizeY) / 6366093) * (180 / Math.PI);
                const lng4Offset = coordonnee.lng + ((deviceTemplate.offsetX + deviceTemplate.sizeX) / 6366093) * (180 / Math.PI) / Math.cos(coordonnee.lat * Math.PI / 180);

                const corner1 = L.latLng(lat1Offset, lng1Offset);
                const corner2 = L.latLng(lat2Offset, lng2Offset);
                const corner3 = L.latLng(lat3Offset, lng3Offset);
                const corner4 = L.latLng(lat4Offset, lng4Offset);
                return rotatePoints(new L.LatLng(coordonnee.lat, coordonnee.lng), [corner1, corner2, corner4, corner3], angle);
            }
        })
        if (transformedCoordinates[0] === undefined)
            return transformedCoordinates[0]

        return transformedCoordinates;
    };

    const rotatePoints = (center, points, yaw) => {
        const res = [];
        const centerPoint = leafletRef.current.latLngToLayerPoint(center);
        const angle = yaw * (Math.PI / 180);

        for (let i = 0; i < points.length; i++) {
            const p = leafletRef.current.latLngToLayerPoint(points[i]);
            const p2 = new L.Point(p.x - centerPoint.x, p.y - centerPoint.y);
            const p3 = new L.Point(Math.cos(angle) * p2.x - Math.sin(angle) * p2.y, Math.sin(angle) * p2.x + Math.cos(angle) * p2.y);
            const p4 = new L.Point(p3.x + centerPoint.x, p3.y + centerPoint.y);
            const latLng = leafletRef.current.layerPointToLatLng(p4);
            res.push(latLng);
        }

        return res;
    }

    const getPictoByTypeDevice = (type) => {

        const picto = Picto.find(device => device.id_device_type === type);

        if (picto)
            return picto.picto;
    }

    useEffect(() => {
        if (leafletRef.current) {
            leafletRef.current.addEventListener('zoomend', handleZoomChange);
        }

        let hasDataChanged = true;
        if (lastData !== null) {
            hasDataChanged = !deepCompare(FiltreData(), lastData);
        }
        if (hasDataChanged) {
            const DataMarker = coordinateOffsetToTemplate(FiltreData());

            if (DataMarker !== undefined) {
                setMarkerData(DataMarker);
                lastData = FiltreData();
            }
        }
    }, [filteredConnected]);

    function deepCompare(obj1, obj2) {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

        for (const key in obj1) {
            if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                if (!deepCompare(obj1[key], obj2[key])) {
                    return false;
                }
            } else if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
        return true;
    }


    const FiltreData = () => {

        const filteredData = Object.entries(filteredConnected).filter(([id, filtered]) => filtered.last_keepAlive.gps_status).map(([id, filtered]) => {
            const serial = filtered.SerialStr;
            const type = filtered.last_keepAlive.current_CB_type;
            const lat = filtered.last_keepAlive.current_position.latitude;
            const lng = filtered.last_keepAlive.current_position.longitude;
            const angle = filtered.last_keepAlive.current_angle;
            const connected = filtered.IsConnected;

            return {
                serial,
                lat,
                lng,
                type,
                angle,
                connected
            };
        });

        return filteredData;

    }




    const customMarkerIconblue = Leaflet.divIcon({
        html: ReactDOMServer.renderToString(<FontAwesomeIcon icon={faMapMarkerAlt} size="3x" style={{ color: 'blue' }} />),
        className: 'leaflet-div-icon'
    });

    const customMarkerIconRed = Leaflet.divIcon({
        html: ReactDOMServer.renderToString(<FontAwesomeIcon icon={faMapMarkerAlt} size="3x" style={{ color: 'red' }} />),
        className: 'leaflet-div-icon'
    });


    if (need_map_bound && !stop_bound) {
        if (leafletRef.current) {
            leafletRef.current.fitBounds([bounds._southWest, bounds._northEast]);
            need_map_bound = false;
            stop_bound = true;
        }
    }

    return (
        <>
            <div className='divFleet'>
                <MapContainer ref={leafletRef} center={[46.922407, 2.670694]} zoom={zoomLevel} scrollWheelZoom={true} className='mapContainer'>
                    <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        maxZoom={22}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />

                    {!showPolygons && Object.entries(filteredConnected).map(([id, { SerialStr, connection_Date, disconnection_Date, IsConnected, last_keepAlive, isUploading, upload_filesize, upload_size_sended, upload_result }]) => (
                        last_keepAlive.gps_status ? IsConnected ? (
                            <Marker ref={ref => markerRefs.current[id] = ref}
                                position={[last_keepAlive.current_position.latitude, last_keepAlive.current_position.longitude]}
                                key={id} icon={customMarkerIconblue}>
                                <Popup>
                                    <img src={require("Public/images/picto/" + getPictoByTypeDevice(last_keepAlive.current_CB_type))} alt="" style={{ width: "100%" }} />
                                    {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? <>{getNamebySerial(SerialStr)} ({SerialStr})</> : <>{getNamebySerial(SerialStr)}</>}
                                </Popup>
                            </Marker>
                        ) : (
                            <Marker ref={ref => markerRefs.current[id] = ref}
                                position={[last_keepAlive.current_position.latitude, last_keepAlive.current_position.longitude]}
                                key={id} icon={customMarkerIconRed}>
                                <Popup>
                                    <img src={require("Public/images/picto/" + getPictoByTypeDevice(last_keepAlive.current_CB_type))} alt="" style={{ width: "100%" }} />
                                    {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? <>{getNamebySerial(SerialStr)} ({SerialStr})</> : <>{getNamebySerial(SerialStr)}</>}
                                </Popup>
                            </Marker>
                        ) : ""
                    ))}

                    {showPolygons && Object.entries(MarkerData).map(([id, data]) => {
                        const isConnected = filteredConnected[id]?.IsConnected;
                        const color = isConnected ? "blue" : "red";
                        return (
                            <Polygon interactive={false} key={id} positions={data} color={color} />
                        );
                    })}

                    {showPolygons && Object.entries(MarkerData).map(([id, data]) => (
                        <Polygon interactive={false} key={id} positions={[data[2], data[3]]} color="black" />
                    ))}

                    {showPolygons && Object.entries(filteredConnected).map(([id, { SerialStr, last_keepAlive }]) => (
                        last_keepAlive.gps_status && last_keepAlive.current_position.latitude > 0 && last_keepAlive.current_position.longitude > 0 ? (
                            <Marker ref={ref => markerRefs.current[id] = ref}
                                position={[last_keepAlive.current_position.latitude, last_keepAlive.current_position.longitude]}
                                key={id} icon={customIconEmpty}
                                >
                                <Popup>
                                    <img src={require("Public/images/picto/" + getPictoByTypeDevice(last_keepAlive.current_CB_type))} alt="" style={{ width: "100%" }} />
                                    {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? <>{getNamebySerial(SerialStr)} ({SerialStr})</> : <>{getNamebySerial(SerialStr)}</>}
                                </Popup>
                            </Marker>
                        ) : ""
                    ))}
                </MapContainer>
            </div>
            <div style={{ paddingTop: '1%' }}>
                {Object.entries(filteredConnected).map(([id, { SerialStr, last_keepAlive }]) => (
                    last_keepAlive.gps_status ? (
                        <Button key={id} style={{ marginLeft: '1%', marginTop: '1%' }} onClick={() => openPopup(id)}>
                            {getNamebySerial(SerialStr)} {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? '(' + SerialStr + ')' : ''}
                        </Button>
                    ) : ""
                ))}
            </div>
        </>
    );

}


export default withRouter(FleetVisualization);