// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-list {
    list-style-type: none;
    padding: 0;
}

.comment-item {
    margin: 10px 0;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.comment-user {
    font-weight: bold;
    margin-right: 10px;
}

.comment-text {
    word-wrap: break-word;
}

h2 {
    font-size: 20px;
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/Comment/CommentList.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".comment-list {\n    list-style-type: none;\n    padding: 0;\n}\n\n.comment-item {\n    margin: 10px 0;\n    padding: 10px;\n    background-color: #f5f5f5;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n}\n\n.comment-user {\n    font-weight: bold;\n    margin-right: 10px;\n}\n\n.comment-text {\n    word-wrap: break-word;\n}\n\nh2 {\n    font-size: 20px;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
