// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker__input-container .react-datepicker__calendar-icon {
    padding: unset !important;
}
.react-datepicker__calendar-icon {
    height: 2em !important;
    margin-left: 0.5em;
}
.react-datepicker-popper{
    z-index:900 !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".react-datepicker__input-container .react-datepicker__calendar-icon {\n    padding: unset !important;\n}\n.react-datepicker__calendar-icon {\n    height: 2em !important;\n    margin-left: 0.5em;\n}\n.react-datepicker-popper{\n    z-index:900 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
