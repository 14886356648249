import React, {useEffect, useRef, useState} from 'react';
import {
    Select,
    MenuItem,
    Input,
    Button,
    Box,
    Paper,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField, FormControl, InputLabel
} from "@mui/material";
import axios from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useNavigate} from "react-router-dom";
import './Setting.css'

const apiUrl = process.env.REACT_APP_API_URL;

const SettingPage = () => {

    const [Worksite, setWorksite] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState('');
    const [selectedWorksite, setSelectedWorksite] = useState("");
    const [maxSpeedWorksite, setMaxSpeedWorksite] = useState("");
    const [ratio, setRatio] = useState({});
    const [lowSpeedScrap, setLowSpeedScrap] = useState("");
    const [highSpeedScrap, setHighSpeedScrap] = useState("");
    const [highSpeedDumper, setHighSpeedDumper] = useState("");
    const [lowSpeedDumper, setLowSpeedDumper] = useState("");
    const [families, setFamilies] = useState([]);
    const [production, setProduction] = useState("");
    const [plateforme, setPlateforme] = useState("");
    const [positionLayers, setPositionLayers] = useState("");
    const [resetWorksite, setResetWorksite] = useState(0);
    const [typeDevice, setTypeDevice] = useState([]);
    const navigate = useNavigate();
    // État pour stocker les noms des routes


    useEffect(() => {
        axios.get(apiUrl + `/api/user/worksite`, {
            headers: {'x-access-token': sessionStorage.getItem("token")}
        })
            .then(res => {
                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
            })
    }, [resetWorksite]);


    useEffect(() => {
        axios.get(apiUrl + `/api/user/familydevice`, {
            headers: {'x-access-token': sessionStorage.getItem("token")}
        })
            .then(res => {
                setTypeDevice(res.data);
            })

        axios.get(apiUrl + `/api/user/families`, {
            headers: {'x-access-token': sessionStorage.getItem("token")}
        })
            .then(res => {
                setFamilies(res.data);
            })

    }, []);

    useEffect(() => {
        if(Worksite.length > 0) {
            setSelectedWorksite(Worksite[0].worksiteid)
        }
    }, [Worksite]);



    const handleWorksiteChange = (event) => {
        setSelectedWorksite(event.target.value);
    };

    const handleMaxSpeedChangeWorksite = (event) => {
        setMaxSpeedWorksite(event.target.value);
    };


    const handleHighSpeedScrap = (event) => {
        setHighSpeedScrap(event.target.value);
    };
    const handleHighSpeedDumper = (event) => {
        setHighSpeedDumper(event.target.value);
    };


    const handleLowSpeedScrap = (event) => {
        setLowSpeedScrap(event.target.value);
    };

    const handleLowSpeedDumper = (event) => {
        setLowSpeedDumper(event.target.value);
    };

    useEffect(() => {
        const selectedWorksiteData = Worksite.find(worksite => worksite.worksiteid === selectedWorksite);

        if (selectedWorksiteData) {
            const worksiteParameters = JSON.parse(selectedWorksiteData.worksiteparameters);
            if(worksiteParameters !== null) {
                setMaxSpeedWorksite(worksiteParameters.security.Speedmax_site_global);

                if(worksiteParameters.production.families_ratio  !== undefined)
                    setRatio(worksiteParameters.production.families_ratio)
                else
                    setRatio({});

                if(worksiteParameters.plateforme.detectionrotation !== undefined) {

                    if(worksiteParameters.plateforme.detectionrotation.Scrap !== undefined){
                        setHighSpeedScrap(worksiteParameters.plateforme.detectionrotation.Scrap.HighSpeed)
                        setLowSpeedScrap(worksiteParameters.plateforme.detectionrotation.Scrap.LowSpeed)
                    }
                    else{
                        setHighSpeedScrap("");
                        setLowSpeedScrap("");
                    }

                    if(worksiteParameters.plateforme.detectionrotation.Dumper !== undefined){
                        setLowSpeedDumper(worksiteParameters.plateforme.detectionrotation.Dumper.LowSpeed)
                        setHighSpeedDumper(worksiteParameters.plateforme.detectionrotation.Dumper.HighSpeed)
                    }
                    else{
                        setLowSpeedDumper("");
                        setHighSpeedDumper("");
                    }

                }else{
                    setHighSpeedScrap("")
                    setLowSpeedScrap("")
                    setLowSpeedDumper("")
                    setHighSpeedDumper("")
                }

            }else{
                setMaxSpeedWorksite("")
                setHighSpeedScrap("")
                setLowSpeedScrap("")
                setLowSpeedDumper("")
                setHighSpeedDumper("")
            }

        }
    }, [selectedWorksite]);

    const handleSubmit = () => {


        const dataWorksiteParameters = {
            security: {
                Speedmax_site_global: maxSpeedWorksite,
                // Ajoutez d'autres clés/valeurs spécifiques à la sécurité ici
            },
            production: {
                families_ratio : ratio,
            },
            positionLayers: {
                // Ajoutez d'autres clés/valeurs spécifiques à la sécurité ici
            },
            plateforme: {
                detectionrotation:{
                    Scrap:{
                        HighSpeed:highSpeedScrap,
                        LowSpeed:lowSpeedScrap
                    },
                    Dumper:{
                        HighSpeed:highSpeedDumper,
                        LowSpeed:lowSpeedDumper
                    }
                }
            },
            }

        // Convertir l'objet en une chaîne JSON
        const jsonDataWorksiteParameters = JSON.stringify(dataWorksiteParameters);

        axios.post(apiUrl + "/api/user/updateworksiteparameters", {
            worksiteparameters: jsonDataWorksiteParameters,
            worksiteid: selectedWorksite
        }, {
            headers: {'x-access-token': sessionStorage.getItem("token")}
        })
            .then((response) => {
                alert(response.data.message);
                setResetWorksite(resetWorksite+1);
            })
            .catch((error) => {
                console.error("Erreur lors de l'update :", error);
                alert("Erreur lors de l'update");
            });
    };

    const ButtonEditWorksite = (worksiteid) => {

        if(sessionStorage.getItem("Roles").includes("ROLE_ADMIN")){
            navigate('/admin/map',{
                state: {
                    worksite: worksiteid
                }
            });
        }
        else{
            navigate('/user/map',{
                state: {
                    worksite: worksiteid
                }
            });
        }



    }

    const handleRatioChange = (family, value) => {
        setRatio((prevRatios) => ({
            ...prevRatios,
            [family]: value,
        }));
    };

    const handleFamilyChange = (family) => {
        setSelectedFamily(family);
    };

    return (
        <Grid container >
            <Paper elevation={3} className='paperSetting'>
                <Typography variant="h4" gutterBottom>
                    Settings
                </Typography>

                <Select
                    fullWidth
                    variant="outlined"
                    value={selectedWorksite}
                    onChange={handleWorksiteChange}
                    className='selectWorksiteSetting'
                >
                    {Worksite.map((worksite, index) => (
                        <MenuItem key={index} value={worksite.worksiteid}>
                            {worksite.worksitename}
                        </MenuItem>
                    ))}
                </Select>

                {selectedWorksite && (
                    <>
                        <Accordion className='accordionSetting'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordionSummarySettingBlue'>
                                <Typography variant="h6">Paramètres de la plateforme</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Traitement :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">Detection Rotation :</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">Scrap :</Typography>
                                        <div className='displayflexAlignCenter'>
                                            <Typography variant="body1" className='marginRight10Setting'>
                                                Vitesse haute(km/h):
                                            </Typography>
                                            <Input
                                                value={highSpeedScrap}
                                                onChange={handleHighSpeedScrap}
                                                placeholder="haute"
                                            />
                                        </div>
                                        <div className='displayflexAlignCenter'>
                                            <Typography variant="body1" className='marginRight10Setting'>
                                                Vitesse basse(km/h):
                                            </Typography>
                                            <Input
                                                value={lowSpeedScrap}
                                                onChange={handleLowSpeedScrap}
                                                placeholder="basse"
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="subtitle2">Dumper :</Typography>
                                        <div className='displayflexAlignCenter'>
                                            <Typography variant="body1" className='marginRight10Setting'>
                                                Vitesse haute(km/h):
                                            </Typography>
                                            <Input
                                                value={highSpeedDumper}
                                                onChange={handleHighSpeedDumper}
                                                placeholder="haute"
                                            />
                                        </div>
                                        <div className='displayflexAlignCenter'>
                                            <Typography variant="body1" className='marginRight10Setting'>
                                                Vitesse basse(km/h):
                                            </Typography>
                                            <Input
                                                value={lowSpeedDumper}
                                                onChange={handleLowSpeedDumper}
                                                placeholder="basse"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className='accordionSetting'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className='accordionSummarySettingGreen'>
                                <Typography variant="h6">Paramètres de sécurité et production</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Sécurité :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className='displayflexAlignCenter'>
                                            <Typography variant="body1" className='marginRight10Setting'>
                                                Vitesse max(km/h):
                                            </Typography>
                                            <Input
                                                value={maxSpeedWorksite}
                                                onChange={handleMaxSpeedChangeWorksite}
                                                placeholder="maximale"
                                                className='marginLeftAutoSetting'
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Production :
                                        </Typography>

                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Ratio Stop(%) :</Typography>
                                        </Grid>

                                        <div className='divProductionSetting'>
                                            <FormControl className='formRatioRouleSetting'>
                                                <InputLabel htmlFor="family-select">Family</InputLabel>
                                                <Select
                                                    label="Family"
                                                    id="family-select"
                                                    value={selectedFamily || ''}
                                                    onChange={(e) => handleFamilyChange(e.target.value)}
                                                >
                                                    {Object.entries(families).map(([index, family]) => (
                                                        <MenuItem key={index} value={family.family_name}>
                                                            {family.family_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>

                                            <Box>
                                                <Input
                                                    value={ratio[selectedFamily] || ''}
                                                    placeholder=""
                                                    onChange={(e) => handleRatioChange(selectedFamily, e.target.value)}
                                                    style={{ marginLeft:'auto' }}
                                                />
                                            </Box>
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Button
                            variant="contained"
                            fullWidth
                            style={{ background: '#e91e63', color: 'white', borderRadius: '4px' }}
                            onClick={handleSubmit}
                        >
                            Sauvegarder
                        </Button>
                    </>
                )}
            </Paper>
        </Grid>
    )
};

export default SettingPage;