import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import axios from "axios";
import Button from "@mui/material/Button";

function CommentForm({ onCommentSubmit }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [comment, setComment] = useState('');
    const users = ["Lucas", "Ludo", "Antho", "Quentin", "François"];
    const [user, setUser] = useState(users[0]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(apiUrl + `/api/admin/addcomment`, { user: user, comment: comment }, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then((response) => {
                setComment('');
                setUser("");
            })
            .catch((error) => {
                console.error("Erreur lors de l'ajout :", error);
                alert("Erreur lors de l'ajout");
            });
    };

    return (
        <div>
            <h2>Ajouter un commentaire</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>Utilisateur</Form.Label>
                    <Form.Control as="select" value={user} onChange={(e) => setUser(e.target.value)}>
                        {users.map((user, index) => (
                            <option key={index} value={user}>
                                {user}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Commentaire</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Votre commentaire"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        style={{ color: 'black'}}
                    />
                </Form.Group>
                <Button style={{marginTop:'1%'}}variant="contained" type="submit">Poster</Button>
            </Form>
        </div>
    );
}

export default CommentForm;
