import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material//Typography';
import {Button} from "@mui/material";
import {Modal} from "react-bootstrap";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";

const apiUrl = process.env.REACT_APP_API_URL;
function Sim() {
    const [sims, setSims] = useState([]);
    const [simQuotas, setSimQuotas] = useState([]);
    const [ModalShow, setModalShow] = useState(false);



    useEffect(() => {

        axios.get(apiUrl + '/api/admin/simapi', {
            headers: {'x-access-token': sessionStorage.getItem('token')}
        })
            .then(res => {
                setSims(res.data)
            })
    },[])

    const getSimQuota = async (iccid) => {

        try {
            axios.post(apiUrl + `/api/admin/simquota`,
                {iccid: iccid},

                {
                    headers: {'x-access-token': sessionStorage.getItem("token")}
                })
                .then(res => {
                    setSimQuotas([{ iccid, quota: res.data }]);
                })
        } catch (error) {
            console.error(`Erreur lors de la récupération du quota de données pour ICCID ${iccid} :`, error);
        }
    };

    const openModal = (iccid) => {
        getSimQuota(iccid);
        setModalShow(true)
    };

    const closeModal = () => {
        setModalShow(false)
    };

    function ColorForQuota(id){
        let color = "";
        if(id === 0)
            color = "";
        else if(id === 1)
            color = "orange";
        else if(id === 2)
            color = "red";

        return color;
    }

    return (
        <div>
            <h1>Liste des SIMs 1nce</h1>
            <div style={{ display: 'flex', flexWrap: 'wrap' , textAlign:'center'}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>ID de la SIM</TableCell>
                                <TableCell>Volume de données (Mo)</TableCell>
                                <TableCell>Statut du quota</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sims.map((sim) => (
                                <TableRow key={sim.iccid} style={{backgroundColor : ColorForQuota(sim.quota_status.id)}}>
                                    <TableCell>{sim.label}</TableCell>
                                    <TableCell>{sim.iccid}</TableCell>
                                    <TableCell>{sim.current_quota}</TableCell>
                                    <TableCell>{sim.quota_status.description}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => openModal(sim.iccid)}
                                        >
                                            Détails du quota
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {simQuotas.length > 0 ?
                <Modal size={"lg"} show={ModalShow} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{simQuotas[0].iccid}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Typography color="textSecondary">
                            Volume total de données: {simQuotas[0].quota.total_volume} Mo
                        </Typography>
                        <Typography color="textSecondary">
                            Volume restant: {simQuotas[0].quota.volume} Mo
                        </Typography>
                        <Typography color="textSecondary">
                            Dernier volume ajouté: {simQuotas[0].quota.last_volume_added} Mo
                        </Typography>
                        <Typography color="textSecondary">
                            Date du dernier volume ajouté: {simQuotas[0].quota.last_status_change_date}
                        </Typography>
                        <Typography color="textSecondary">
                            Date d'expiration: {simQuotas[0].quota.expiry_date}
                        </Typography>
                    </Modal.Body>
                </Modal>
                : ""}
            </div>
        </div>

    );
}

export default Sim;