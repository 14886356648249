import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { Modal } from "react-bootstrap";
import 'leaflet/dist/leaflet.css';
import {HeatmapLayerFactory} from "@pauloak_/react-leaflet-heatmap-layer";

const HeatmapLayer = HeatmapLayerFactory();

function HeatMapSpeed({ show, onHide, heatMapSpeed ,serial ,name}) {
    
    let heatmapData;
    if(serial === "")
     heatmapData = heatMapSpeed.map(item => [item.latitude, item.longitude]);
    else
        heatmapData = heatMapSpeed
            .filter(item => item.serial === serial)
            .map(item => [item.latitude, item.longitude]);

    
    

    return (
        <Modal size="lg" show={show} onHide={onHide} >
            <div className="modal-content" style={{position:"absolute"}}>
            <Modal.Header closeButton>
                {serial === "" ?
                <Modal.Title>Carte des points de chaleurs des vitesses max atteintes du site</Modal.Title>
                    : <Modal.Title>Carte des points de chaleurs des vitesses max atteintes du {name} </Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                <div className="heatmap-container">
                    {heatmapData.length > 0 ?
                        <MapContainer center={[heatmapData[0][0], heatmapData[0][1]]} zoom={16}
                                      scrollWheelZoom={true}
                                      style={{ marginLeft: '1%', marginBottom: '1%', width: '100%' , height:'700px'}}>
                            <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                                       maxZoom={22}
                                       subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
                            <HeatmapLayer
                                /*fitBoundsOnLoad={true}
                                fitBoundsOnUpdate={true}*/
                                points={heatmapData}
                                longitudeExtractor={point => point[1]}
                                latitudeExtractor={point => point[0]}
                                intensityExtractor={() => 0.6} // Modification de l'intensité ici
                                blur={15}
                                radius={30}
                                max={1.0}
                            />
                        </MapContainer> : ""
                    }
                </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
            </div>
        </Modal>
    );
}

export default HeatMapSpeed;
