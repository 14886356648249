import React, {useEffect, useState} from "react";
import axios from 'axios';
import {
    Card,
    TextField,
    Container,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Grid
} from '@mui/material';
import Checkbox from "@mui/material/Checkbox";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import {MapContainer, Marker, TileLayer,Circle} from "react-leaflet";
import Leaflet from "leaflet";
import ReactDOMServer from "react-dom/server";
import CardContent from "@mui/material/CardContent";
import Markers from 'Public/images/marker.png';


const apiUrl = process.env.REACT_APP_API_URL;

var latitude_modal = '';
var longitude_modal = '';
var radius_modal = '';

function Worksite() {

    let tmptab = window.location.href.split('/');
    const serial = tmptab[tmptab.length - 1];
    const [showPositionModal, setShowPositionModal] = useState(false);

   /* const iconHTML2 = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faMapMarkerAlt} size="3x"
                                                                     style={{color: 'blue'}}></FontAwesomeIcon>)*/

    const customMarkerIconblue = new Leaflet.Icon({
        iconAnchor: [25,49],
        iconUrl: Markers,
        iconSize : [50,50]
    });

    const [openDialog, setOpenDialog] = useState(false);
    const [newWorksite, setNewWorksite] = useState({latitude_ref: '', longitude_ref: '', rayon: ''});
    const [worksites, setWorksites] = useState([]);
    const [params, setParams] = useState([]);
    let intervalID = null;
    useEffect(() => {
        intervalID = setInterval(() => {
            axios.post(apiUrl + "/api/admin/EnginGetWorksites", {serial: serial}, {headers: {'x-access-token': sessionStorage.getItem("token")}})
                .then(res => {
                    if (res.data.success) {
                        setWorksites(res.data.last_engin_worksite)
                    }
                })

            axios.post(apiUrl + "/api/admin/EnginParams", {serial: serial}, {headers: {'x-access-token': sessionStorage.getItem("token")}})
                .then(res => {
                    if (res.data.success) {
                        setParams(res.data.last_engin_params)
                    }
                })
        }, 1000);
        return () => clearInterval(intervalID);
    }, [])

    const removeWorksite = () => {
        axios.post(apiUrl + "/api/admin/RemoveEnginWorksites", {serial: serial}, {headers: {'x-access-token': sessionStorage.getItem("token")}})
            .then(function (response) {
                alert(response.data);
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const addWorksite = () => {
        setOpenDialog(true);
    };

    const saveWorksite = () => {
        axios.post(apiUrl + "/api/admin/AddEnginWorksite", {
            worksite: newWorksite,
            serial: serial
        }, {headers: {'x-access-token': sessionStorage.getItem("token")}})
            .then(function (response) {
                alert(response.data);
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error);
            });

        setOpenDialog(false);
        setNewWorksite({latitude_ref: '', longitude_ref: '', rayon: ''});
    };

    const handleModalClose = () => {
        setShowPositionModal(false)
    }
    const handleModalOpen = (latitude, longitude, radius) => {
        latitude_modal = latitude;
        longitude_modal = longitude;
        radius_modal = radius
        setShowPositionModal(true)
    }


    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Liste des Worksites du {serial}
            </Typography>
            {worksites.length > 0 ? (
                    <>
                        {worksites.map((worksite, index) => (
                            <Box key={index} marginBottom={2}>
                                    <Button title='Localise worksite' style={{marginLeft: '1%', width: '5%'}}
                                            onClick={() => handleModalOpen(worksite.coordinates.latitude, worksite.coordinates.longitude, worksite.radius)} disabled={worksite.coordinates.longitude === 0}><FontAwesomeIcon
                                        icon={faMapMarkerAlt}/></Button>
                                <TextField style={{marginLeft:'2%'}}
                                    label="Latitude"
                                    type="number"
                                    value={worksite.coordinates.latitude}
                                    // Add an event handler to update latitude
                                />
                                <TextField
                                    label="Longitude"
                                    type="number"
                                    value={worksite.coordinates.longitude}
                                />
                                <TextField
                                    label="Radius"
                                    type="number"
                                    value={worksite.radius}
                                />
                                <label style={{marginLeft: '1%'}}>Activé: </label>
                                <Checkbox
                                    checked={worksite.enabled}
                                />
                            </Box>
                        ))}
                        <Button style={{marginLeft:'8%',width: '20%'}} onClick={addWorksite}>
                            Ajouter un worksite
                        </Button>
                        <Button  style={{marginLeft: '1%', width: '20%'}}
                                onClick={() => removeWorksite()}>
                            Supprimer un worksite
                        </Button>

                        <Modal size={"lg"} show={showPositionModal} onHide={handleModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Latitude : {latitude_modal} Longitude : {longitude_modal} Radius : {radius_modal}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <MapContainer
                                        center={[latitude_modal, longitude_modal]}
                                        zoom={10} scrollWheelZoom={true} style={{width: '100%' , height:'700px'}}>
                                        <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" maxZoom={22}
                                                   subdomains={['mt0', 'mt1', 'mt2', 'mt3']}/>
                                        <Marker icon={customMarkerIconblue}
                                                position={[latitude_modal, longitude_modal]}/>

                                        <Circle
                                            center={[latitude_modal, longitude_modal]}
                                            radius={radius_modal}
                                            fillColor="blue" // Remplacez par la couleur de remplissage souhaitée
                                            fillOpacity={0.2} // Opacité de remplissage
                                            color="blue" // Couleur de la bordure
                                        />

                                    </MapContainer>
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                            <DialogTitle>Ajouter un nouveau worksite</DialogTitle>
                            <DialogContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <TextField
                                            label="Latitude"
                                            type="number"
                                            value={newWorksite.latitude_ref}
                                            onChange={(e) => setNewWorksite({...newWorksite, latitude_ref: e.target.value})}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label="Longitude"
                                            type="number"
                                            value={newWorksite.longitude_ref}
                                            onChange={(e) => setNewWorksite({
                                                ...newWorksite,
                                                longitude_ref: e.target.value
                                            })}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label="Radius"
                                            type="number"
                                            value={newWorksite.rayon}
                                            onChange={(e) => setNewWorksite({...newWorksite, rayon: e.target.value})}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                                <Button onClick={saveWorksite} color="primary">
                                    Ajouter
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Card style={{marginLeft: '8%',width:'20%' , textAlign:'center' , marginTop:'2%'}}>
                            <CardContent>
                                <Typography>Latitude de référence: {params.lat_ref}</Typography>
                                <Typography>Longitude de référence: {params.long_ref}</Typography>
                                <Typography>Rayon Site: {params.rayon_site}</Typography>
                                <Button
                                    title="Localise worksite"
                                    style={{ width: '30%' }}
                                    onClick={() => handleModalOpen(params.lat_ref, params.long_ref, params.rayon_site)}
                                >
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </Button>
                            </CardContent>
                        </Card>
                    </>
                ) :
                (
                    <Typography>Aucun worksite n'a été trouvé.</Typography>
                )
            }
        </Container>
    )
        ;
}

export default Worksite;