import React, {useEffect, useState} from 'react';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import axios from "axios";

function CommentPage() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [comments, setComments] = useState([]);
    let intervalID = null;

    useEffect(() => {
        intervalID = setInterval(() => {
            axios.get(apiUrl + `/api/admin/comment`,
                {
                    headers: {'x-access-token': sessionStorage.getItem("token")}
                })
                .then(res => {
                    setComments(res.data);

                })
        }, 1000);
        return () => clearInterval(intervalID);

    }, []);

    const handleCommentSubmit = (comment) => {
        setComments([...comments, comment]);
    };

    return (
        <div>
            <CommentList comments={comments} />
            <CommentForm onCommentSubmit={handleCommentSubmit} />
        </div>
    );
}

export default CommentPage;
