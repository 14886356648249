import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import sidebarImage from "../../public/images/sidebar-4.jpg";
import Footer from "../components/Footer/Footer";
import { dashboardRoutes } from "./routes";
import Navbar from "../components/NavBars/Navbar";
import ConstructionPage from "../components/ConstructionPage";
import axios from "axios";

export function ConnectedLayout(props) {
    const [image, setImage] = useState(sidebarImage);
    const [color, setColor] = useState("black");
    const [hasImage, setHasImage] = useState(true);
    const [constructionPage, setConstructionUserPage] = useState([]);
    const location = useLocation();
    const mainPanel = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    let DashboardRoutes;

    useEffect(() => {
        axios.get(apiUrl + `/api/user/constructionuserpages`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setConstructionUserPage(res.data);
            })
    }, [apiUrl]);

    const updateDashboardRoutes = (dashboardRoutes, construction) => {
        return dashboardRoutes.map(route => {
            const constructionEntry = construction.find(entry => entry.name_page.trim() === route.name.trim());
            if (constructionEntry && constructionEntry.construction) {
                return {
                    ...route,
                    construction: true
                };
            } else if (route.subMenuItems && route.subMenuItems.length > 0) {
                // Vérifie également les sous-menus
                const updatedSubMenuItems = route.subMenuItems.map(subMenu => {
                    const subMenuConstructionEntry = construction.find(entry => entry.name_page.trim() === subMenu.name.trim());
                    if (subMenuConstructionEntry && subMenuConstructionEntry.construction) {
                        return {
                            ...subMenu,
                            construction: subMenuConstructionEntry.construction,
                        };
                    } else {
                        return subMenu;
                    }
                });
                console.log(updatedSubMenuItems);
                return {
                    ...route,
                    subMenuItems: updatedSubMenuItems
                };
            } else {
                return route;
            }
        });
    };

    if (constructionPage.length > 0 && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
        DashboardRoutes = updateDashboardRoutes(dashboardRoutes, constructionPage);
    } else {
        DashboardRoutes = dashboardRoutes;
    }

    const getRoutes = (routes) => {
        const renderRoute = (prop, key) => {
            if (prop.construction) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        element={props.isLogin ? <ConstructionPage /> : <Navigate replace to="/" />}
                        key={key}
                    />
                );
            } else {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        element={props.isLogin ? <prop.component /> : <Navigate replace to="/" />}
                        key={key}
                    />
                );
            }
        };

        return routes.flatMap((prop, key) => {
            let routeComponents = [];
            if (prop.layout !== "") {
                routeComponents.push(renderRoute(prop, key));
                if (prop.subMenuItems && prop.subMenuItems.length > 0) {
                    routeComponents = [
                        ...routeComponents,
                        ...prop.subMenuItems.map((subProp, subKey) => renderRoute(subProp, `${key}-${subKey}`))
                    ];
                }
            }
            return routeComponents;
        });
    };

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainPanel.current.scrollTop = 0;
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            const element = document.getElementById("bodyClick");
            element.parentNode.removeChild(element);
        }
    }, [location]);

    return (
        <div className="wrapper">
            <Sidebar color={color} image={hasImage ? image : ""} routes={DashboardRoutes} />
            <div className="main-panel" ref={mainPanel}>
                <Navbar />
                <div className="content">
                    <Routes>{getRoutes(DashboardRoutes)}</Routes>
                </div>
                <Footer />
            </div>
        </div>
    );
}
