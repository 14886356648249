// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divDevice{
    display: flex;
}

.cardDevice{
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    align-items: center;
}

.cardDevice2{
    margin-left: 5%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
}

.centerDevice{
    text-align: center;
}

.formDevice{
    width:100%;
}

.width100Device{
    width: 100%;
}
.marginTop1Device{
    margin-top: 1%;
}

@media only screen and (max-width: 768px) {
    .divDevice{
        flex-direction: column;
    }

    .cardDevice{
        margin-bottom: 2%;
    }

    .cardDevice2{
        margin-left: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/user/Device/Device.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;AACf;AACA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".divDevice{\n    display: flex;\n}\n\n.cardDevice{\n    border: 1px solid #ccc;\n    padding: 10px;\n    border-radius: 4px;\n    align-items: center;\n}\n\n.cardDevice2{\n    margin-left: 5%;\n    border: 1px solid #ccc;\n    padding: 10px;\n    border-radius: 4px;\n}\n\n.centerDevice{\n    text-align: center;\n}\n\n.formDevice{\n    width:100%;\n}\n\n.width100Device{\n    width: 100%;\n}\n.marginTop1Device{\n    margin-top: 1%;\n}\n\n@media only screen and (max-width: 768px) {\n    .divDevice{\n        flex-direction: column;\n    }\n\n    .cardDevice{\n        margin-bottom: 2%;\n    }\n\n    .cardDevice2{\n        margin-left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
