import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import axios from "axios";
import Loader from 'Public/images/loader.gif';
import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Typography, Modal, Box, Button, TextField, Grid, CardContent, Card } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx-js-style';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const apiUrl = process.env.REACT_APP_API_URL;
function DashboardProductionCumulative() {

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedStopDate, setSelectedStopDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorksite, setSelectedWorksite] = useState("");
  const [Worksite, setWorksite] = useState([]);
  const [Picto, setPicto] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const [numberOfDaysWeekYear, setNumberOfDaysWeekYear] = useState(1);
  const [dateOption, setDateOption] = useState('jours');
  const [updateData, setUpdateData] = useState(true);
  const [cumulProd, setCumulProd] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRotations, setSelectedRotations] = useState([]);
  const [stockMaterial, setStockMaterial] = useState([]);
  const [dataStockMaterial, setDataStockMaterial] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedStockMaterial, setSelectedStockMaterial] = useState("all");
  const [dataFilter, setDataFilter] = useState(null);

  const handleOpen = (rotations) => {
    setSelectedRotations(rotations);
    setOpen(true);
  };


  const handleSelectDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  const handleSelectedStockMaterial = (event) => {
    
    
    if(event.target.value !== 'all'){
      const result = {
        total: { totalRotation: 0, totalTaskDuration: 0 },
        filteredData: []
      };
      cumulProd.forEach((item) => {
        const rotations = JSON.parse(item.rotation);
        rotations.forEach((rotation) => {
          // Extraire stock et material de chaque rotation
          const { stock, material, rotation_count, task_duration } = rotation;
    
          // Comparer avec le stock et material sélectionnés
          if (`${stock} / ${material}` === event.target.value) {
            result.filteredData.push({
              serial: item.serial,
              rotation_count,
              task_duration,
              stock,
              material
            });

            result.total.totalRotation += rotation_count;
            result.total.totalTaskDuration += task_duration;
          }
        });
      });
      setDataStockMaterial(result);
    }
    setSelectedStockMaterial(event.target.value);
  }
  const handleSelectedFilter = (event) => {
    if (event.target.value === "rotation") {
      let totalRotationsSum = 0;
      let stocksCumul = {};
      const stockMaterialSet = new Set();

      cumulProd.forEach((item) => {
        // Calculer le total global des rotations
        totalRotationsSum += item.rotation_cnt;
        // Accumuler les stocks et matériaux
        const rotations = JSON.parse(item.rotation);
        rotations.forEach((rotation) => {
          const stock = rotation.stock;
          const material = rotation.material;
          const taskDuration = rotation.task_duration;
          const rotation_count = rotation.rotation_count;
          const key = `${stock}_${material}`;

          stockMaterialSet.add(`${rotation.stock} / ${rotation.material}`);
          // Ajouter au cumul des stocks et matériaux
          if (!stocksCumul[key]) {
            stocksCumul[key] = { stock, material, totalDuration: taskDuration, rotation_count };
          } else {
            // Si la clé existe déjà, cumuler les valeurs
            stocksCumul[key].totalDuration += taskDuration;
            stocksCumul[key].rotation_count += rotation_count;
          }
        });
      });

      // Trier et sélectionner le top 10 des serials par rotations
      const sortedByRotation = [...cumulProd].sort((a, b) => b.rotation_cnt - a.rotation_cnt).slice(0, 10);

      const stockCumul = Object.entries(stocksCumul).map(([key, details]) => ({
        stock: details.stock,
        material: details.material,
        totalDuration: details.totalDuration,
        rotation_count: details.rotation_count,
      }));
      const sortedStockCumul = stockCumul.sort((a, b) => b.rotation_count - a.rotation_count);

      setStockMaterial(Array.from(stockMaterialSet));
      // Sérialisation des données dans un tableau
      const serialized = {
        totalRotations: totalRotationsSum,
        stockCumul: sortedStockCumul,
        top10Rotations: sortedByRotation.map(item => ({
          serial: item.serial,
          rotationCount: item.rotation_cnt,
        })),
      };

      setDataFilter(serialized);

    }
    else if(event.target.value === 'fuel'){
      let totalFuelSum = 0;
      cumulProd.forEach((item) => {
        // Calculer le total global des rotations
        totalFuelSum += item.fuel;
        // Accumuler les stocks et matériaux

      // Trier et sélectionner le top 10 des serials par rotations
      })
      const sortedByFuel = [...cumulProd].sort((a, b) => b.fuel - a.fuel).slice(0, 10);

      // Sérialisation des données dans un tableau
      const serialized = {
        totalFuel: totalFuelSum,
        top10Fuel: sortedByFuel.map(item => ({
          serial: item.serial,
          fuel: item.fuel,
        })),
      };


      setDataFilter(serialized);
    }
    else if(event.target.value === 'timepost'){
      let totalTimePostSum = 0;
      cumulProd.forEach((item) => {
        
        totalTimePostSum += item.time_post;
     
      })
      const sortedByTimePost = [...cumulProd].sort((a, b) => b.time_post - a.time_post).slice(0, 10);

      // Sérialisation des données dans un tableau
      const serialized = {
        totalTimePost: totalTimePostSum,
        top10TimePost: sortedByTimePost.map(item => ({
          serial: item.serial,
          time_post: item.time_post,
        })),
      };


      setDataFilter(serialized);
    }
    setSelectedFilter(event.target.value);

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleWorksiteChange = (event) => {
    setSelectedWorksite(event.target.value);
  };

  const handleUpdate = () => {
    setUpdateData(true);
  }

  function formatDate(date) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {

    setIsLoading(true);
    var start, stop;
    start = selectedStartDate;

    const dateStart = new Date(selectedStartDate);
    let dateStop = new Date(selectedStartDate);
    if (dateOption === "jours") {
      dateStop.setDate(dateStop.getDate() + numberOfDaysWeekYear - 1)
    }
    else if (dateOption === "semaines") {
      dateStop.setDate(dateStop.getDate() + (numberOfDaysWeekYear * 7))
    }
    else if (dateOption === "mois") {
      dateStop.setDate(dateStop.getDate() + (numberOfDaysWeekYear * 30))
    }
    else if (dateOption === "années") {
      dateStop.setDate(dateStop.getDate() + (numberOfDaysWeekYear * 365))

    }

    dateStart.setHours(0, 0, 1);
    start = new Date(dateStart);
    dateStop.setHours(23, 59, 59);
    stop = new Date(dateStop);
    setSelectedStopDate(dateStop);

    let formattedStart = formatDate(start);
    let formattedStop = formatDate(stop);

    if (selectedWorksite !== "") {
      axios.post(apiUrl + `/api/user/cumulprod`,
        { startdate: formattedStart, stopdate: formattedStop, selectedWorksite: selectedWorksite },

        {
          headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
        .then(res => {
          setExcelData(res.data);
          const cumulateData = (data) => {
            const cumulativeData = {};
            data.forEach(item => {
              const serial = item.serial.trim();
              const rotations = JSON.parse(item.rotation);
              const time_post = Number(item.time_post);

              if (!cumulativeData[serial]) {
                cumulativeData[serial] = {
                  ...item,
                  time_post: time_post,
                  fuel: item.fuel,
                  distance_traveled: item.distance_traveled,
                  rotation: rotations,
                  rotation_cnt: item.rotation_cnt
                };
              } else {
                cumulativeData[serial].rotation_cnt += item.rotation_cnt;
                cumulativeData[serial].time_post += time_post;
                cumulativeData[serial].distance_traveled += item.distance_traveled;
                cumulativeData[serial].fuel += item.fuel;
                rotations.forEach(rotation => {
                  const existingRotation = cumulativeData[serial].rotation.find(r =>
                    r.stock === rotation.stock && r.material === rotation.material
                  );

                  if (existingRotation) {
                    existingRotation.rotation_count += rotation.rotation_count;
                    existingRotation.task_duration += rotation.task_duration;
                  } else {
                    cumulativeData[serial].rotation.push(rotation);
                  }
                });
              }
            });

            // Transforme l'objet en tableau
            return Object.values(cumulativeData).map(item => ({
              ...item,
              rotation: JSON.stringify(item.rotation)
            }));
          };

          setCumulProd(cumulateData(res.data))
          setDataFilter(null);
          setSelectedFilter("all");
          setSelectedDevice("all");
          setSelectedStockMaterial("all");
          setIsLoading(false);;

        })
    }
    setUpdateData(false);



  }, [updateData, selectedWorksite])

  const secondesEnDuree = (secondes) => {
    const heures = Math.floor(secondes / 3600);
    const minutes = Math.floor((secondes % 3600) / 60);
    const secondesRestantes = secondes % 60;
    return `${heures.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}m${secondesRestantes.toString().padStart(2, "0")}s`;
  };

  useEffect(() => {
    axios.get(apiUrl + `/api/user/worksite`, {
      headers: { 'x-access-token': sessionStorage.getItem("token") }
    })
      .then(res => {

        const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
        setWorksite(worksite);
      })

    axios.get(apiUrl + `/api/user/picto`, {
      headers: { 'x-access-token': sessionStorage.getItem("token") }
    })
      .then(res => {
        setPicto(res.data);
      })
    if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
      axios.get(apiUrl + `/api/admin/device`, {
        headers: { 'x-access-token': sessionStorage.getItem("token") }
      })
        .then(res => {
          setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
        })
    } else {
      axios.get(apiUrl + `/api/user/device`, {
        headers: { 'x-access-token': sessionStorage.getItem("token") }
      })
        .then(res => {
          setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
        })
    }
  }, []);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleChangeDateOption = (event) => {
    setDateOption(event.target.value);
  };

  useEffect(() => {
    if (Worksite.length > 0) {
      setSelectedWorksite(Worksite[0].worksiteid)
    }
  }, [Worksite]);

  const handleNumberOfDaysWeekYearChange = (event) => {
    const days = parseInt(event.target.value, 10);
    setNumberOfDaysWeekYear(days);
  };

  function getNamebySerial(serial) {
    if (deviceName.length > 0) {
      const device = deviceName.find(device => device.username.trim() === serial.trim());
      let selectedDevice = "noname";
      let NameDevice = [];
      if (device)
        NameDevice = JSON.parse(device.name_device);
      let StartDate = selectedStartDate.setHours(0, 0, 1);
      if (NameDevice.length > 1) {

        for (const item of NameDevice) {
          const itemDate = new Date(item.timestamp);
          const startDate = new Date(StartDate);

          if (itemDate < startDate) {
            selectedDevice = item.nameDevice;
          }
        }
      } else {
        const itemDate = new Date(NameDevice.timestamp);
        const startDate = new Date(StartDate);
        if (itemDate < startDate)
          selectedDevice = NameDevice.nameDevice;
      }

      const str = selectedDevice;
      return str;
    }
  }

  const generateExcelData = (data, isCumul, start, stop) => {
    const excelData = [];
  
    // Ajouter en-têtes personnalisés avec des styles
    if (isCumul) {
      excelData.push([
        { v: "Start", s: { fill: { fgColor: { rgb: "FFFF00" } } } },
        { v: "Stop", s: { fill: { fgColor: { rgb: "FFFF00" } } } }
      ]);
      excelData.push([start, stop]);
      excelData.push([]);
  
      // En-têtes de colonne avec du style
      excelData.push([
        { v: "Appareil", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Carburant (L)", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Temps de Poste", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Distance Parcourue (km)", s: { fill: { fgColor: { rgb: "FFCC00" } } } },
        { v: "Total Rotation", s: { fill: { fgColor: { rgb: "FFCC00" } } } }
      ]);
    } else {
      excelData.push([
        { v: "Date", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Appareil", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Carburant (L)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Temps de Poste", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Distance Parcourue (km)", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
        { v: "Total Rotation", s: { fill: { fgColor: { rgb: "D3D3D3" } } } }
      ]);
    }
  
    // Ajouter les données de chaque entrée avec style
    data.forEach(item => {
      const row = isCumul
        ? [
            { v: getNamebySerial(item.serial), s: { fill: { fgColor: { rgb: "FFFFFF" } } } }, // Appareil
            { v: item.fuel, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                  // Carburant
            { v: secondesEnDuree(item.time_post), s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Temps de poste
            { v: `${(item.distance_traveled / 1000)}km`, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Distance
            { v: item.rotation_cnt, s: { fill: { fgColor: { rgb: "FFFFFF" } } } }           // Rotation
          ]
        : [
            { v: item.ts, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                    // Date
            { v: getNamebySerial(item.serial), s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Appareil
            { v: item.fuel, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },                  // Carburant
            { v: secondesEnDuree(item.time_post), s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Temps
            { v: `${(item.distance_traveled / 1000)}km`, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Distance
            { v: item.rotation_cnt, s: { fill: { fgColor: { rgb: "FFFFFF" } } } }           // Rotation
          ];
      excelData.push(row);
    });
    excelData.push([]);
    // Ajouter des rotations si applicable

    let isbool = false;

    data.forEach(item => {
      if (!isCumul && item.rotation_cnt > 0) {
        if(!isbool){
          excelData.push([{ v: "Date", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
            { v: "Stock", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
            { v: "Materiau", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
            { v: "Nombre de rotation", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
            { v: "Durée", s: { fill: { fgColor: { rgb: "D3D3D3" } } } },
          ]);
          isbool = true;
        }

        const rotations = JSON.parse(item.rotation);
        rotations.forEach(rotation => {
          excelData.push([
            { v: item.ts, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },            // Date
            { v: rotation.stock, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },     // Stock
            { v: rotation.material, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Matériau
            { v: rotation.rotation_count, s: { fill: { fgColor: { rgb: "FFFFFF" } } } },  // Rotation
            { v: secondesEnDuree(rotation.task_duration), s: { fill: { fgColor: { rgb: "FFFFFF" } } } }  // Durée
          ]);
        });
      }
    });
  
    return excelData;
  };

  const exportToExcel = (data) => {
    // Générer les données pour Excel
    const groupedData = groupDataBySerialNumber(data);

    const wb = XLSX.utils.book_new();

    const excelDataCumul = generateExcelData(cumulProd, true, selectedStartDate.toLocaleDateString("fr-FR"), selectedStopDate.toLocaleDateString("fr-FR"));

    const ws = XLSX.utils.aoa_to_sheet(excelDataCumul);
    const columnWidth = 30;
    const columnCount = cumulProd.length;
    ws['!cols'] = Array(columnCount).fill({ wch: columnWidth });

    XLSX.utils.book_append_sheet(wb, ws, `Cumul`);

    for (const serialNumber in groupedData) {
      if (groupedData.hasOwnProperty(serialNumber)) {
        const excelData = generateExcelData(groupedData[serialNumber], false);
        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const columnWidth = 30;
        const columnCount = Object.keys(excelData[0]).length;
        ws['!cols'] = Array(columnCount).fill({ wch: columnWidth });

        // Ajouter la feuille de calcul au classeur
        XLSX.utils.book_append_sheet(wb, ws, `${getNamebySerial(serialNumber)}`);
      }
    }


    // Enregistrer le fichier
    XLSX.writeFile(wb, 'export.xlsx');
  };

  const groupDataBySerialNumber = (data) => {
    return data.reduce((acc, item) => {
      const serialNumber = item.serial.trim();
      if (!acc[serialNumber]) {
        acc[serialNumber] = [];
      }
      // On ajoute l'élément au serial correspondant
      acc[serialNumber].push(item);

       // On trie le groupe par date du plus récent au plus ancien
      acc[serialNumber].sort((a, b) => new Date(b.ts) - new Date(a.ts));
      return acc;
    }, {});
  };

  function getPictoByTypeDevice(type) {

    const picto = Picto.find(device => device.id_device_type === type);

    if (picto)
      return picto.picto;
  }

  const CustomTooltipRotation = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <p>{`Appareil : ${getNamebySerial(data.serial)}`}</p>
          <p>{`Rotations : ${data.rotationCount}`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipFuel = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <p>{`Appareil : ${getNamebySerial(data.serial)}`}</p>
          <p>{`Fuel : ${data.fuel} L`} </p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipTimePost = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <p>{`Appareil : ${getNamebySerial(data.serial)}`}</p>
          <p>{`Temps de poste : ${secondesEnDuree(data.time_post)}`} </p>
        </div>
      );
    }
    return null;
  };



  return (
    <div>
      <div>
        <label className='labelStart'>Start : </label>
        <span className='spanStart'>
          <DatePicker showIcon
            disabled={isLoading}
            locale={fr}
            dateFormat="dd/MM/yyyy"
            selected={selectedStartDate}
            onChange={(date) => handleStartDateChange(date)}
          />

        </span>

        <TextField
          style={{ marginLeft: "1%", width: '80px' }}
          type="number"
          value={numberOfDaysWeekYear}
          onChange={handleNumberOfDaysWeekYearChange}
          inputProps={{ min: 1 }}
        />

        <Select
          style={{ marginLeft: '1%' }}
          labelId="date-select-label"
          id="date-select"
          value={dateOption}
          onChange={handleChangeDateOption}
          label="Choisir une option"
        >
          <MenuItem value="jours">Jours</MenuItem>
          <MenuItem value="semaines">Semaines</MenuItem>
          <MenuItem value="mois">Mois</MenuItem>
          <MenuItem value="années">Année</MenuItem>
        </Select>


        <Button variant="outlined" size="large" sx={{ height: 55 }} style={{ marginLeft: "1%", width: "5%" }} onClick={handleUpdate}>Update</Button>
        <Button title='export excel' className='btnExportExcel' onClick={() => exportToExcel(excelData)}><FontAwesomeIcon icon={faFileCsv} size="lg" /></Button>
      </div>

      <div>
        {isLoading ? (
          <div className='divLoading'>
            <img src={Loader} alt="Chargement..." className='imgLoading' />
          </div>
        ) : (
          <>
            <div className='textCenter'>
              <h2>CUMULS PRODUCTION</h2>
              <h4>Periode du : {selectedStartDate.toLocaleDateString("fr-FR")} au {selectedStopDate.toLocaleDateString("fr-FR")} </h4>
            </div>
            {cumulProd.length > 0 ?
              (<>
                Device :
                <Select value={selectedDevice} onChange={handleSelectDeviceChange}>
                  <MenuItem selected key="all" value="all">All</MenuItem>
                  {cumulProd.map((item, index) => (
                    <MenuItem key={index} value={item.serial}>{getNamebySerial(item.serial)}</MenuItem>
                  ))}
                </Select> </>) : ""}
            {cumulProd.length > 0 && selectedDevice === 'all' ?
              (<>
                Filtre :
                <Select value={selectedFilter} onChange={handleSelectedFilter}>
                  <MenuItem selected key="all" value="all">All</MenuItem>
                  <MenuItem selected key="rotation" value="rotation">Rotation</MenuItem>
                  <MenuItem selected key="timepost" value="timepost">Temps de poste</MenuItem>
                  <MenuItem selected key="fuel" value="fuel">Consommation</MenuItem>
                </Select> </>)
              : ""}
              {
              cumulProd.length > 0 && selectedDevice === 'all' && selectedFilter === 'rotation' ? 
              <>
                Stock / Materiau :
                <Select value={selectedStockMaterial} onChange={handleSelectedStockMaterial}>
                  <MenuItem selected key="all" value="all">All</MenuItem>
                  {stockMaterial.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                    </MenuItem>
                    ))}
                </Select>
              </>
             : ""}
            Site : 
          <Select className='selectWorksite' value={selectedWorksite} onChange={handleWorksiteChange}>
          {Worksite.map((worksite, index) => (
            <MenuItem key={index} value={worksite.worksiteid}>{worksite.worksitename}</MenuItem>
          ))}
        </Select>
            {
              selectedDevice === 'all' ? (
                selectedFilter === 'all' ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align={"center"}>Type Engin</TableCell>
                          <TableCell align={"center"}>Appareil</TableCell>
                          <TableCell align={"center"}>Temps de production </TableCell>
                          <TableCell align={"center"}>Total Rotation</TableCell>
                          <TableCell align={"center"}>Consommation</TableCell>
                          <TableCell align={"center"}>Temps de poste</TableCell>
                          <TableCell align={"center"}>Distance</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cumulProd.map((item, index) => {
                          const rotations = JSON.parse(item.rotation);
                          const totalTaskDuration = rotations.reduce((sum, rotation) => sum + rotation.task_duration, 0);

                          return (
                            <TableRow key={index} className="responsiveTableRow">
                              <TableCell className="responsiveTableCell" align="center">
                                <img
                                  src={require("Public/images/picto/" + getPictoByTypeDevice(item.device_type))}
                                  alt=""
                                  className="imgPictoDashboardProdCumul"
                                />
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {getNamebySerial(item.serial)}
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                <Button onClick={() => handleOpen(rotations)}>
                                  {secondesEnDuree(totalTaskDuration)}
                                </Button>
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {item.rotation_cnt}
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {item.fuel} L
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {secondesEnDuree(item.time_post)}
                              </TableCell>
                              <TableCell className="responsiveTableCell" align="center">
                                {item.distance_traveled / 1000} km
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : selectedFilter === 'rotation' && selectedStockMaterial === 'all' ? (
                  <>
                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }}>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Rotation
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {dataFilter.totalRotations}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TableContainer component={Paper} >
                              <Typography variant="h6" gutterBottom align="center">
                                Cumul des Stocks et Matériaux
                              </Typography>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">Stock</TableCell>
                                    <TableCell align="center">Matériel</TableCell>
                                    <TableCell align="center">Durée Totale</TableCell>
                                    <TableCell align="center">Nombre de Rotations</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {dataFilter.stockCumul.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell align="center">{item.stock}</TableCell>
                                      <TableCell align="center">{item.material}</TableCell>
                                      <TableCell align="center">{secondesEnDuree(item.totalDuration)}</TableCell>
                                      <TableCell align="center">{item.rotation_count}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={10} md={8}>
                        <Card style={{ backgroundColor: 'White', color: 'Black', padding: '20px', borderRadius: '8px' }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom align="center">
                              Top 10 des Rotations par Appareil
                            </Typography>
                            <ResponsiveContainer width="100%" height={400}>
                              <BarChart data={dataFilter.top10Rotations} layout="vertical">
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis
                                  dataKey="serial"
                                  type="category"
                                  tickFormatter={getNamebySerial}// Afficher le nom dans YAxis
                                  width={150}
                                />
                                <Tooltip content={<CustomTooltipRotation />} /> {/* Personnalisation du Tooltip */}
                                <Bar dataKey="rotationCount" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </>
                ) : selectedFilter === 'rotation' && selectedStockMaterial !== 'all' ? ( 
                 <>
                    <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }}>
                    <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Rotation
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {dataStockMaterial.total.totalRotation}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Durée
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {secondesEnDuree(dataStockMaterial.total.totalTaskDuration)}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <TableContainer component={Paper} >
                              <Typography variant="h6" gutterBottom align="center">
                                {selectedStockMaterial}
                              </Typography>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">Appareil</TableCell>
                                    <TableCell align="center">Durée Totale</TableCell>
                                    <TableCell align="center">Nombre de Rotations</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {dataStockMaterial.filteredData.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell align="center">{getNamebySerial(item.serial)}</TableCell>
                                      <TableCell align="center">{secondesEnDuree(item.task_duration)}</TableCell>
                                      <TableCell align="center">{item.rotation_count}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                 </> 
                ): selectedFilter === 'timepost' ? (
                  <>
                  <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }}>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Temps de Poste
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {secondesEnDuree(dataFilter.totalTimePost)} 
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={10} md={8}>
                        <Card style={{ backgroundColor: 'White', color: 'Black', padding: '20px', borderRadius: '8px' }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom align="center">
                              Top 10 des temps de poste par Appareil
                            </Typography>
                            <ResponsiveContainer width="100%" height={400}>
                              <BarChart data={dataFilter.top10TimePost} layout="vertical">
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis
                                  dataKey="serial"
                                  type="category"
                                  tickFormatter={getNamebySerial}// Afficher le nom dans YAxis
                                  width={150}
                                />
                                <Tooltip content={<CustomTooltipTimePost />} /> {/* Personnalisation du Tooltip */}
                                <Bar dataKey="time_post" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                      </Grid>
                  </>
                ) : selectedFilter === 'fuel' ? (
                  <>
                  <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }}>
                      <Grid item>
                        <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom align="center">
                              Total Consommation
                            </Typography>
                            <Typography variant="body1" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
                              <br></br>
                              {dataFilter.totalFuel} L
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={10} md={8}>
                        <Card style={{ backgroundColor: 'White', color: 'Black', padding: '20px', borderRadius: '8px' }}>
                          <CardContent>
                            <Typography variant="h6" gutterBottom align="center">
                              Top 10 des Consommations par Appareil
                            </Typography>
                            <ResponsiveContainer width="100%" height={400}>
                              <BarChart data={dataFilter.top10Fuel} layout="vertical">
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis
                                  dataKey="serial"
                                  type="category"
                                  tickFormatter={getNamebySerial}// Afficher le nom dans YAxis
                                  width={150}
                                />
                                <Tooltip content={<CustomTooltipFuel />} /> {/* Personnalisation du Tooltip */}
                                <Bar dataKey="fuel" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                      </Grid>
                  </>
                ) : (<></>)) : (<></>)}

            {selectedDevice !== 'all' ? (cumulProd.filter(item => item.serial === selectedDevice).map((item, index) => (
              <Grid container spacing={2} justifyContent="center" style={{ marginTop: '1%' }} key={index}>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Type de l'engin
                      </Typography>
                      <img
                        src={require("Public/images/picto/" + getPictoByTypeDevice(item.device_type))}
                        alt=""
                        className='imgPictoDashboardProdCumul'
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Nom de l'appareil
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {getNamebySerial(item.serial)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Nombre de rotation
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        {item.rotation_cnt}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Temps de poste
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        <br></br>
                        {secondesEnDuree(item.time_post)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Consommations
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        <br></br>
                        {item.fuel} L
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'Black', width: '250px', height: '200px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom align="center">
                        Distances
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                        <br></br>
                        {item.distance_traveled / 1000} km
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom align="center">
                        Comparaison Temps de Poste et Temps de Travail
                      </Typography>
                      <BarChart width={500} height={300} data={[{ name: 'Temps de Poste', duree: item.time_post },
                      { name: 'Temps de Travail', duree: JSON.parse(item.rotation).reduce((sum, rotation) => sum + rotation.task_duration, 0) }]}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={(value) => secondesEnDuree(value)} tick={{ fontSize: 11 }} />
                        <Tooltip formatter={(value) => secondesEnDuree(value)} />
                        <Legend />
                        <Bar dataKey="duree" fill="#8884d8" />
                      </BarChart>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card style={{ backgroundColor: 'White', color: 'black', width: '100%', maxWidth: '600px', padding: '10px', borderRadius: '8px' }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom align="center">
                        Rotations
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align='center'>Stock</TableCell>
                              <TableCell align='center'>Material</TableCell>
                              <TableCell align='center'>Nombre de Rotation</TableCell>
                              <TableCell align='center'>Durée</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {JSON.parse(item.rotation).map((rotation, index) => (
                              <TableRow key={index}>
                                <TableCell align='center'>{rotation.stock}</TableCell>
                                <TableCell align='center'>{rotation.material}</TableCell>
                                <TableCell align='center'>{rotation.rotation_count}</TableCell>
                                <TableCell align='center'>{secondesEnDuree(rotation.task_duration)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ))) : ""}
            <Modal open={open} onClose={handleClose}>
              <Box
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80%',
                  maxWidth: 800,
                  backgroundColor: 'white',
                  padding: '16px',
                  boxShadow: 24,
                  overflow: 'auto'
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Rotation Details
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Stock</TableCell>
                      <TableCell>Materiau</TableCell>
                      <TableCell>Rotation</TableCell>
                      <TableCell>Durée</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedRotations.map((rotation, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{rotation.stock}</TableCell>
                        <TableCell>{rotation.material}</TableCell>
                        <TableCell>{rotation.rotation_count}</TableCell>
                        <TableCell>{secondesEnDuree(rotation.task_duration)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Modal>
          </>

        )}
      </div>
    </div>
  )
}

export default DashboardProductionCumulative