import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFolder, faUpload } from "@fortawesome/free-solid-svg-icons";
import TextField from "@mui/material/TextField";
import Loader from 'Public/images/loader.gif';
import useWebSocket from "react-use-websocket"

const apiUrl = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;

function SdExplorer() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileManuel, setSelectedFileManuel] = useState(null);
    const [file, setFile] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const inputFileRef = useRef(null);
    const inputFileRefManuel = useRef(null);
    const [listSD, setListSD] = useState([]);
    const [directory, setDirectory] = useState('/');
    const [directoryManuel, setDirectoryManuel] = useState('/');
    const [isLoading, setIsLoading] = useState(false);
    const [isAddOrDeleteFile, setIsAddOrDeleteFile] = useState(false);
    const [connected, setConnected] = useState([]);
    const [fileNameDownload, setFileNameDownload] = useState("");
    const [dlFile, setDLFile] = useState(false);
    const [dataDlFile, setDataDLFile] = useState([]);
    let tmptab = window.location.href.split('/');
    const serial = tmptab[tmptab.length - 1];
    const userid = sessionStorage.getItem("userId")
    const channelnameconnected = "connected";
    const channelnamelistsd = "listsd";
    const channelnamefilesd = "filesd";
    const channelnameloadingfile = "loadingfile";

    const { lastJsonMessage: lastJsonMessageListSd } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname: channelnamelistsd },
            share: false,
            shouldReconnect: () => true,

        },
    )

    const { lastJsonMessage: lastJsonMessageLoadingFile } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname: channelnameloadingfile },
            share: false,
            shouldReconnect: () => true,

        },
    )

    const { lastJsonMessage: lastJsonMessageFileSd } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname: channelnamefilesd },
            share: false,
            shouldReconnect: () => true,

        },
    )

    const { lastJsonMessage: lastJsonMessageConnected } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname: channelnameconnected },
            share: false,
            shouldReconnect: () => true,

        },
    )
    useEffect(() => {
        if (lastJsonMessageListSd !== null) {
            lastJsonMessageListSd.forEach(element => {
                if (element.serial === serial){
                    if(element.last_engin_list_sd !== 'Error Trame list sd')
                        setListSD(element.last_engin_list_sd);
                    else
                        setListSD([]);
                    setIsAddOrDeleteFile(false)
                    setIsLoading(false);
                }  
            });
            
        }
    }, [lastJsonMessageListSd])

    useEffect(() => {
        if (lastJsonMessageLoadingFile !== null) {
            lastJsonMessageLoadingFile.forEach(element => {
                if (element.serial === serial){
                    setDataDLFile(element.last_engin_file_sd);
                    if(element.last_engin_file_sd[0] !== element.last_engin_file_sd[1])
                        setDLFile(true);
                    else
                        setDLFile(false);
                }  
            });
            
        }
    }, [lastJsonMessageLoadingFile])

    useEffect(() => {
        if (directory !== "") {
            setIsLoading(true);
            axios.post(apiUrl + "/api/admin/EnginGetListSD", {
                serial: serial,
                directory: directory
            }, { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                .then(res => {
                })

        }
    }, [directory, isAddOrDeleteFile])

    useEffect(() => {
        if (lastJsonMessageConnected !== null) {
            setConnected(lastJsonMessageConnected[serial])
        }
    }, [lastJsonMessageConnected])

    useEffect(() => {
        if (lastJsonMessageFileSd !== null) {
            handleDownload(lastJsonMessageFileSd);
        }
    }, [lastJsonMessageFileSd])

    const handleDownload = (content) => {
        if (!content || !fileNameDownload) {
          alert('Le contenu ou le nom du fichier est vide');
          return;
        }
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileNameDownload;
        a.click();
        URL.revokeObjectURL(url);
      };
    


    const onClickHandler = (serial) => {
        const data = new FormData();
        if (serial !== null && selectedFile !== "") {
            data.append('file', selectedFile);
            data.append('serial', serial);
            const emplacement = directory.replaceAll("/", "@");
            data.append('emplacement', emplacement);
            axios.post(apiUrl + "/api/admin/UploadFile", data, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            }).then(res => {
                if (res.statusText === 'OK') {
                    inputFileRef.current.value = null;
                    alert("file upload Ok")
                    //handleDirectoryAfterDeleteorAdd(directory)
                    //setIsAddOrDeleteFile(true);
                } else {
                    alert("file send error");
                }
            })
        } else {
            alert('Erreur numéro de série vide ou aucun fichier selectionné ou emplacement vide ou emplacement ne commence pas par un /');
        }
    }

    const onClickHandlerManuel = (serial) => {
        const data = new FormData();
        if (serial !== null && selectedFileManuel !== "") {
            data.append('file', selectedFileManuel);
            data.append('serial', serial);
            const emplacement = directoryManuel.replaceAll("/", "@");
            data.append('emplacement', emplacement);
            axios.post(apiUrl + "/api/admin/UploadFile", data, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            }).then(res => {
                if (res.statusText === 'OK') {
                    inputFileRef.current.value = null;
                    alert("file upload Ok")
                } else {
                    alert("file send error");
                }
            })
        } else {
            alert('Erreur numéro de série vide ou aucun fichier selectionné ou emplacement vide ou emplacement ne commence pas par un /');
        }
    }

    const onChangeHandler = event => {
        setSelectedFile(event.target.files[0])
    }
    const handleDirectoryManuel = (e) => {
        setDirectoryManuel(e.target.value)
    }

    const onChangeHandlerManuel = event => {
        setSelectedFileManuel(event.target.files[0])
    }

    const handleDirectoryClick = (directoryName) => {
        // Mettez à jour si un dossier est cliqué
        if (directory.length > 1) {
            // Si le répertoire actuel a déjà une longueur supérieure à 1, mettez à jour prevDirectory
            setDirectory(directory + "/" + directoryName);
        }
        else {
            setDirectory("/" + directoryName);
        }
    };

    const handleDirectoryAfterDeleteorAdd = (directoryName) => {
        setDirectory(directoryName);
    }

    const FileExplorerItem = ({ item, onClick }) => {
        return (
            <div style={{ margin: '10px', textAlign: 'center', flexBasis: '25%', maxWidth: '200px' }} onClick={onClick}>
                {item.IsDirectory ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faFolder} size="4x" style={{ marginBottom: '10px' }} />
                        <p style={{ margin: 0 }}>{item.Name}</p>
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faFile} size="4x" style={{ marginBottom: '10px' }} />
                        <p style={{ margin: 0 }}>{item.Name}</p>
                        <p style={{ margin: 0 }}>Size : {item.Size} octets</p>
                    </div>
                )}
            </div>
        );
    };

    const handleFileClick = (event, file) => {
        event.preventDefault();
        const { clientX, clientY } = event;
        setPopupPosition({ x: clientX, y: clientY });
        setFile(file.Name);
        setShowPopup(true);
    };

    const handleDeleteFile = (serial) => {
        if (serial !== null && file !== "" && directory !== "") {

            const path = directory.replaceAll("/", "@");
            const fileName = "@" + file;
            let pathFileName = fileName;
            if (path.length > 1)
                pathFileName = path + fileName;
            const isConfirmed = window.confirm(`Êtes-vous sûr de vouloir supprimer le fichier "${file}" ?`);
            if (isConfirmed) {
                axios.post(apiUrl + "/api/admin/DeleteFile", { serial: serial, emplacement: pathFileName }, {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                }).then(res => {
                    if (res.statusText === 'OK') {
                        alert("file Delete Ok")
                        setShowPopup("");
                        handleDirectoryAfterDeleteorAdd(directory)
                        setIsAddOrDeleteFile(true);
                    } else {
                        alert("file Delete error");
                        setShowPopup("");
                    }
                })
            }
        } else {
            alert('Erreur numéro de série vide ou emplacement vide ou nom de fichier vide');
        }
    };

    const handleDownloadFile = (serial) => {
        if (serial !== null && file !== "" && directory !== "") {

            const path = directory.replaceAll("/", "@");
            const fileName = "@" + file;
            setFileNameDownload(file);
            let pathFileName = fileName;
            if (path.length > 1)
                pathFileName = path + fileName;
            const isConfirmed = window.confirm(`Êtes-vous sûr de vouloir télécharger le fichier "${file}" ?`);
            if (isConfirmed) {
                axios.post(apiUrl + "/api/admin/GetFile", { serial: serial, emplacement: pathFileName }, {
                    headers: { 'x-access-token': sessionStorage.getItem("token") }
                }).then(res => {
                    if (res.statusText === 'OK') {
                        alert("file send Download Ok")
                        setShowPopup("");
                        handleDirectoryAfterDeleteorAdd(directory)
                    } else {
                        alert("file Download error");
                        setShowPopup("");
                    }
                })
            }
        } else {
            alert('Erreur numéro de série vide ou emplacement vide ou nom de fichier vide');
        }
    };

    

    const handleOpen = (event, item) => {
        event.preventDefault();
        if (item.IsDirectory) {
            handleDirectoryClick(item.Name);
        } else {
            // Si c'est un fichier, déclenchez l'action associée
            handleFileClick(event, item);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        if (connected.isUploading && connected.upload_filesize === connected.upload_size_sended) {
            handleDirectoryAfterDeleteorAdd(directory);
            setIsAddOrDeleteFile(true);
        }
    }, [connected.upload_size_sended]);

    return (
        <div>
            {isLoading ? (
                <div className='divLoading'>
                    <img src={Loader} alt="Chargement..." className='imgLoading' />
                </div>
            ) : (
                <>
                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center' }}>
                        {listSD.map((item, index) => (
                            <FileExplorerItem key={index} item={item} onClick={(event) => handleOpen(event, item)} />
                        ))}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                        <input ref={inputFileRef} type="file" name="file" onChange={onChangeHandler} />
                        {selectedFile ? <Button title='Upload file' style={{ width: '15%' }} type="button" onClick={() => onClickHandler(serial)}><FontAwesomeIcon icon={faUpload} /></Button> : ""}
                        {connected.isUploading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}><progress max={connected.upload_filesize} value={connected.upload_size_sended}></progress><h6 style={{ margin: '3%' }}>Uploading...</h6></div> : ""}
                    </div>
                </>

            )}
            {dlFile ? <progress max={dataDlFile[1]} value={dataDlFile[0]}></progress>  : ""}

            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center' }}>
                <input ref={inputFileRefManuel} type="file" name="file" onChange={onChangeHandlerManuel} />
                <TextField
                    id="Emplacement"
                    label="Emplacement"
                    value={directoryManuel}
                    onChange={handleDirectoryManuel}
                />
                {selectedFileManuel ? <Button title='Upload file' style={{ width: '15%' }} type="button" onClick={() => onClickHandlerManuel(serial)}><FontAwesomeIcon icon={faUpload} /></Button> : ""}
            </div>


            {showPopup && (
                <div style={{ position: 'fixed', top: `${popupPosition.y}px`, left: `${popupPosition.x}px`, border: '1px solid #ccc', padding: '10px', background: '#fff', zIndex: '1' }}>
                    <Button >Ouvrir</Button>
                    <Button onClick={() => handleDeleteFile(serial)} >Supprimer</Button>
                    <Button onClick={() => handleDownloadFile(serial)} >Télécharger</Button>
                    <Button onClick={closePopup}>Fermer</Button>
                </div>
            )}
        </div>

    );
}

export default SdExplorer;