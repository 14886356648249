import {useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import Leaflet from "leaflet";
import ReactDOMServer from "react-dom/server";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from "@mui/material";
import Button from "@mui/material/Button";
import "./Rotation.css"

function Rotation() {
    const location = useLocation();
    const [Rotation, setRotation] = useState([]);

    function getNamebySerial(name, serial) {
        if (name.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN"))
            name = name + '(' + serial + ')';
        return name;
    }

    const iconHTML2 = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faMapMarkerAlt} size="3x"
                                                                     style={{color: 'blue'}}></FontAwesomeIcon>)
    const customMarkerIconblue = new Leaflet.DivIcon({
        html: iconHTML2,
    });
    const distanceThreshold = 0.00000000001;


    function groupMarkers(markers) {
        const groupedMarkers = [];
        markers.forEach(marker => {
            let grouped = false;
            if(!isNaN(marker.latitude) || !isNaN(marker.longitude)) {
                for (const group of groupedMarkers) {
                    const [groupMarker] = group;

                    const latDiff = Math.abs(groupMarker.latitude - marker.latitude);
                    const lngDiff = Math.abs(groupMarker.longitude - marker.longitude);

                    if (latDiff < distanceThreshold && lngDiff < distanceThreshold) {
                        group.push(marker);
                        grouped = true;
                        break;
                    }
                }

                if (!grouped) {
                    groupedMarkers.push([marker]);
                }
            }
        });

        return groupedMarkers;
    }


    const groupedMarkers = groupMarkers(Rotation);

    useEffect(() => {
        const data = location.state.rotation.map((data) => {
            return data.position.split('},{').map((str) => {
                // Supprime les accolades des extrémités
                const position = str.replace('{', '').replace('}', '');
                // Divise les valeurs séparées par des virgules et les stocke dans un tableau
                const values = position.split(',');
                // Convertit les valeurs de chaîne de caractères en types appropriés
                return {
                    serial: data.serial,
                    timestamp: new Date(data.timestamp),
                    latitude: parseFloat(values[0]),
                    longitude: parseFloat(values[1]),
                    material: data.material_name,
                    tonnage: data.tonnage,

                };
            });
        });
        const allDataArray = data.reduce((acc, curr) => acc.concat(curr), []);
        setRotation(allDataArray)


    }, [location.state.rotation])

    const sortedRotation = Rotation.slice().sort((a, b) => a.timestamp - b.timestamp);
    const totalItems = sortedRotation.length;

    /*const totalTonnage = sortedRotation.reduce((sum, item) => sum + item.tonnage, 0);*/
    const cellStyle = {
        width: '33.33%', // Divise l'espace en 3 pour les 3 colonnes égales

    };

    function formatDateWithTime(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return <div><span>{day}/{month}/{year}<br/>{hours}:{minutes}:{seconds}</span></div>;
    }



    return (
        <>
            <h1>{sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? getNamebySerial(location.state.name, location.state.serial) + " (" + (location.state.serial) + ") " : getNamebySerial(location.state.name, location.state.serial)}</h1>


            {Rotation.length > 0  ? !isNaN(Rotation[0].latitude) ?  <MapContainer center={[Rotation[0].latitude, Rotation[0].longitude]} zoom={20}
                                                 scrollWheelZoom={true}
                                                 className='mapContainerRotation'>
                <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                           maxZoom={22}
                           subdomains={['mt0', 'mt1', 'mt2', 'mt3']}/>
                {groupedMarkers.map((group, index) => {
                    if (group.length === 1) {
                        const marker = group[0];
                        return (
                            <Marker
                                key={index}
                                position={[marker.latitude, marker.longitude]}
                                icon={customMarkerIconblue}
                            >
                                <Popup>
                                    Materiaux : {marker.material}
                                    <br/>
                                    Tonnage : {marker.tonnage}
                                    <br/>
                                    date : {formatDateWithTime(marker.timestamp)}
                                </Popup>
                            </Marker>
                        );
                    } else {
                        const combinedData = group.map(marker => (
                            <div key={marker.timestamp}>
                                Materiaux : {marker.material}
                                <br/>
                                Tonnage : {marker.tonnage}
                                <br/>
                                date : {formatDateWithTime(marker.timestamp)}
                                <br/>
                                <hr noshade width="100%" size="3" align="left"></hr>
                            </div>
                        ))

                        return (
                            <Marker
                                key={index}
                                position={[group[0].latitude, group[0].longitude]}
                                icon={customMarkerIconblue}
                            >
                                <Popup>{combinedData}</Popup>
                            </Marker>
                        );
                    }
                })}
            </MapContainer> : "" : ""}

            {sortedRotation.length > 0 ?
                <>
                    <label className='labelRotation'>TotalTonnage : {location.state.loadTransported} T </label>
                    <label className='labelRotation'>TotalRotation : {totalItems} </label>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={cellStyle}>Date</TableCell>
                            <TableCell style={cellStyle}>Materiaux</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRotation.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell style={cellStyle}>{formatDateWithTime(item.timestamp)}</TableCell>
                                <TableCell style={cellStyle}>{item.material}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </>
                : ""}
        </>
    );
}

export default Rotation;