import 'leaflet/dist/leaflet.css';
import {FeatureGroup, MapContainer, Marker, Polygon, Polyline, Popup, TileLayer} from 'react-leaflet';
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPause, faPlay, faStop} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import MarkerIcon from 'Public/images/dump-truck.png';
import L from 'leaflet';
import {deviceTemplates} from "./DeviceTemplate";

let polylines_positions = [];

function leaflet(props) {
    const currentPositionRef = useRef(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [filteredDataSerial, setFilteredDataSerial] = useState([]);
    const [filteredDataCrossedSerial, setFilteredDataCrossedSerial] = useState([]);
    const [markerPositionSerial, setMarkerPositionSerial] = useState(null);
    const [markerPositionCrossedSerial, setMarkerPositionCrossedSerial] = useState(null);
    const [speedCrossedSerial, setSpeedCrossedSerial] = useState(null);
    const [speedSerial, setSpeedSerial] = useState(null);
    const [serial, setSerial] = useState(null);
    const [crossedSerial, setCrossedSerial] = useState(null);
    const [intervalValue, setIntervalValue] = useState(1);
    const [typeDevice, setTypeDevice] = useState([]);
    const leafletRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [Data, setData] = useState([]);
    const [ReplaySerial, setReplaySerial] = useState([]);
    const [ReplaySerialCrossed, setReplaySerialCrossed] = useState([]);
    const [showPolyline, setShowPolyline] = useState(true);
    const [deviceName, setDeviceName] = useState([]);
    let dateStart = "";
    let dateEnd = "";
    let serials = "";
    let worksiteid = "";
    let start = "";
    let stop = "";
    if (props !== "") {
        dateStart = new Date(props.movingDanger.dateStart.getTime() - 10 * 1000);
        dateEnd = new Date(props.movingDanger.dateEnd.getTime() + 10 * 1000);
        const dateStartReplay = new Date(props.movingDanger.dateStart);
        const dateStopReplay = new Date(props.movingDanger.dateEnd);
        dateStartReplay.setHours(0, 0, 1);
        start = new Date(dateStartReplay);

        dateStopReplay.setHours(23, 59, 59);
        stop = new Date(dateStopReplay);

        serials = [props.movingDanger.serial, props.movingDanger.CrossedSerial];
        worksiteid = props.movingDanger.worksiteid;
    }

    const customIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: ["30", "30"], // Définissez la taille de votre icône
    });
    useEffect(() => {
        axios.post(apiUrl + `/api/user/replayperiodserialsfilter`,
            {startdate: start, stopdate: stop, serials: serials , worksiteid :worksiteid},

            {
                headers: {'x-access-token': sessionStorage.getItem("token")}
            })
            .then(res => {
                const jsonString = res.data;
                const data = jsonString.map((data) => {
                    return data.position.split('},{').map((str) => {
                        // Supprime les accolades des extrémités
                        const position = str.replace('{', '').replace('}', '');
                        // Divise les valeurs séparées par des virgules et les stocke dans un tableau
                        const values = position.split(',');
                        // Convertit les valeurs de chaîne de caractères en types appropriés
                        return {
                            serial: data.serial,
                            type:data.device_type,
                            date: convertUTCDateToLocalDate(new Date(values[0])),
                            latitude: parseFloat(values[1]),
                            longitude: parseFloat(values[2]),
                            speed: values[3],
                            orientation: parseInt(values[4]),
                        };
                    });
                });
                const allDataArray = data.reduce((acc, curr) => acc.concat(curr), []);
                setData(allDataArray);
            })
    }, [props]);

    useEffect(() => {
        if(sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: {'x-access-token': sessionStorage.getItem("token")}
            })
                .then(res => {
                    setDeviceName(res.data);
                })
        }
        else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: {'x-access-token': sessionStorage.getItem("token")}
            })
                .then(res => {
                    setDeviceName(res.data);
                })
        }
    }, []);

    function getNamebySerial(serial){
        if(deviceName.length >0 && serial !== "") {
            const device = deviceName.find(device => device.username === serial);
            if(device)
                return device.name_device;
            else
                return "noname";
        }
    }

    async function coordinateOffsetToTemplate(coord) {
        let deviceTemplate;
        const type = coord.type;
        const response = await axios.post(apiUrl + `/api/user/familydevicetype`,
            {id_device_type: type},

            {
                headers: {'x-access-token': sessionStorage.getItem("token")}
            })

        const typeDevice = response.data;

        if (typeDevice.length > 0) {
            const typeDeviceName = typeDevice[0].nom_device_type.replace(/\s/g, "");
            deviceTemplate = deviceTemplates.find((template) => {
                return template.name === typeDeviceName;
            })
        }

        if (deviceTemplate !== undefined) {
            const lat1Offset = coord.latitude + (deviceTemplate.offsetY / 6366093) * (180 / Math.PI);

            const lng1Offset = coord.longitude + (deviceTemplate.offsetX / 6366093) * (180 / Math.PI) / Math.cos(coord.latitude * Math.PI / 180);

            const lat2Offset = coord.latitude + (deviceTemplate.offsetY / 6366093) * (180 / Math.PI);
            const lng2Offset = coord.longitude + ((deviceTemplate.sizeX + deviceTemplate.offsetX) / 6366093) * (180 / Math.PI) / Math.cos(coord.latitude * Math.PI / 180);

            const lat3Offset = coord.latitude + ((deviceTemplate.offsetY + deviceTemplate.sizeY) / 6366093) * (180 / Math.PI);
            const lng3Offset = coord.longitude + ((deviceTemplate.offsetX) / 6366093) * (180 / Math.PI) / Math.cos(coord.latitude * Math.PI / 180);

            const lat4Offset = coord.latitude + ((deviceTemplate.offsetY + deviceTemplate.sizeY) / 6366093) * (180 / Math.PI);
            const lng4Offset = coord.longitude + ((deviceTemplate.offsetX + deviceTemplate.sizeX) / 6366093) * (180 / Math.PI) / Math.cos(coord.latitude * Math.PI / 180);

            const corner1 = L.latLng(lat1Offset, lng1Offset);
            const corner2 = L.latLng(lat2Offset, lng2Offset);
            const corner3 = L.latLng(lat3Offset, lng3Offset);
            const corner4 = L.latLng(lat4Offset, lng4Offset);
            return rotatePoints(new L.LatLng(coord.latitude, coord.longitude), [corner1, corner2, corner4, corner3], coord.orientation);
        }
        return [];
    }

    function rotatePoints(center, points, yaw) {
        const res = [];
        const centerPoint = leafletRef.current.latLngToLayerPoint(center);
        const angle = yaw * (Math.PI / 180);

        for (let i = 0; i < points.length; i++) {
            const p = leafletRef.current.latLngToLayerPoint(points[i]);
            const p2 = new L.Point(p.x - centerPoint.x, p.y - centerPoint.y);
            const p3 = new L.Point(Math.cos(angle) * p2.x - Math.sin(angle) * p2.y, Math.sin(angle) * p2.x + Math.cos(angle) * p2.y);
            const p4 = new L.Point(p3.x + centerPoint.x, p3.y + centerPoint.y);
            const latLng = leafletRef.current.layerPointToLatLng(p4);
            res.push(latLng);
        }

        return res;
    }

    useEffect(() => {
        let intervalId;
        const startInterval = () => {
            intervalId = setInterval(async () => {
                // Utilisez la référence de position actuelle pour obtenir l'index de départ
                const startIndex = currentPositionRef.current;
                if (startIndex < filteredDataSerial.length) {
                    const endIndex = startIndex + 1;
                    const newReplaySerial = filteredDataSerial.slice(0, endIndex);
                    const newReplaySerialCrossed = filteredDataCrossedSerial.slice(0, endIndex);
                    const currentMarkerPositionSerial = filteredDataSerial[currentPositionRef.current];
                    const currentMarkerPositionCrossedSerial = filteredDataCrossedSerial[currentPositionRef.current];

                    const currentPositionsCrossedSerial = await coordinateOffsetToTemplate(currentMarkerPositionCrossedSerial)
                    const currentPositionsSerial = await coordinateOffsetToTemplate(currentMarkerPositionSerial)
                    if (currentPositionsSerial.length > 0)
                        setMarkerPositionSerial(currentPositionsSerial);
                    if (currentPositionsCrossedSerial.length > 0)
                        setMarkerPositionCrossedSerial(currentPositionsCrossedSerial);
                    setSpeedCrossedSerial(currentMarkerPositionCrossedSerial.speed)
                    setSpeedSerial(currentMarkerPositionSerial.speed)
                    setSerial(currentMarkerPositionSerial.serial)
                    setCrossedSerial(currentMarkerPositionCrossedSerial.serial)
                    setReplaySerial(newReplaySerial);
                    setReplaySerialCrossed(newReplaySerialCrossed);

                    // Mettre à jour la position actuelle pour la prochaine itération
                    currentPositionRef.current = endIndex;
                } else {
                    setIsPlaying(false);
                }
            }, 1000 / intervalValue);
        };

        const stopInterval = () => {
            clearInterval(intervalId);
        };

        if (isPlaying) {
            // Réinitialiser la position actuelle si le jeu est redémarré
            if (currentPositionRef.current === filteredDataSerial.length) {
                currentPositionRef.current = 0;
            }
            startInterval();
        } else {
            stopInterval();
        }

        return () => clearInterval(intervalId);
    }, [isPlaying, filteredDataSerial, filteredDataCrossedSerial, intervalValue]);

    useEffect(() => {
        polylines_positions = Data.filter((item) => item.date >= dateStart && item.date <= dateEnd);
        const newFilteredDataSerial = polylines_positions.filter((item) => item.serial === props.movingDanger.serial);
        const newFilteredDataCrossedSerial = polylines_positions.filter((item) => item.serial === props.movingDanger.CrossedSerial);
        setFilteredDataSerial(newFilteredDataSerial);
        setFilteredDataCrossedSerial(newFilteredDataCrossedSerial);

    }, [Data]);

    const handlePlayClick = () => {
        if (isPlaying) {
            setIsPlaying(false);
        } else {
            setIsPlaying(true);
            setReplaySerial([]);
            setReplaySerialCrossed([]);
        }
    };

    const togglePolyline = () => {
        setShowPolyline(!showPolyline);
    };

    const handleStopClick = () => {
        setIsPlaying(false);
    };


    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    return (
        <>
            <div style={{display: "flex"}}>
                {filteredDataCrossedSerial.length > 0 ? <Button className='buttonPlayStop'
                                                                onClick={handlePlayClick}>{isPlaying ?
                    <FontAwesomeIcon icon={faPause}></FontAwesomeIcon> :
                    <FontAwesomeIcon icon={faPlay}></FontAwesomeIcon>}</Button> : ""}

                {filteredDataCrossedSerial.length > 0 ?
                    <Button className='buttonPlayStop' variant="danger" onClick={handleStopClick}><FontAwesomeIcon
                        icon={faStop}></FontAwesomeIcon></Button> : ""}
                {filteredDataCrossedSerial.length > 0 ?
                    <h5 style={{marginTop: "1%", marginLeft: "1%"}}>{intervalValue} x</h5> : ""}
                {filteredDataCrossedSerial.length > 0 ? <input style={{marginLeft: "1%"}}
                                                               type="range"
                                                               min="1"
                                                               max="10"
                                                               value={intervalValue}
                                                               onChange={(e) => setIntervalValue(parseInt(e.target.value))}
                /> : ""}
                {filteredDataCrossedSerial.length > 0 ?
                    <label style={{marginTop: "1%", marginLeft: "1%"}} htmlFor="polylineCheckbox">Afficher la
                        polyline</label> : ""}
                {filteredDataCrossedSerial.length > 0 ?
                    <input style={{marginLeft: "1%"}}
                           type="checkbox"
                           id="polylineCheckbox"
                           checked={showPolyline}
                           onChange={togglePolyline}
                    /> : ""}



            </div>

            {filteredDataSerial.length > 0 ?
                <MapContainer ref={leafletRef} center={[filteredDataSerial[0].latitude, filteredDataSerial[0].longitude]} zoom={20}
                              scrollWheelZoom={true} style={{marginLeft: '1%', marginBottom: '1%', width: '100%'}} >
                    <TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                               maxZoom={22}
                               subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />

                    {isPlaying ? (
                        showPolyline ? (
                            <Polyline
                                positions={ReplaySerial.map(coordinates => [coordinates.latitude, coordinates.longitude])}
                                color="blue"/>
                        ) : ""
                    ) : (
                        showPolyline ? (
                            <Polyline
                                positions={filteredDataSerial.map(coordinates => [coordinates.latitude, coordinates.longitude])}
                                color="blue"/>
                        ) : ""
                    )}

                    {isPlaying ? (
                        showPolyline ? (
                            <Polyline
                                positions={ReplaySerialCrossed.map(coordinates => [coordinates.latitude, coordinates.longitude])}
                                color="red"/>
                        ) : null
                    ) : (
                        showPolyline ? (
                            <Polyline
                                positions={filteredDataCrossedSerial.map(coordinates => [coordinates.latitude, coordinates.longitude])}
                                color="red"/>
                        ) : null
                    )}

                    {markerPositionSerial && (
                        <Polygon positions={markerPositionSerial} color="blue">
                        </Polygon>
                    )}
                    {markerPositionSerial && (
                        <Polygon positions={[markerPositionSerial[2], markerPositionSerial[3]]} color="black" />
                    )}
                    {markerPositionCrossedSerial && (
                        <Polygon positions={markerPositionCrossedSerial} color="red" >
                        </Polygon>
                    )}
                    {markerPositionCrossedSerial && (
                        <Polygon positions={[markerPositionCrossedSerial[2], markerPositionCrossedSerial[3]]} color="black" />
                    )}
                    <div className="leaflet-bottom leaflet-left"></div>

                    <FeatureGroup> </FeatureGroup>

                </MapContainer>
                : ""}
            <div style={{display:'flex' , justifyContent:'center'}}>
                {markerPositionSerial && (
                    <p style={{marginLeft : '1%' , textAlign:'center'}}>{getNamebySerial(serial)} <br/> <img src="https://img.icons8.com/ios/50/000000/dump-truck.png"
                                                                                                             width="45px" height="45px"/> <br/>{speedSerial} km/h</p>
                )}

                {markerPositionCrossedSerial && (
                    <p style={{marginLeft : '1%',textAlign:'center'}}>{getNamebySerial(crossedSerial)} <br/> <img src="https://img.icons8.com/ios/50/000000/dump-truck.png"
                                                                                                                  width="45px" height="45px"/> <br/>{speedCrossedSerial} km/h</p>
                )}
            </div>
            {filteredDataSerial.length > 0 ? filteredDataCrossedSerial.length === 0 ? "No Data CrossedSerial" : "" : ""}

        </>
    )
}

export default leaflet;