// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapContainerRotation{
    margin-left: 1%;
    margin-bottom: 1%;
    width: 100% ;
    height:700px;

}

.labelRotation{
    font-weight: bold;
    margin-left:1%;
}

@media only screen and (max-width: 768px) {
    .mapContainerRotation{
        height:250px !important;

    }
}`, "",{"version":3,"sources":["webpack://./src/components/user/Rotation/Rotation.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,YAAY;;AAEhB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,uBAAuB;;IAE3B;AACJ","sourcesContent":[".mapContainerRotation{\n    margin-left: 1%;\n    margin-bottom: 1%;\n    width: 100% ;\n    height:700px;\n\n}\n\n.labelRotation{\n    font-weight: bold;\n    margin-left:1%;\n}\n\n@media only screen and (max-width: 768px) {\n    .mapContainerRotation{\n        height:250px !important;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
