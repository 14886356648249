// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divFleet{
    display: flex;
    flex-wrap: wrap;
    height: 750px;
}

.mapContainer{
    width: 100%;
    height: 100%;
}

.leaflet-div-icon{
    background: transparent !important;
    border: 0 !important;
}

/* Styles pour écrans de téléphone */
@media only screen and (min-width: 480px) and (max-width: 800px) {
    .divFleet{
        display: flex;
        flex-wrap: wrap;
        height: 150px;
    }

    .mapContainer{
        width: 100%;
        height: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/user/Fleet/FleetVisualization.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,oBAAoB;AACxB;;AAEA,oCAAoC;AACpC;IACI;QACI,aAAa;QACb,eAAe;QACf,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".divFleet{\n    display: flex;\n    flex-wrap: wrap;\n    height: 750px;\n}\n\n.mapContainer{\n    width: 100%;\n    height: 100%;\n}\n\n.leaflet-div-icon{\n    background: transparent !important;\n    border: 0 !important;\n}\n\n/* Styles pour écrans de téléphone */\n@media only screen and (min-width: 480px) and (max-width: 800px) {\n    .divFleet{\n        display: flex;\n        flex-wrap: wrap;\n        height: 150px;\n    }\n\n    .mapContainer{\n        width: 100%;\n        height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
