import React, {useEffect, useRef, useState} from "react";
import axios from 'axios';
import Button from '@mui/material/Button';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUpload , faXmark} from "@fortawesome/free-solid-svg-icons";
import Loader from 'Public/images/loader.gif';
import {IconButton} from "@mui/material";

const apiUrl = process.env.REACT_APP_API_URL;


function ReflashVisualization() {
    const [selectedFile, setSelectedFile] = useState(null);
    const inputFileRef = useRef(null);
    const [listSD, setListSD] = useState([]);
    const [directory, setDirectory] = useState('/DFUE/PP1');
    const [isLoading, setIsLoading] = useState(false);
    let tmptab = window.location.href.split('/');
    const serial = tmptab[tmptab.length-2];
    const serialP1 = tmptab[tmptab.length-1];

    useEffect(() => {
        if(directory !== "") {
            setIsLoading(true);
            axios.post(apiUrl + "/api/admin/EnginGetListSD", {
                serial: serial,
                directory: directory
            }, {headers: {'x-access-token': sessionStorage.getItem("token")}})
                .then(res => {
                    console.log(res.data)
                    if (res.data.success && res.data.last_engin_list_sd !== "Error Trame list sd") {
                        setListSD(res.data.last_engin_list_sd)
                        setIsLoading(false);
                    }
                    else{
                        setIsLoading(false);
                    }
                })

        }
    }, [directory])

    const onClickHandler = (serial) => {
        const data = new FormData();
        if (serial !== null && selectedFile !== "") {
            data.append('file', selectedFile);
            data.append('serial', serial);
            const emplacement = directory.replaceAll("/","@");
            data.append('emplacement', emplacement);
            axios.post(apiUrl + "/api/admin/UploadFile", data, {
                headers: {'x-access-token': sessionStorage.getItem("token")}
            }).then(res => {
                if (res.statusText === 'OK') {
                    inputFileRef.current.value = null;
                    alert("file upload Ok")
                    window.location.reload(false);
                } else {
                    alert("file send error");
                }
            })
        } else {
            alert('Erreur numéro de série vide ou aucun fichier selectionné ou emplacement vide ou emplacement ne commence pas par un /');
        }
    }

    const onChangeHandler = event => {
        setSelectedFile(event.target.files[0])
    }


    function ReflashP1(){

        axios.post(apiUrl + "/api/admin/ReflashP1", {serial: serial , serialP1:serialP1}, {
            headers: {'x-access-token': sessionStorage.getItem("token")}
        }).then(res => {
            console.log(res)
        })
    }

    const handleDeleteFile = (file) => {
        if (serial !== null && file !== "" && directory !== "") {

            const path = directory.replaceAll("/","@");
            const fileName = "@" + file;
            let pathFileName = fileName;
            if(path.length>1)
                pathFileName = path + fileName;
            const isConfirmed = window.confirm(`Êtes-vous sûr de vouloir supprimer le fichier "${file}" ?`);
            if (isConfirmed) {
                axios.post(apiUrl + "/api/admin/DeleteFile", {serial: serial, emplacement: pathFileName}, {
                    headers: {'x-access-token': sessionStorage.getItem("token")}
                }).then(res => {
                    if (res.statusText === 'OK') {
                        alert("file Delete Ok")
                        window.location.reload(false);
                    } else {
                        alert("file Delete error");
                    }
                })
            }
        } else {
            alert('Erreur numéro de série vide ou emplacement vide ou nom de fichier vide');
        }
    };


    return (
        <div>
            {isLoading ? (
                <div className='divLoading'>
                    <img src={Loader} alt="Chargement..." className='imgLoading'/>
                </div>
            ) : (
                <>
                    <h2>Reflash P1</h2>
                    <div style={{ margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px'}}>
                        <ul>
                            {listSD.map((item, index) => (
                                <li key={index}>
                                    {item.Name}
                                    <IconButton style={{width:'2%',marginLeft : '20px'}} aria-label="delete" size="small" onClick={()=>handleDeleteFile(item.Name)}><FontAwesomeIcon icon={faXmark} style={{color: 'red'}}></FontAwesomeIcon></IconButton>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <input ref={inputFileRef} type="file" name="file" onChange={onChangeHandler}/>
                    {selectedFile ? <Button  title='Upload file' style={{width: '15%'}}  type="button" onClick={() => onClickHandler(serial)}><FontAwesomeIcon icon={faUpload}/></Button> : ""}
                <Button variant='contained' onClick={()=> ReflashP1()}>Reflash P1</Button>
                </>
            )}
        </div>

    );
}

export default ReflashVisualization;