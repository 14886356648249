import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRankingStar } from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';
import Timeline from 'fix-react-vis-timeline';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import TooltipMaterial from '@mui/material/Tooltip';
import { MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Input } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Button from "@mui/material/Button";
import Loader from 'Public/images/loader.gif';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { Modal } from "react-bootstrap";
import "./DashboardProduction.css";
import useWebSocket from "react-use-websocket"

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const apiUrl = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;
function DashboardProductionV2() {
    const modalCadenceRef = useRef();
    const [selectedStopDate, setSelectedStopDate] = useState(new Date());
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWorksite, setSelectedWorksite] = useState("");
    const [dailyDatas, setDailyDatas] = useState([]);
    const [dailyUserDatas, setDailyUserDatas] = useState([]);
    const [Worksite, setWorksite] = useState([]);
    const [Picto, setPicto] = useState([]);
    const navigate = useNavigate();
    const [showRotationModal, setShowRotationModal] = useState(false);
    const [showConsommationModal, setShowConsommationModal] = useState(false);
    const [showCadenceModal, setShowCadenceModal] = useState(false);
    const [showPeriodModal, setShowPeriodModal] = useState(false);
    const [rotationSerialModal, setRotationSerialModal] = useState([]);
    const [cadenceSerialModal, setCadenceSerialModal] = useState([]);
    const [itemPeriod, setItemPeriod] = useState([]);
    const [consommationSerialModal, setConsommationSerialModal] = useState([]);
    const [deviceName, setDeviceName] = useState([]);
    const [backgroundColors, setBackgroundColors] = useState([]);
    const [email, setEmail] = useState('');
    const [connected, setConnected] = useState([]);

    const userid = sessionStorage.getItem("userId")
    const channelname = "filterconnected";

    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname },
            share: false,
            shouldReconnect: () => true,

        },
    )

    const handlePrint = () => {
    const printContent = modalCadenceRef.current.innerHTML;
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

    useEffect(() => {
        const dateNow = new Date().toDateString();
        const dateSelected = new Date(selectedStartDate).toDateString();
        if (lastJsonMessage !== null && dateNow === dateSelected) {
            setConnected(lastJsonMessage)
        }
    }, [lastJsonMessage])

    useEffect(() => {
        let distance_total_keepAlive = 0;
        dailyUserDatas.forEach(device => {
            const connectedDevice = connected.find(cd => cd.SerialStr === device.serial);
            if (connectedDevice && connectedDevice.IsConnected) {
                device.last_keepAlive = connectedDevice.last_keepAlive.timestamp;
                device.distance_keepAlive = connectedDevice.last_keepAlive.distance_running_period;
                distance_total_keepAlive += connectedDevice.last_keepAlive.distance_running_period;
            }
        });

        dailyDatas.forEach(deviceDaily => {
            deviceDaily.distance_total_keepAlive = (distance_total_keepAlive / 1000);
        })
    }, [connected])


    dailyUserDatas.sort((a, b) => {
        // Si les familles sont les mêmes, comparez par ordre alphanumérique des noms d'appareils
        const nameA = getNamebySerial(a.serial);
        const nameB = getNamebySerial(b.serial);
        return nameA.localeCompare(nameB);
    });

    function getPictoByTypeDevice(type) {

        const picto = Picto.find(device => device.id_device_type === type);

        if (picto)
            return picto.picto;
    }

    function getTotalTransportedforSerial(serial, rotation) {
        const Device = deviceName.find(device => device.username === serial);
        if (Device) {
            return (Device.payload * rotation) / 1000
        }
        else {
            return 0;
        }
    }


    const millisecondesEnDuree = (millisecondes) => {
        const secondsDifference = Math.floor(millisecondes / 1000);
        const heures = Math.floor(secondsDifference / 3600);
        const minutes = Math.floor((secondsDifference % 3600) / 60);
        const secondesRestantes = secondsDifference % 60;
        let duree;
        if (heures === 0)
            duree = `${minutes.toString().padStart(2, "0")}m${secondesRestantes.toString().padStart(2, "0")}s`;
        if (minutes === 0)
            duree = `${secondesRestantes.toString().padStart(2, "0")}s`;
        if (heures > 0)
            duree = `${heures.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}m${secondesRestantes.toString().padStart(2, "0")}s`;

        return duree;

    }

    const secondesEnDuree = (secondes) => {
        const secondesArrondies = Math.round(secondes);
        const heures = Math.floor(secondesArrondies / 3600);
        const minutes = Math.floor((secondesArrondies % 3600) / 60);
        const secondesRestantes = secondesArrondies % 60;
        return `${heures.toString().padStart(2, "0")}h${minutes.toString().padStart(2, "0")}m${secondesRestantes.toString().padStart(2, "0")}s`;
    };


    const handleModalPeriodClose = () => {
        setShowPeriodModal(false);
    };

    const handleOpenRotationModal = (item) => {

        setRotationSerialModal(item)
        setShowRotationModal(true);
    };

    const handleOpenModalPeriod = (item) => {
        setItemPeriod(item)
        setShowPeriodModal(true);
    };

    const handleOpenModalCadence = () => {
        setShowCadenceModal(true);
        if (dailyUserDatas.length > 0) {
            // Créer une nouvelle liste avec la cadence ajoutée pour chaque user
            const updatedUserDatas = dailyUserDatas
                .filter(userData => {
                    // Vérifier si le nombre de rotations est supérieur à 0
                    if (userData.prod_event && userData.prod_event.total_rotcnt > 0) {
                        return userData.device_type === 16 || userData.device_type === 51 || userData.device_type === 41 || userData.device_type === 13 || userData.device_type === 10 || userData.device_type === 11 || userData.device_type === 12 || userData.device_type === 14 || userData.device_type === 15;
                    }
                    return false; // Filtrer les éléments ne remplissant pas la condition
                })
                .map((userData) => {
                    const cadence = cadenceByHours(userData);
                    return {
                        serial: userData.serial,
                        rotation_count: userData.prod_event.total_rotcnt,
                        cadence: cadence,
                        device_name: getNamebySerial(userData.serial),
                    };
                });
            setCadenceSerialModal(updatedUserDatas);
        }
        else {
            setCadenceSerialModal([]);
        }


    }

    const handleOpenConsommationModal = (Consommation, Serial) => {
        const ConsommationWithSerial = [
            {
                serial: Serial,
                consommation: Consommation
            }
        ]
        setConsommationSerialModal(ConsommationWithSerial)

        setShowConsommationModal(true);
    };

    const handleModalRotationClose = () => {
        setShowRotationModal(false);
    };

    const handleModalConsommationClose = () => {
        setShowConsommationModal(false);
    };
    const handleModalCadenceClose = () => {
        setShowCadenceModal(false);
    };

    const handleStartDateChange = (date) => {
        setSelectedStartDate(date);
        setSelectedStopDate(date);
    };


    function getNamebySerial(serial) {
        if (deviceName.length > 0) {
            const device = deviceName.find(device => device.username === serial);
            let selectedDevice = "noname";
            if (device !== undefined) {
                let NameDevice = JSON.parse(device.name_device);
                let StartDate = new Date(selectedStartDate);
                StartDate.setHours(0, 0, 1);
                if (NameDevice.length > 1) {

                    for (const item of NameDevice) {
                        const itemDate = new Date(item.timestamp);
                        const startDate = new Date(StartDate);

                        if (itemDate < startDate) {
                            selectedDevice = item.nameDevice;
                        }
                    }
                } else {
                    const itemDate = new Date(NameDevice.timestamp);
                    const startDate = new Date(StartDate);
                    if (itemDate < startDate)
                        selectedDevice = NameDevice.nameDevice;
                }
            }
            else {

            }

            let str = selectedDevice;
            if (str.startsWith('noname') && !sessionStorage.getItem("Roles").includes("ROLE_ADMIN"))
                str = str + '(' + serial + ')';
            return str;
        }
    }


    useEffect(() => {
        axios.get(apiUrl + `/api/user/worksite`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {

                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
            })

        axios.get(apiUrl + `/api/user/picto`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setPicto(res.data);
            })
        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })

            axios.get(apiUrl + '/api/admin/serialsforadmin', {
                headers: { 'x-access-token': sessionStorage.getItem('token') }
            })
                .then(res => {
                    const data = res.data;
                    const deviceSerial = data.map(item => item.username);
                    sendJsonMessage(deviceSerial);

                })
        } else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    setDeviceName(res.data.sort((a, b) => a.username.localeCompare(b.username)));
                })

            axios.get(apiUrl + '/api/user/serialsforuser', {
                headers: { 'x-access-token': sessionStorage.getItem('token') }
            })
                .then(res => {
                    const data = res.data;
                    const deviceSerial = data.map(item => item.username);
                    sendJsonMessage(deviceSerial);
                })
        }
    }, []);


    useEffect(() => {
        if (Worksite.length > 0) {
            setSelectedWorksite(Worksite[0].worksiteid)
        }
    }, [Worksite]);


    useEffect(() => {
        var start, stop;
        start = selectedStartDate;
        stop = selectedStopDate;

        const dateStart = new Date(selectedStartDate);
        const dateStop = new Date(selectedStopDate);

        start = dateStart.setHours(0, 0, 1);
        stop = dateStop.setHours(23, 59, 59);
        setIsLoading(true);
        if (selectedWorksite !== "") {

            axios.post(apiUrl + `/api/user/reportprodevent`, {
                startdate: new Date(start), stopdate: new Date(stop), selectedWorksite: selectedWorksite
            }, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then(res => {
                    if (res.data.length > 0) {
                        setDailyUserDatas(res.data);
                        let total_distance = 0;
                        let total_rotcnt = 0;
                        let total_timeheating = 0;
                        let heating_count = 0;
                        let time_work = 0;
                        let time_post = 0;
                        let total_load_transported = 0;
                        //console.log(res.data);
                        res.data.forEach(reportprodevent => {
                            reportprodevent.periods.forEach(period => {
                                total_distance += period.distance;
                                if (reportprodevent.device_type !== 24) {
                                    time_work += period.time_work;
                                    const startTime = new Date(period.start);
                                    const stopTime = new Date(period.stop);
                                    const difference = stopTime - startTime;
                                    time_post += (difference / 1000);
                                }
                            });
                            total_rotcnt += reportprodevent.prod_event ? reportprodevent.prod_event.total_rotcnt : 0;
                            if (reportprodevent.prod_event && reportprodevent.prod_event.total_rotcnt > 0)
                                total_load_transported += getTotalTransportedforSerial(reportprodevent.serial, reportprodevent.prod_event.total_rotcnt);

                            total_timeheating += reportprodevent.time_heating;
                            heating_count += 1;
                        });
                        const average_timeheating = heating_count > 0 ? total_timeheating / heating_count : 0;
                        const DailyDatas = [{
                            total_load_transported: total_load_transported,
                            time_stop: time_post - time_work,
                            time_work: time_work,
                            total_rotcnt: total_rotcnt,
                            total_distance: (total_distance / 1000),
                            average_timeheating: average_timeheating
                        }];
                        setDailyDatas(DailyDatas);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setDailyDatas([]);
                        setDailyUserDatas([]);
                    }
                })
        }

    }, [selectedStartDate, selectedStopDate, selectedWorksite]);


    const handleWorksiteChange = (event) => {
        setSelectedWorksite(event.target.value);
    };

    function formatDateWithTime(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        let dateFormat = <div><span>{day}/{month}/{year}<br />{hours}:{minutes}:{seconds}</span></div>;
        if (selectedStartDate.toDateString() === selectedStopDate.toDateString())
            dateFormat = <div><span>{hours}:{minutes}:{seconds}</span></div>;


        return dateFormat;
    }

    function formatDateModal(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;
    }


    function Goto_ReplayWithSerial(serial) {

        let serialSelected = [];
        serialSelected.push(serial);

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            navigate('/admin/replay', {
                state: {
                    start: new Date(selectedStartDate),
                    stop: new Date(selectedStopDate),
                    worksite: selectedWorksite,
                    serials: serialSelected
                }
            });
        } else {
            navigate('/user/replay', {
                state: {
                    start: new Date(selectedStartDate),
                    stop: new Date(selectedStopDate),
                    worksite: selectedWorksite,
                    serials: serialSelected
                }
            });
        }
    }

    function Goto_Replay(date, serials_toAff, selectedWorksite) {

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            navigate('/admin/Replay', {
                state: {
                    ts: new Date(date),
                    serials: serials_toAff,
                    worksite: selectedWorksite
                }
            });
        } else {
            navigate('/user/Replay', {
                state: {
                    ts: new Date(date),
                    serials: serials_toAff,
                    worksite: selectedWorksite
                }
            });
        }
    }

    function Goto_ManageRotation(dateStart, dateStop, selectedWorksite) {

        if (sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            navigate('/admin/ManageRotation', {
                state: {
                    start: new Date(dateStart),
                    stop: new Date(dateStop),
                    worksite: selectedWorksite
                }
            });
        } else {
            navigate('/user/ManageRotation', {
                state: {
                    start: new Date(dateStart),
                    stop: new Date(dateStop),
                    worksite: selectedWorksite
                }
            });
        }
    }

    const ButtonWatchPartialReplayHandler = (serial, timestamp, selectedWorksite) => {
        let array = [];
        array.push(serial);
        Goto_Replay(timestamp, array, selectedWorksite);

    }

    function formatTime(secondes) {
        if (secondes !== undefined) {
            const hours = Math.floor(secondes / 3600);
            const minutes = Math.floor((secondes % 3600) / 60);
            const seconds = secondes % 60;
            let time = `${String(hours).padStart(2, '0')}h${String(minutes).padStart(2, '0')}m${String(seconds).padStart(2, '0')}s`;
            return time;
        } else
            return "0s"
    }

    function getPostTime(item) {
        let totalDifference = 0;

        const dateNow = new Date().toDateString();
        const dateSelected = new Date(selectedStartDate).toDateString();

        if (dateNow === dateSelected && item.period_in_progress) {
            item.periods[item.periods.length - 1].stop = item.last_keepAlive
        }
        if (item.periods.length > 0) {
            item.periods.forEach((period) => {
                const startTime = new Date(period.start);
                const stopTime = new Date(period.stop);
                const difference = stopTime - startTime;

                totalDifference += difference;
            });
        }
        return millisecondesEnDuree(totalDifference);
    }

    function getStopTime(period, time_work) {
        let total_difference = 0;

        if (period.length > 0) {
            period.forEach((item) => {
                const startTime = new Date(item.start);
                const stopTime = new Date(item.stop);
                const difference = stopTime - startTime; // en millisecondes

                total_difference += difference;
            });
        }

        return (total_difference / 1000) - time_work;
    }

    function countDays(startDate, stopDate) {
        // Convertir les chaînes de date en objets Date
        const start = new Date(startDate);
        const stop = new Date(stopDate);

        // Obtenir le temps en millisecondes entre les deux dates
        const timeDifference = stop.getTime() - start.getTime();

        // Calculer le nombre de jours
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;

        return daysDifference;
    }

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSendMail = () => {

        const start = selectedStartDate.setHours(0, 0, 1);
        const end = selectedStopDate.setHours(23, 59, 59);

        axios.post(apiUrl + `/api/admin/sendmailrotation`,
            { startdate: start, stopdate: end, selectedWorksite: selectedWorksite, email },
            { headers: { 'x-access-token': sessionStorage.getItem("token") } })
            .then(res => {
                alert(res.data);
            })
    };

    function GetEndDate(stopDate) {
        const end = stopDate.setHours(23, 59, 59);
        return end;
    }

    function GetStartDate(startDate) {
        const start = startDate.setHours(0, 0, 1);
        return start;
    }

    const optionsTimeline = {
        height: '100px',
        min: GetStartDate(selectedStartDate),
        max: GetEndDate(selectedStopDate),
        stack: false,
        width: '1200px',
        tooltip: {
            followMouse: true,
        },
        rollingMode: {
            follow: false,
        }
    }

    function cumulativeConso(consommation) {

        let volume_litre = 0;
        if (consommation !== undefined) {
            consommation.map((conso) => {
                volume_litre += conso.volume;
            });
        }
        return volume_litre;

    }

    function ChangeDateProduction(unixTimestamp) {
        // Créer un objet Date à partir du timestamp Unix (en secondes)
        let date = new Date(unixTimestamp);

        // Extraire les composants de la date
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
        let year = date.getFullYear();
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');

        // Retourner la chaîne de date complète
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }


    function timelineItem(item, current_serial) {
        const dateNow = new Date().toDateString();
        const dateSelected = new Date(selectedStartDate).toDateString();

        if (dateNow === dateSelected && item.period_in_progress) {
            item.periods[item.periods.length - 1].stop = item.last_keepAlive
        }
        return item.periods.map((period, periodIx) => {
            const startPeriod = new Date(period.start);
            const endPeriod = new Date(period.stop)

            const numberPeriod = periodIx + 1;
            return {
                id: 'P' + numberPeriod,
                content: 'Period ' + numberPeriod,
                start: startPeriod,
                end: endPeriod,
                type: 'range',
                className: "green",
                title: `
                Period ${numberPeriod} du
                ${formatDateModal(startPeriod)} à 
                ${formatDateModal(endPeriod)}`,
                group: current_serial
            };
        });
    }

    function cumulativeDistance(item) {
        const dateNow = new Date().toDateString();
        const dateSelected = new Date(selectedStartDate).toDateString();
        let distances = 0;

        const connectedDevice = connected.find(cd => cd.SerialStr === item.serial);
        if (item.periods.length > 0) {
            item.periods.map((period) => {
                if (item.period_in_progress) {
                    if (connectedDevice && connectedDevice.IsConnected && dateNow === dateSelected) {
                        distances += item.distance_keepAlive;
                    }
                }
                distances += period.distance;
            });
        }
        return (distances / 1000) + 'km';
    }

    function cadenceByHours(data) {
        //console.log(data)
        const totalRotations = data.prod_event.total_rotcnt;
        const cumulList = data.prod_event.cumul_list;
        let totalTaskDuration = 0;
        cumulList.forEach(cumulItem => {
            totalTaskDuration += cumulItem.task_duration;
        });

        const totalTaskDurationInHours = (totalTaskDuration / 3600).toFixed(2);
        //console.log(totalTaskDurationInHours)
        const cadence = totalRotations > 0 && totalTaskDurationInHours > 0.00 ? (totalRotations / totalTaskDurationInHours).toFixed(2) : 0;
        return cadence + "tr/h"
    }

    function cumulativeTimeWork(periods) {
        let time_work = 0;
        if (periods.length > 0) {
            periods.map((period) => {
                time_work += period.time_work;
            });
        }
        return time_work;
    }

    return (
        <div>
            <div>
                <label className='labelStart'>Date : </label>
                <span className='spanStart'>
                    <DatePicker showIcon
                        disabled={isLoading}
                        locale={fr}
                        dateFormat="dd/MM/yyyy"
                        selected={selectedStartDate}
                        onChange={(date) => handleStartDateChange(date)}
                    />


                </span>


                <Select className='selectWorksite' value={selectedWorksite} onChange={handleWorksiteChange}>
                    {Worksite.map((worksite, index) => (
                        <MenuItem key={index} value={worksite.worksiteid}>{worksite.worksitename}</MenuItem>
                    ))}
                </Select>

                {selectedStartDate.toDateString() !== new Date(Date.now()).toDateString() ?

                    <Button variant='outlined' style={{ marginLeft: '1%', width: '10%', height: '5%' }} onClick={handleOpenModalCadence} size="large" startIcon={<FontAwesomeIcon icon={faRankingStar} />} placeholder="Cadence">Cadence</Button> : ""
                }

                {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ?
                    (
                        <>
                            <Input type="email" placeholder="Entrez votre e-mail" className="emailInput" value={email} onChange={handleInputChange} />
                            <Button variant='outlined' className="sendButton" onClick={handleSendMail}>Send Mail</Button>
                        </>
                    ) : ""
                }
            </div>
            {isLoading ? (
                <div className='divLoading'>
                    <img src={Loader} alt="Chargement..." className='imgLoading' />
                </div>
            ) : (
                <>
                    {dailyDatas.length > 0 ?
                        dailyDatas.map((data, index) => (
                            <div key={index} className='frameIndicator'>
                                <h2>INDICATEURS CUMULES</h2>
                                <div className='allCard'>
                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent>
                                            <TooltipMaterial title="Rotation totale de tous les appareils" arrow>
                                                <div>
                                                    <Typography variant="h5" align="center">Rotations</Typography>
                                                    <div className="titleSeparator"></div>
                                                    <Typography variant="h4"
                                                        align="center">{data.total_rotcnt ? data.total_rotcnt : 0}</Typography>
                                                </div>
                                            </TooltipMaterial>

                                        </CardContent>
                                    </Card>

                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent >
                                            <TooltipMaterial
                                                title="Charges transportées totales de tous les appareils (rotation totale * tonnage totale)"
                                                arrow>
                                                <div>
                                                    <Typography variant="h5" align="center">Charges
                                                        transportées</Typography>
                                                    <div className="titleSeparator"></div>
                                                    <div className="cardData">
                                                        {data.total_load_transported > 0 ? (
                                                            <Button
                                                                onClick={() => Goto_ManageRotation(selectedStartDate, selectedStopDate, selectedWorksite)}>
                                                                <Typography variant="h4"
                                                                    align="center">{data.total_load_transported} T</Typography>
                                                            </Button>
                                                        ) : (
                                                            <Typography variant="h4"
                                                                align="center">{data.total_load_transported} T</Typography>
                                                        )}
                                                    </div>
                                                </div>
                                            </TooltipMaterial>
                                        </CardContent>
                                    </Card>

                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent >
                                            <TooltipMaterial
                                                title="Distance parcourue totale de tous les appareils par rapport au site"
                                                arrow>
                                                <div>
                                                    <Typography variant="h5" align="center">Distances</Typography>
                                                    <div className="titleSeparator"></div>
                                                    <div className="cardData">
                                                        <Typography variant="h4"
                                                            align="center">{data.distance_total_keepAlive ? (data.total_distance + data.distance_total_keepAlive).toFixed(2) : data.total_distance.toFixed(2)} km</Typography>
                                                    </div>

                                                </div>
                                            </TooltipMaterial>
                                        </CardContent>
                                    </Card>

                                    <Card variant="outlined" className='cardProd'>
                                        <CardContent>
                                            <TooltipMaterial
                                                title="Temps entre le 1er départ et la prise de poste moyen"
                                                arrow>
                                                <div>
                                                    <Typography variant="h5" align="center">Temps de chauffe moyen</Typography>
                                                    <div className="titleSeparator"></div>
                                                    <div className="cardData">
                                                        <Typography variant="h4"
                                                            align="center">{secondesEnDuree(data.average_timeheating)}</Typography>
                                                    </div>
                                                </div>
                                            </TooltipMaterial>
                                        </CardContent>
                                    </Card>
                                    {data.time_work > 0 && data.time_stop > 0 ? (
                                        <Card variant="outlined" className='cardProd'>
                                            <CardContent>

                                                <TooltipMaterial
                                                    title="Ce diagramme permet de visualiser le temps ou l'appareil est stoppé ou et en mouvement"
                                                    arrow>
                                                    <div>
                                                        <Pie
                                                            data={{
                                                                labels: ['roule', 'stop'],
                                                                datasets: [
                                                                    {
                                                                        data: [data.time_work, data.time_stop],
                                                                        backgroundColor: [
                                                                            'rgba(54, 162, 235, 0.2)',
                                                                            'rgba(255, 99, 132, 0.2)',
                                                                        ],
                                                                        borderColor: [
                                                                            'rgba(54, 162, 235, 1)',
                                                                            'rgba(255, 99, 132, 1)',
                                                                        ],
                                                                    },
                                                                ],
                                                            }}
                                                            options={{
                                                                plugins: {
                                                                    legend: {
                                                                        display: true,
                                                                    },
                                                                    tooltip: {
                                                                        callbacks: {
                                                                            label: (context) => {
                                                                                const label = context.label;

                                                                                if (label === 'roule') {
                                                                                    return `${secondesEnDuree(data.time_work)}`;
                                                                                } else if (label === 'stop') {
                                                                                    return `${secondesEnDuree(data.time_stop)}`;
                                                                                }

                                                                                return '';
                                                                            },
                                                                        },
                                                                    },
                                                                    datalabels: {
                                                                        color: 'black', // Couleur des libellés
                                                                        font: {
                                                                            size: 14, // Taille de police des libellés
                                                                        },
                                                                        formatter: (value, context) => {
                                                                            const total = context.dataset.data.reduce((acc, curr) => acc + curr);
                                                                            const percentage = ((value / total) * 100).toFixed(2); // Calcul du pourcentage avec 2 décimales
                                                                            return `${percentage}%`;
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </TooltipMaterial>

                                            </CardContent>
                                        </Card>
                                    ) : ''}
                                </div>
                                <div>
                                </div>
                            </div>
                        ))
                        :
                        (
                            <p className='pNoData'>Aucune donnée disponible.</p>
                        )
                    }
                    {
                        dailyUserDatas.length > 0 && (
                            <>
                                <TableContainer className='tableContainer'>
                                    <Table>
                                        <TableHead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                            <TableRow>
                                                <TableCell align={"center"}>Picto</TableCell>
                                                <TableCell align={"center"}>Vehicule</TableCell>
                                                <TableCell align={"center"}>Temps de poste</TableCell>
                                                <TableCell align={"center"}>Prise de poste</TableCell>
                                                <TableCell align={"center"}>Fin de poste</TableCell>
                                                <TableCell align={"center"}>Rotation</TableCell>
                                                <TableCell align={"center"}>Cadence</TableCell>
                                                <TableCell align={"center"}>Charge Transportée</TableCell>
                                                <TableCell align={"center"}>Distance parcourue</TableCell>
                                                <TableCell align={"center"}>Consommation</TableCell>
                                                <TableCell align={"center"}>Activités</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dailyUserDatas.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow>
                                                        <TableCell align={"center"}>
                                                            {item.device_type ? (
                                                                <img src={require(`Public/images/picto/${getPictoByTypeDevice(item.device_type)}`)} alt="" className='imgPicto' />
                                                            ) : null}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {sessionStorage.getItem("Roles").includes("ROLE_ADMIN") ? (
                                                                <>
                                                                    {item.serial ? `${getNamebySerial(item.serial)} (${item.serial})` : ''}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {getNamebySerial(item.serial)}
                                                                </>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.periods.length > 0 ? (
                                                                <Button onClick={() => handleOpenModalPeriod(item)} style={{ textTransform: 'lowercase' }}>
                                                                    {item.period_in_progress && new Date().toDateString() !== new Date(selectedStartDate).toDateString() ? <p style={{ backgroundColor: 'red' }}>{getPostTime(item)} </p> : getPostTime(item)}
                                                                </Button>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.start ? formatDateWithTime(item.start) : ''}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.stop != null ? formatDateWithTime(item.stop) : "En cours"}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.prod_event ? item.prod_event.total_rotcnt > 0 ? (
                                                                <Button onClick={() => handleOpenRotationModal(item)}>
                                                                    {item.prod_event.total_rotcnt}
                                                                </Button>
                                                            ) : '0' : '0'}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.prod_event ? cadenceByHours(item) : '0r/h'}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.prod_event ? item.prod_event.total_rotcnt ? getTotalTransportedforSerial(item.serial, item.prod_event.total_rotcnt) : 0 : 0} T
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.periods.length > 0 ? cumulativeDistance(item) : '0 km'}
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            {item.fuel ? cumulativeConso(item.fuel) > 0 ? (
                                                                <Button onClick={() => handleOpenConsommationModal(item.fuel, item.serial)}>
                                                                    {cumulativeConso(item.fuel)} L
                                                                </Button>
                                                            ) : (
                                                                '0 L'
                                                            ) : '0 L'}
                                                        </TableCell>
                                                        <TableCell align={"center"} className='tableCellPie'>
                                                            {item.device_type !== 24 ? item.period_in_progress && new Date().toDateString() !== new Date(selectedStartDate).toDateString() || !item.period_in_progress ? <Pie
                                                                data={{
                                                                    labels: ['roule', 'stop'],
                                                                    datasets: [
                                                                        {
                                                                            data: [cumulativeTimeWork(item.periods) || 0, getStopTime(item.periods, cumulativeTimeWork(item.periods))],
                                                                            backgroundColor: [
                                                                                'rgba(54, 162, 235, 0.2)',
                                                                                backgroundColors[index] || 'rgba(255, 99, 132, 0.2)',
                                                                            ],
                                                                            borderColor: [
                                                                                'rgba(54, 162, 235, 1)',
                                                                                'rgba(255, 99, 132, 1)',
                                                                            ],
                                                                        },
                                                                    ],
                                                                }}
                                                                options={{
                                                                    plugins: {
                                                                        legend: {
                                                                            display: true,
                                                                        },
                                                                        tooltip: {
                                                                            callbacks: {
                                                                                label: (context) => {
                                                                                    const label = context.label;

                                                                                    if (label === 'roule') {
                                                                                        return `${secondesEnDuree(cumulativeTimeWork(item.periods))}`;
                                                                                    } else if (label === 'stop') {
                                                                                        return `${secondesEnDuree(getStopTime(item.periods, cumulativeTimeWork(item.periods)))}`;
                                                                                    }

                                                                                    return '';
                                                                                },
                                                                            },
                                                                        },
                                                                        datalabels: {
                                                                            color: 'black',
                                                                            font: {
                                                                                size: 12,
                                                                            },
                                                                            formatter: (value, context) => {
                                                                                const total = context.dataset.data.reduce((acc, curr) => acc + curr);
                                                                                const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : 0;
                                                                                return `${percentage}%`;
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                            /> : "en cours" : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                    {item.periods ? item.periods.length > 0 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={10} style={{ padding: 0, width: '100%', height: '100%' }}>
                                                                <Box display="flex" justifyContent="center">
                                                                    <Timeline
                                                                        initialItems={timelineItem(item, item.serial)}
                                                                        options={optionsTimeline}
                                                                        keys={{ groupIdKey: 'id', itemGroupKey: 'group' }}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : null : ""}
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {itemPeriod.serial !== undefined ?
                                    <Modal size="lg" show={showPeriodModal} onHide={handleModalPeriodClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Détail du temps de poste du {getNamebySerial(itemPeriod.serial)} </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <>
                                                <TableContainer className='tableContainer'>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align={"center"}>1er départ</TableCell>
                                                                <TableCell align={"center"}>Temps de chauffe</TableCell>
                                                                <TableCell align={"center"}>Dernier arrêt</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell
                                                                    align={"center"}>{itemPeriod.fmv_ts != null ? <>
                                                                        <>

                                                                            {formatDateWithTime(itemPeriod.fmv_ts)}
                                                                        </>
                                                                    </> : ""}</TableCell>
                                                                <TableCell
                                                                    align={"center"}>{formatTime(itemPeriod.time_heating)}</TableCell>
                                                                <TableCell align={"center"}>{itemPeriod.stop != null ? (
                                                                    <>
                                                                        {formatDateWithTime(itemPeriod.stop)}
                                                                    </>
                                                                ) : (
                                                                    "En cours"
                                                                )}</TableCell>

                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {itemPeriod.periods.length > 0 ? (
                                                    <div>
                                                        <h5>Périodes :</h5>
                                                        <ul>
                                                            {itemPeriod.periods.map((item, index) => (
                                                                <li key={index}>
                                                                    <strong>Période {index + 1} :</strong> {formatDateModal(item.start)} - {formatDateModal(item.stop)}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : ""}
                                            </>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>
                                    </Modal> : ""}
                                {rotationSerialModal.serial !== undefined ?
                                    <Modal size="lg" show={showRotationModal} onHide={handleModalRotationClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Cumul {ChangeDateProduction(rotationSerialModal.prod_event.last_rotation_ts)} pour {getNamebySerial(rotationSerialModal.serial)} </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {<>
                                                {rotationSerialModal.prod_event.cumul_list.map((item, index) => (
                                                    <div key={index}>
                                                        {item.stock} - {item.material} : {item.rotation_count} voyage{item.rotation_count !== 1 && 's'} en {formatTime(item.task_duration)}
                                                    </div>
                                                ))}
                                            </>}
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>
                                    </Modal>
                                    : ""}

                                {consommationSerialModal.length > 0 ?
                                    <Modal size="xl" show={showConsommationModal} onHide={handleModalConsommationClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Consommation du {getNamebySerial(consommationSerialModal[0].serial)}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                {consommationSerialModal[0].consommation.length > 0 ? (
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Dernier plein GNR</TableCell>
                                                                <TableCell>Total (L)</TableCell>
                                                                {/*<TableCell>Distance parcourue (km)</TableCell>
                                                               <TableCell>Consommation (L/100km)</TableCell>
                                                                <TableCell>Durée d'activité</TableCell>
                                                                <TableCell>Consommation (L/h)</TableCell> */}
                                                                <TableCell>Emission de CO2 (kg)</TableCell>
                                                                <TableCell>Coût estimé de la taxe carbone (€)</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {consommationSerialModal[0].consommation.map((conso, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{formatDateModal(conso.timestamp)}</TableCell>
                                                                    <TableCell>{conso.volume}L</TableCell>
                                                                    {/* <TableCell>{conso.totalDistance.toFixed(2)} km</TableCell> */}
                                                                    {/*<TableCell>{((conso.last_volume_litre * 100) / conso.totalDistance).toFixed(2)} L/100km</TableCell>*/}
                                                                    {/*<TableCell>{millisecondesEnDuree(conso.time_full_volume_litre)}</TableCell>*/}
                                                                    {/*<TableCell>{((conso.last_volume_litre) * (3600000 / conso.time_full_volume_litre)).toFixed(1)} L/h</TableCell>*/}
                                                                    <TableCell>{(conso.volume * 3.07).toFixed(2)} kg CO2</TableCell>
                                                                    <TableCell>{((conso.volume * 3.07) * 0.273).toFixed(2)} €</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>
                                    </Modal>
                                    : ""}

                                {cadenceSerialModal.length > 0 ?
                                    <Modal size="xl" show={showCadenceModal} onHide={handleModalCadenceClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Cadence</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body ref={modalCadenceRef}>
                                            <Grid container spacing={3}>
                                                {cadenceSerialModal.map((userData, index) => (
                                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                                        <Card sx={{ minWidth: 275, textAlign: 'center', boxShadow: 5 }}>
                                                            <CardContent>
                                                                <Typography variant="h6" component="div">
                                                                    <br></br>
                                                                    {userData.device_name}
                                                                </Typography>

                                                                <Typography variant="h4" component="div" sx={{ margin: '20px 0', fontWeight: 'bold' }}>
                                                                    {userData.cadence}
                                                                </Typography>

                                                                <Typography color="textSecondary">
                                                                    Rotations : {userData.rotation_count}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>
                                    </Modal>
                                    : ""}
                            </>
                        )
                    }
                </>
            )
            }

        </div>

    )
        ;
}

export default DashboardProductionV2;