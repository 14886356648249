import React, {useEffect, useState} from "react";
import {Circle, FeatureGroup, MapContainer, Marker, Polygon, Popup, TileLayer, Tooltip} from 'react-leaflet';
import './EditWorksite.css';
import 'leaflet/dist/leaflet.css';
import MouseCoordinates from '../MouseCoordinates';
import {Card, InputGroup, Form, Accordion} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useParams} from "react-router";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import 'leaflet-draw/dist/leaflet.draw.css';
import {Icon} from 'leaflet'
import { EditControl } from "react-leaflet-draw";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {empty} from "leaflet/src/dom/DomUtil";

const apiUrl = process.env.REACT_APP_API_URL;
let idPos = 0;
let positionsLayers = [];
function EditWorksite() {
    const location = useLocation();
    const params = useParams();
    const [positions, setPositions] = useState([]);
    const [positionRef,setPositionRef] = useState([]);
    const [worksite, setWorksite] = useState([]);
    const [editingPositionId, setEditingPositionId] = useState(null);

    useEffect(() => {

        axios.post(apiUrl + `/api/user/worksitebyid`, {
            worksiteid: location.state.worksite },
            {headers: {'x-access-token': sessionStorage.getItem("token")}
        })
            .then(res => {
                setWorksite(res.data);
                const positionRefLatLong = JSON.parse(res.data.positionref);
                setPositionRef(positionRefLatLong)
            })
    }, [location.state.worksite]);



    const onCreated = (e) => {
        const layer = e.layer;
        const id = idPos++
        let positionData = { id: layer._leaflet_id.toString(), name: 'Default' + id};

        if (layer instanceof L.Circle) {
            positionData = {
                ...positionData,
                lat: layer.getLatLng().lat,
                long: layer.getLatLng().lng,
                radius: layer.getRadius(),
                type:"circle"
            };
        } else if (layer instanceof L.Polygon || layer instanceof L.Rectangle ) {
            const latLngs = layer.getLatLngs()[0];
            const pointsData = latLngs.map((point) => ({ lat: point.lat, long: point.lng }));
            positionData = {
                ...positionData,
                ...pointsData.reduce((acc, point, index) => {
                    acc[`lat${index}`] = point.lat;
                    acc[`long${index}`] = point.long;
                    return acc;
                }, {}),
                type: 'polygon',
            };
        }

        setPositions((positions) => [...positions, positionData]);
        positionsLayers.push(positionData);
    };
    const onDeleted = (e) => {
        const deletedLayers = e.layers.getLayers();
        const deletedLayerIds = deletedLayers.map((layer) => layer._leaflet_id.toString());
        const updatedPositions = positionsLayers.filter((position) => {
            // Vérifie si l'ID de la position fait partie des IDs des couches supprimées
            return !deletedLayerIds.includes(position.id.toString());
        });
        positionsLayers = updatedPositions;
        setPositions(updatedPositions);
    };


    const handleNameChange = (id, newName) => {
        const updatedPositions = positions.map((position) =>
            position.id === id ? { ...position, name: newName } : position
        );
        setPositions(updatedPositions);
        setEditingPositionId(null);
    };

    const handleChangePositionLayers = () => {
        const worksiteparameters = JSON.parse(worksite.worksiteparameters);
        const updatedWorksiteParameters = {
            ...worksiteparameters,
            positionLayers: positions,
        };
        const jsonDataWorksiteParameters = JSON.stringify(updatedWorksiteParameters);

        axios.post(apiUrl + "/api/user/updateworksiteparameters", {
            worksiteparameters: jsonDataWorksiteParameters,
            worksiteid: location.state.worksite
        }, {
            headers: {'x-access-token': sessionStorage.getItem("token")}
        })
            .then((response) => {
                alert(response.data.message);
            })
            .catch((error) => {
                console.error("Erreur lors de l'update :", error);
                alert("Erreur lors de l'update");
            });
    };


    const onEdited = (e) => {
        const editedLayers = e.layers.getLayers();
        const updatedPositions = positionsLayers.map((position) => {
            const editedLayer = editedLayers.find((layer) => layer._leaflet_id.toString() === position.id.toString());
            if (editedLayer) {
                let updatedPosition = {
                    ...position,
                    lat: editedLayer.getLatLng().lat,
                    long: editedLayer.getLatLng().lng
                };

                // Mettre à jour les coordonnées spécifiques au type de layer
                if (editedLayer instanceof L.Circle) {
                    updatedPosition = {
                        ...updatedPosition,
                        radius: editedLayer.getRadius()
                    };
                } else if (editedLayer instanceof L.Polygon || editedLayer instanceof L.Rectangle) {
                    const latLngs = editedLayer.getLatLngs()[0];
                    const pointsData = latLngs.map((point, index) => ({
                        [`lat${index}`]: point.lat,
                        [`long${index}`]: point.lng
                    }));
                    updatedPosition = {
                        ...updatedPosition,
                        ...pointsData.reduce((acc, point) => ({ ...acc, ...point }), {})
                    };
                }

                return updatedPosition;
            }
            return position;
        });

        positionsLayers = updatedPositions;
        setPositions(updatedPositions);
    };


    return (
        <div
            style={{
                border: '1px solid lightgray',
                borderRadius: '5px 5px',
                background: 'white'
            }}
        >
            <h6 style={{ marginTop: '1%', marginBottom: '1%', marginLeft: '1%' }}>Editeur de zones</h6>

            <div
                style={{
                    display: 'flex',
                    outline: '1px solid lightgray',
                    outlineOffset: '10px',
                    borderRadius: '0px 0px 5px 5px',
                    background: 'white'
                }}
            >
                {worksite.worksitename !== undefined ? <MapContainer center={[positionRef.lat, positionRef.long]} zoom={16} scrollWheelZoom={true} style={{ marginLeft: '1%', marginBottom: '1%' }}>
                    <TileLayer
                        url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        maxZoom={22}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                    <div className="leaflet-bottom leaflet-left">
                        <MouseCoordinates />
                    </div>
                    <FeatureGroup>
                        <EditControl
                            position="topright"
                            onCreated={onCreated}
                            onDeleted={onDeleted}
                            onEdited={onEdited}
                            draw={{
                                rectangle: false,
                                marker:false,
                                circlemarker:false,
                                polyline:false
                            }}
                        />
                    </FeatureGroup>
                    <Circle center={[positionRef.lat, positionRef.long]} radius={worksite.rayon} />
                </MapContainer> : ""}
                <div style={{ marginLeft: '1%' }}>
                    <Card style={{ width: '50rem' }}>
                        <Card.Body>
                            {worksite.worksitename !== undefined  ? <Card.Title style={{ textAlign: 'center' }}>{worksite.worksitename  + "/" + worksite.worksitedesc}</Card.Title> : ""}
                            <br />
                            Zones
                            {/*<Button variant="primary" style={{ width: '5%', marginLeft: '1%' }} onClick={onCreated}>
                                +
                            </Button>*/}
                            <Accordion>
                                {positions.map((position, index) => (
                                        <Accordion.Item key={index} eventKey={position.id}>
                                            <Accordion.Header onClick={() => setEditingPositionId(position.id)}>
                                                {editingPositionId === position.id ? (
                                                    <input
                                                        type="text"
                                                        value={position.name}
                                                        onChange={(e) => handleNameChange(position.id, e.target.value)}
                                                    />
                                                ) : (
                                                    position.name
                                                )}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {Object.entries(position).map(([key, value]) => (
                                                    <React.Fragment key={key}>
                                                        {key}: {value}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </Card.Body>
                        <Card.Footer>
                            <Button onClick={handleChangePositionLayers} variant="primary" style={{ width: '30%', marginLeft: '35%' }}>
                                Update
                            </Button>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default EditWorksite;