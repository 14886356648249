import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import TextField from "@mui/material/TextField";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import Button from "@mui/material/Button";

const apiUrl = process.env.REACT_APP_API_URL;

const UserPage = () => {

    const [username, setUsername] = useState('');
    const [clientId, setClientId] = useState('');
    const [client, setClient] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [rayon, setRayon] = useState(0);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [worksite, setWorksite] = useState("");
    const [worksitedesc, setWorksiteDesc] = useState("");
    const [clientIdWorksite, setClientIdWorksite] = useState("");
    const [roleUser, setRoleUser] = useState([]);

    const handleAddWorksiteSubmit = () => {

        if (worksite !== "") {
            axios.post(apiUrl + "/api/admin/addworksite", {
                worksitename: worksite,
                worksitedesc,
                worksiteparameters: '{"security":{"Speedmax_site_global":"50"},"production":{},"positionLayers":{},"plateforme":{"detectionrotation":{"Scrap":{"HighSpeed":"","LowSpeed":""},"Dumper":{"HighSpeed":"","LowSpeed":""}}}}',
                clientId:clientIdWorksite,
                latitude,
                longitude,
                rayon,
            }, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then((response) => {
                    alert(response.data.message);
                    setWorksite('');
                    setWorksiteDesc('');
                    setClientIdWorksite('');
                    setRayon(0);
                    setLatitude(0);
                    setLongitude(0);
                    //window.location.reload(false);
                })
                .catch((error) => {
                    console.error("Erreur lors de l'update :", error);
                    alert("Erreur lors de l'update");
                });
        }
    }

    const handleAddUserSubmit = () => {
        if (username !== "" && password !== "" && email !== "" && role !== "") {
            const roleforAdd = [role];
            axios.post(apiUrl + "/api/auth/signup", {
                username: username,
                password: password,
                email: email,
                idClient: clientId,
                roles: roleforAdd,
            }, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            })
                .then((response) => {
                    alert(response.data.message);
                    setRole('');
                    setEmail('');
                    setPassword('');
                    setUsername('');
                    setClientId('');
                    window.location.reload(false);
                })
                .catch((error) => {
                    console.error("Erreur lors de l'update :", error);
                    alert("Erreur lors de l'update");
                });
        }
    }

    useEffect(() => {
        axios.get(apiUrl + `/api/admin/client`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setClient(res.data.sort((a, b) => a.nomClient.localeCompare(b.nomClient)));
            })

        axios.get(apiUrl + `/api/admin/roleuser`, {
            headers: { 'x-access-token': sessionStorage.getItem("token") }
        })
            .then(res => {
                setRoleUser(res.data);
            })
    }, [])



    return (
        <div style={{ display: 'flex' }}>
            {client.length > 0 ?
                <div className="divDevice">
                    <div className='cardDevice2'>
                        <h1 className='centerDevice'>User</h1>
                        <form onSubmit={handleAddUserSubmit}>
                            <div>
                                <TextField className='formDevice'
                                    id='usernameid'
                                    label="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <TextField className='formDevice'
                                    id='emailid'
                                    label="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <TextField className='formDevice'
                                    id='passwordid'
                                    type="password"
                                    label="Mot de passe"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="client-label">Role*</InputLabel>
                                    <Select
                                        labelId="client-label"
                                        id="role"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        required
                                    >
                                        <MenuItem value="">Sélectionnez un client</MenuItem>
                                        {roleUser.map((role, index) => (
                                            <MenuItem key={index} value={role.name}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="client-label">Client*</InputLabel>
                                    <Select
                                        labelId="client-label"
                                        id="clientid"
                                        value={clientId}
                                        onChange={(e) => setClientId(e.target.value)}
                                        required
                                    >
                                        <MenuItem value="">Sélectionnez un client</MenuItem>
                                        {client.map((client, index) => (
                                            <MenuItem key={index} value={client.idClient}>{client.nomClient}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <Button type="submit" variant="contained" color="primary" className='width100Device'>
                                    Ajouter
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                : ""}
            {client.length > 0 ?
                <div className="divDevice" style={{ marginLeft: '2%' }}>
                    <div className='cardDevice2'>
                        <h1 className='centerDevice'>Worksite</h1>
                        <form onSubmit={handleAddWorksiteSubmit}>
                            <div>
                                <TextField className='formDevice'
                                    id='worksitename'
                                    label="worksite"
                                    value={worksite}
                                    onChange={(e) => setWorksite(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <TextField className='formDevice'
                                    id='worksitedesc'
                                    label="description"
                                    value={worksitedesc}
                                    onChange={(e) => setWorksiteDesc(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <TextField className='formDevice'
                                    id='rayon'
                                    type="number"
                                    label="rayon"
                                    value={rayon}
                                    onChange={(e) => setRayon(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <TextField className='formDevice'
                                    id='latitude'
                                    type="number"
                                    label="latitude"
                                    value={latitude}
                                    onChange={(e) => setLatitude(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <TextField className='formDevice'
                                    id='longitude'
                                    type="number"
                                    label="longitude"
                                    value={longitude}
                                    onChange={(e) => setLongitude(e.target.value)}
                                    required
                                />
                            </div>
                            <br />
                            <div>
                                <FormControl className='formDevice'>
                                    <InputLabel id="client-label">Client*</InputLabel>
                                    <Select
                                        labelId="client-label"
                                        id="clientidWorksite"
                                        value={clientIdWorksite}
                                        onChange={(e) => setClientIdWorksite(e.target.value)}
                                        required
                                    >
                                        <MenuItem value="">Sélectionnez un client</MenuItem>
                                        {client.map((client, index) => (
                                            <MenuItem key={index} value={client.idClient}>{client.nomClient}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <Button type="submit" variant="contained" color="primary" className='width100Device'>
                                    Ajouter
                                </Button>
                            </div>
                        </form>
                    </div>
                </div> : ""}
        </div>

    )
}

export default UserPage