import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import axios from 'axios';
import { Card, Toast } from "react-bootstrap";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MenuItem, Select } from "@mui/material";
import useWebSocket from "react-use-websocket"

const apiUrl = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL;
let intervalID_EnginUpdate = null;

function EnginUpdate() {
    const [lcd_checked, setlcd_checked] = useState(true);
    const [main_checked, setmain_checked] = useState(false);
    const [bt_checked, setbt_checked] = useState(false);
    const [retapi, setRetapi] = useState();
    const [retapi_ts, setRetapi_ts] = useState(null);
    const [uwbAntselectedFile, setUwbAntSelectedFile] = useState(null);
    const [internalSelectedFile, setInternalSelectedFile] = useState(null);
    const [connected_devices, setConnectedDevices] = useState([]);
    const [our_serial_keepAlive, setOur_serial_keepAlive] = useState([]);
    const userid = sessionStorage.getItem("userId")
    const channelname = "connected";
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            queryParams: { userid, channelname },
            share: false,
            shouldReconnect: () => true,

        },
    )


    const uwbAntinputFileRef = useRef(null);
    const internalinputFileRef = useRef(null);

    const cb_upload_lcd = useRef(true);
    const cb_upload_main = useRef(null);
    const cb_upload_bt = useRef(null);
    const [Version1, setVersion1] = useState(0);
    const [Version2, setVersion2] = useState(0);
    const [Version1UWB, setVersion1UWB] = useState(0);
    const [Version2UWB, setVersion2UWB] = useState(0);

    const params = useParams()
    const serial = params.serial;




    const handleVersion1 = (event) => {
        setVersion1(event.target.value);
    };

    const handleVersion2 = (event) => {
        setVersion2(event.target.value);
    };

    const handleVersion1UWB = (event) => {
        setVersion1UWB(event.target.value);
    };

    const handleVersion2UWB = (event) => {
        setVersion2UWB(event.target.value);
    };


    useEffect(() => {
        if (lastJsonMessage !== null) {
            let find = false;
            Object.entries(lastJsonMessage).forEach(element => {
                if ((element[1].SerialStr === serial) && (element[1].IsConnected)) {
                    find = true;
                    setOur_serial_keepAlive(element[1]);
                }
            });
            if (!find)
                setOur_serial_keepAlive([]);
            setConnectedDevices(lastJsonMessage);
        }
    }, [lastJsonMessage])


    useEffect(() => {
        intervalID_EnginUpdate = setInterval(() => {
            axios.post(apiUrl + "/api/admin/EnginUpdateStatus", { serial: serial }, { headers: { 'x-access-token': sessionStorage.getItem("token") } })
                .then(res => {
                    if (res.data.success) {
                        setRetapi(res.data.last_engin_update_status)
                        setRetapi_ts(new Date().toLocaleString());
                    }
                })
        }, 1000)
    }, []);

    useEffect(() => {
        return () => {
            //console.log("cleaned up");
            clearInterval(intervalID_EnginUpdate);
        };
    }, []);




    const onCheckboxLCDChecked = (event) => {

        if (event.target.checked) {
            setlcd_checked(true);
            setmain_checked(false);
            setbt_checked(false);
        }
        else
            setlcd_checked(false);
    }
    const onCheckboxMainChecked = (event) => {

        if (event.target.checked) {
            setlcd_checked(false);
            setmain_checked(true);
            setbt_checked(false);
        }
        else
            setmain_checked(false);
    }
    const onCheckboxBTChecked = (event) => {

        if (event.target.checked) {
            setlcd_checked(false);
            setmain_checked(false);
            setbt_checked(true);
        }
        else
            setbt_checked(false);
    }


    const onInternalFileChangeHandler = (event) => {
        //console.log(event.target.files[0])
        setInternalSelectedFile(event.target.files[0]);
    }
    const onUwbAntFileChangeHandler = (event) => {
        //console.log(event.target.files[0])
        setUwbAntSelectedFile(event.target.files[0]);
    }

    const onUpdateLcdClickHandler = () => {
        // ELCD64.hex
        const renameFileLCD = renameFile(internalSelectedFile, "ELCD" + Version1 + Version2 + ".hex");
        UpdateFirmware(renameFileLCD, "ENGIN LCD");
    }
    const onUpdateMainClickHandler = () => {
        //E41047.hex
        const renameFileMain = renameFile(internalSelectedFile, "E410" + Version1 + Version2 + ".hex");
        UpdateFirmware(renameFileMain, "ENGIN MAIN");
    }
    const onUpdateBtClickHandler = () => {
        //EBT51.hex
        const renameFileBT = renameFile(internalSelectedFile, "EBT" + Version1 + Version2 + ".hex");
        UpdateFirmware(renameFileBT, "ENGIN BT");
    }
    const onUpdateUwbClickHandler = () => {
        //EXPU39.hex
        const renameFileUWB = renameFile(uwbAntselectedFile, "EXPU" + Version1UWB + Version2UWB + ".hex");
        UpdateFirmware(renameFileUWB, "EXPANDEUR UWB");
    }

    function renameFile(file, newName) {
        try {
            // Créez un nouveau objet File avec le même contenu mais un nom différent
            const renamedFile = new File([file], newName, { type: file.type, lastModified: file.lastModified });

            // Retournez le nouveau fichier renommé
            return renamedFile;
        } catch (error) {
            console.error("Erreur lors du renommage du fichier :", error);
            return null;
        }
    }

    function UpdateFirmware(selectedfile, type) {
        const data = new FormData();
        data.append('file', selectedfile);
        data.append('serial', serial);
        data.append('type', type);

        if (serial !== null && selectedfile !== null) {
            axios.post(apiUrl + "/api/admin/uploadFirmware", data, {
                headers: { 'x-access-token': sessionStorage.getItem("token") }
            }).then(res => {
                if (res.statusText === 'OK') {

                } else {
                    alert("Firmware send error");
                }
            })
        } else {
            alert('Serial null or file null or type null');
        }
    }

    return (
        <div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                <p className="fs-2 me-auto">Engin {serial} - {((our_serial_keepAlive) && (our_serial_keepAlive.IsConnected)) ? "Connecté" : "Déconnecté"}</p>
                {retapi_ts ? <p className="fs-5">{retapi_ts}</p> : <p className="fs-5">Not received</p>}

            </div>
            {((our_serial_keepAlive) && (our_serial_keepAlive.IsConnected)) ?
                <div>

                    {/* <p>update_status:{JSON.stringify(retapi)}</p>
            <p>connected:{JSON.stringify(connected_devices)}</p>             */}

                    <Card style={{ marginBottom: '1%' }}>
                        <Card.Title style={{ margin: '1%' }}>Boitier Engin</Card.Title>
                        {retapi ?
                            <Card.Body style={{ display: "flex", flexWrap: "wrap" }}>
                                <Toast style={{ marginLeft: '1%', marginTop: "1%" }}>
                                    <Toast.Header>
                                        <strong className="me-auto">LCD uC</strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        <div>
                                            <p className="lh-2 fw-bold">Version : {retapi.LCD_version}</p>
                                            {(retapi.LCD_initstate & 0x01) ? <p className="lh-1 text-success">TP OK</p> : <p className="lh-1 text-danger">TP ERROR</p>}
                                            {(retapi.LCD_initstate & 0x02) ? <p className="lh-1 text-success">SD OK</p> : <p className="lh-1 text-danger">SD ERROR</p>}
                                            {(retapi.LCD_initstate & 0x08) ? <p className="lh-1 text-success">RTC OK</p> : <p className="lh-1 text-danger">RTC ERROR</p>}
                                            {(retapi.LCD_initstate & 0x10) ? <p className="lh-1 text-success">SDRAM OK</p> : <p className="lh-1 text-danger">SDRAM ERROR</p>}
                                            {(retapi.LCD_initstate & 0x20) ? <p className="lh-1 text-success">EnginFonctions OK</p> : <p className="lh-1 text-danger">EnginFonctions ERROR</p>}
                                        </div>
                                    </Toast.Body>
                                </Toast>

                                <Toast style={{ marginLeft: '1%', marginTop: "1%" }}>
                                    <Toast.Header>
                                        <strong className="me-auto">MAIN 410 uC</strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        <div>
                                            <p className="lh-2 fw-bold">Version : {retapi.Main410_version}</p>
                                            {(retapi.Main410_initstate & 0x01) ? <p className="lh-1 text-success">GPS OK</p> : <p className="lh-1 text-danger">GPS ERROR</p>}
                                            {(retapi.Main410_initstate & 0x02) ? <p className="lh-1 text-success">EEPROM OK</p> : <p className="lh-1 text-danger">EEPROM ERROR</p>}
                                            {(retapi.Main410_initstate & 0x04) ? <p className="lh-1 text-success">LORA OK</p> : <p className="lh-1 text-danger">LORA ERROR</p>}
                                            {(retapi.Main410_initstate & 0x08) ? <p className="lh-1 text-success">BT init OK</p> : <p className="lh-1 text-danger">BT init ERROR</p>}
                                        </div>
                                    </Toast.Body>
                                </Toast>

                                <Toast style={{ marginLeft: '1%', marginTop: "1%" }}>
                                    <Toast.Header>
                                        <strong className="me-auto">BT uC</strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        <div>
                                            <p className="lh-2 fw-bold">Version : {retapi.BT_version}</p>
                                        </div>
                                    </Toast.Body>
                                </Toast>
                                <div style={{ margin: 'auto' }}>
                                    <div>
                                        <FormControlLabel control={<Checkbox inputRef={cb_upload_lcd} onChange={(event) => onCheckboxLCDChecked(event)} checked={lcd_checked} />} label="LCD" />
                                        <FormControlLabel control={<Checkbox inputRef={cb_upload_main} onChange={(event) => onCheckboxMainChecked(event)} checked={main_checked} />} label="Main" />
                                        <FormControlLabel control={<Checkbox inputRef={cb_upload_bt} onChange={(event) => onCheckboxBTChecked(event)} checked={bt_checked} />} label="BT" />
                                    </div>
                                    <div>
                                        <div style={{ marginBottom: '1%' }}>
                                            Version :
                                            <Select
                                                value={Version1}
                                                onChange={handleVersion1}
                                            >
                                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                                                    <MenuItem key={number} value={number}>
                                                        {number}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Select style={{ marginLeft: '1%' }}
                                                value={Version2}
                                                onChange={handleVersion2}
                                            >
                                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                                                    <MenuItem key={number} value={number}>
                                                        {number}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <input ref={internalinputFileRef} type="file" name="fileInternal" onChange={(event) => onInternalFileChangeHandler(event)} />
                                        {internalSelectedFile ? lcd_checked ?
                                            our_serial_keepAlive.length == 0 || our_serial_keepAlive.isUploading ?
                                                <Button disabled="true" variant="contained" onClick={(event) => onUpdateLcdClickHandler()}>Upload LCD Firmware </Button>
                                                : <Button variant="contained" onClick={(event) => onUpdateLcdClickHandler()}>Upload LCD Firmware </Button>

                                            : "" : ""}
                                        {internalSelectedFile ? main_checked ?
                                            our_serial_keepAlive.length == 0 || our_serial_keepAlive.isUploading ?
                                                <Button disabled="true" variant="contained" onClick={(event) => onUpdateMainClickHandler()}>Upload Main Firmware </Button>
                                                : <Button variant="contained" onClick={(event) => onUpdateMainClickHandler()}>Upload Main Firmware </Button>
                                            : "" : ""}
                                        {internalSelectedFile ? bt_checked ?
                                            our_serial_keepAlive.length == 0 || our_serial_keepAlive.isUploading ?
                                                <Button disabled="true" variant="contained" onClick={(event) => onUpdateBtClickHandler()}>Upload BT Firmware </Button>
                                                : <Button variant="contained" onClick={(event) => onUpdateBtClickHandler()}>Upload BT Firmware </Button>
                                            : "" : ""}
                                        {our_serial_keepAlive.isUploading ?
                                            <div>
                                                <progress max={our_serial_keepAlive.upload_filesize} value={our_serial_keepAlive.upload_size_sended}></progress>
                                                <h6 style={{ margin: '3%' }}>Firmware transfer...</h6>
                                            </div>
                                            : ""}

                                    </div>
                                </div>
                            </Card.Body>
                            : <Card.Body style={{ display: "flex", flexWrap: "wrap" }}></Card.Body>}
                    </Card>
                    <Card style={{ marginBottom: '1%' }}>
                        <Card.Title style={{ margin: '1%' }}>Antennes UWB</Card.Title>
                        <Card.Body style={{ display: "flex", flexWrap: "wrap" }}>
                            {retapi ? retapi.UwbAnt_initstate[0] != 255 ?
                                <Toast style={{ marginLeft: '1%', marginTop: "1%" }}>
                                    <Toast.Header>
                                        <strong className="me-auto">Antenne UWB AVG (0)</strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        {retapi ?
                                            <div>
                                                <p className="lh-2 fw-bold">Version : {retapi.UwbAnt_version_main[0]}</p>
                                                {(retapi.UwbAnt_initstate[0] & 0x02) ? <p className="lh-1 text-success">UWB OK</p> : <p className="lh-1 text-danger">UWB ERROR</p>}
                                                {(retapi.UwbAnt_initstate[0] & 0x04) ? <p className="lh-1 text-success">FLASH OK</p> : <p className="lh-1 text-danger">FLASH ERROR</p>}
                                                {(retapi.UwbAnt_initstate[0] & 0x08) ? <p className="lh-1 text-success">CAN OK</p> : <p className="lh-1 text-danger">CAN ERROR</p>}
                                                {(retapi.UwbAnt_initstate[0] & 0x10) ? <p className="lh-1 text-success">LUMI OK</p> : <p className="lh-1 text-danger">LUMI ERROR</p>}
                                            </div>
                                            : ""}
                                    </Toast.Body>
                                </Toast> : "" : ""}

                            {retapi ? retapi.UwbAnt_initstate[1] != 255 ?
                                <Toast style={{ marginLeft: '1%', marginTop: "1%" }}>
                                    <Toast.Header>
                                        <strong className="me-auto">Antenne UWB AVD (1)</strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        {retapi ?
                                            <div>
                                                <p className="lh-2 fw-bold">Version : {retapi.UwbAnt_version_main[1]}</p>
                                                {(retapi.UwbAnt_initstate[1] & 0x02) ? <p className="lh-1 text-success">UWB OK</p> : <p className="lh-1 text-danger">UWB ERROR</p>}
                                                {(retapi.UwbAnt_initstate[1] & 0x04) ? <p className="lh-1 text-success">FLASH OK</p> : <p className="lh-1 text-danger">FLASH ERROR</p>}
                                                {(retapi.UwbAnt_initstate[1] & 0x08) ? <p className="lh-1 text-success">CAN OK</p> : <p className="lh-1 text-danger">CAN ERROR</p>}
                                                {(retapi.UwbAnt_initstate[1] & 0x10) ? <p className="lh-1 text-success">LUMI OK</p> : <p className="lh-1 text-danger">LUMI ERROR</p>}
                                            </div>
                                            : ""}
                                    </Toast.Body>
                                </Toast> : "" : ""}

                            {retapi ? retapi.UwbAnt_initstate[2] != 255 ?
                                <Toast style={{ marginLeft: '1%', marginTop: "1%" }}>
                                    <Toast.Header>
                                        <strong className="me-auto">Antenne UWB AR (2)</strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        {retapi ?
                                            <div>
                                                <p className="lh-2 fw-bold">Version : {retapi.UwbAnt_version_main[2]}</p>
                                                {(retapi.UwbAnt_initstate[2] & 0x02) ? <p className="lh-1 text-success">UWB OK</p> : <p className="lh-1 text-danger">UWB ERROR</p>}
                                                {(retapi.UwbAnt_initstate[2] & 0x04) ? <p className="lh-1 text-success">FLASH OK</p> : <p className="lh-1 text-danger">FLASH ERROR</p>}
                                                {(retapi.UwbAnt_initstate[2] & 0x08) ? <p className="lh-1 text-success">CAN OK</p> : <p className="lh-1 text-danger">CAN ERROR</p>}
                                                {(retapi.UwbAnt_initstate[2] & 0x10) ? <p className="lh-1 text-success">LUMI OK</p> : <p className="lh-1 text-danger">LUMI ERROR</p>}
                                            </div>
                                            : ""}
                                    </Toast.Body>
                                </Toast> : "" : ""}

                            {retapi ? retapi.UwbAnt_initstate[3] != 255 ?
                                <Toast style={{ marginLeft: '1%', marginTop: "1%" }}>
                                    <Toast.Header>
                                        <strong className="me-auto">Antenne UWB VL (3)</strong>
                                    </Toast.Header>
                                    <Toast.Body>
                                        {retapi ?
                                            <div>
                                                <p className="lh-2 fw-bold">Version : {retapi.UwbAnt_version_main[3]}</p>
                                                {(retapi.UwbAnt_initstate[3] & 0x02) ? <p className="lh-1 text-success">UWB OK</p> : <p className="lh-1 text-danger">UWB ERROR</p>}
                                                {(retapi.UwbAnt_initstate[3] & 0x04) ? <p className="lh-1 text-success">FLASH OK</p> : <p className="lh-1 text-danger">FLASH ERROR</p>}
                                                {(retapi.UwbAnt_initstate[3] & 0x08) ? <p className="lh-1 text-success">CAN OK</p> : <p className="lh-1 text-danger">CAN ERROR</p>}
                                                {(retapi.UwbAnt_initstate[3] & 0x10) ? <p className="lh-1 text-success">LUMI OK</p> : <p className="lh-1 text-danger">LUMI ERROR</p>}
                                            </div>
                                            : ""}
                                    </Toast.Body>
                                </Toast> : "" : ""}

                            {retapi ? (retapi.UwbAnt_initstate[0] != 255 || retapi.UwbAnt_initstate[1] != 255 || retapi.UwbAnt_initstate[2] != 255 || retapi.UwbAnt_initstate[3] != 255) ?
                                <div style={{ margin: 'auto' }}>
                                    <div style={{ marginBottom: '1%' }}>
                                        Version :
                                        <Select
                                            value={Version1UWB}
                                            onChange={handleVersion1UWB}
                                        >
                                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                                                <MenuItem key={number} value={number}>
                                                    {number}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Select style={{ marginLeft: '1%' }}
                                            value={Version2UWB}
                                            onChange={handleVersion2UWB}
                                        >
                                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                                                <MenuItem key={number} value={number}>
                                                    {number}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                    <input ref={uwbAntinputFileRef} type="file" name="file" onChange={(event) => onUwbAntFileChangeHandler(event)} />
                                    {uwbAntselectedFile != null ?
                                        retapi.UwbAnt_DFU_running || our_serial_keepAlive.length == 0 || our_serial_keepAlive.isUploading ?
                                            <Button disabled="true" variant="contained" onClick={(event) => onUpdateUwbClickHandler()}>Upload UWB Firmware </Button>
                                            : <Button variant="contained" onClick={(event) => onUpdateUwbClickHandler()}>Upload UWB Firmware </Button>
                                        : ""}
                                    {connected_devices != null ?
                                        Object.entries(connected_devices).map(([id, { SerialStr, connection_Date, disconnection_Date, IsConnected, last_keepAlive, isUploading, upload_filesize, upload_size_sended, upload_result }]) => (
                                            SerialStr === serial ?
                                                isUploading === true ?
                                                    <div key={id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                                                        <progress max={upload_filesize} value={upload_size_sended}></progress>
                                                        <h6 style={{ margin: '3%' }}>Firmware transfer...</h6>
                                                    </div>
                                                    : ""
                                                : ""
                                        )) : ""}
                                    {retapi.UwbAnt_DFU_running ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                                            <progress max={retapi.UwbAnt_DFU_bytes_tosend} value={retapi.UwbAnt_DFU_bytes_sended}></progress>
                                            <h6 style={{ margin: '3%' }}>Firmware Flash addr{retapi.UwbAnt_DFU_currentAdress}...</h6>
                                        </div>
                                        : ""}
                                </div>
                                : "" : ""}
                        </Card.Body>
                    </Card>
                </div> : ""}
        </div>
    );
}

export default EnginUpdate;