import React from 'react';
const ConstructionPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <h1 style={{ marginTop: '20px' }}>Page en construction</h1>
            <p>Cette page est en cours de développement. Revenez bientôt !</p>
        </div>
    );
};

export default ConstructionPage;
