export const deviceTemplates = [
    { name: 'ENGIN_VL', sizeY: 4.3, sizeX: 1.9, offsetY: -3, offsetX: -1 },
    { name: 'ENGIN_CAMION_SEMI', sizeY: 12, sizeX: 2.6, offsetY: -11.5, offsetX: -2 },
    { name: 'ENGIN_CAMION_TOUPIE', sizeY: 10, sizeX: 2.5, offsetY: -9.5, offsetX: -2 },
    { name: 'ENGIN_CAMION_BENNE', sizeY: 9, sizeX: 2.5, offsetY: -8.5, offsetX: -2 },
    { name: 'ENGIN_CHARGEUR_TINY', offsetX: -2, offsetY: -3, sizeX: 2.2, sizeY: 5.5 },
    { name: 'ENGIN_CHARGEUR_SMALL', offsetX: -2.7, offsetY: -4.5, sizeX: 3.2, sizeY: 8.4 },
    { name: 'ENGIN_CHARGEUR_NORMAL', sizeY: 9.7, sizeX: 3.5, offsetY: -5, offsetX: -3 },
    { name: 'ENGIN_CHARGEUR_BIG', sizeY: 14, sizeX: 4.9, offsetY: -8, offsetX: -4.4 },
    { name: 'ENGIN_CHARGEUR_GIANT', sizeY: 16, sizeX: 5, offsetY: -8, offsetX: -4.5 },
    { name: 'ENGIN_DUMPER_RIGIDE_TINY', sizeY: 8.75, sizeX: 4, offsetY: -5.75, offsetX: -4 },
    { name: 'ENGIN_DUMPER_RIGIDE_SMALL', sizeY: 9.3, sizeX: 4.5, offsetY: -6.3, offsetX: -4.5 },
    { name: 'ENGIN_DUMPER_RIGIDE_NORMAL', sizeY: 11, sizeX: 5.6, offsetY: -5.5, offsetX: -5.6 },
    { name: 'ENGIN_DUMPER_RIGIDE_BIG', sizeY: 12, sizeX: 6.5, offsetY: -8, offsetX: -6.5 },
    { name: 'ENGIN_DUMPER_RIGIDE_GIANT', sizeY: 14.6, sizeX: 9.2, offsetY: -10.6, offsetX: -9.2 },
    { name: 'ENGIN_DUMPER_ARTICU_NORMAL', sizeY: 10.7, sizeX: 3, offsetY: -8.7, offsetX: -3 },
    { name: 'ENGIN_DUMPER_ARTICU_BIG', sizeY: 11.5, sizeX: 3.5, offsetY: -9.5, offsetX: -3.5 },
    { name: 'ENGIN_BULLDOZER_TINY', sizeY: 4, sizeX: 2.7, offsetY: 0, offsetX: -2.7 },
    { name: 'ENGIN_BULLDOZER_SMALL', sizeY: 4.75, sizeX: 3.3, offsetY: -0.5, offsetX: -3.3 },
    { name: 'ENGIN_BULLDOZER_NORMAL', sizeY: 8.3, sizeX: 3.3, offsetY: -0.5, offsetX: -3.3 },
    { name: 'ENGIN_BULLDOZER_BIG', sizeY: 9, sizeX: 3.3, offsetY: -0.5, offsetX: -3.3 },
    { name: 'ENGIN_BULLDOZER_GIANT', sizeY: 10.5, sizeX: 5.5, offsetY: -0.5, offsetX: -5.5 },
    { name: 'ENGIN_PELLE_RETRO_SMALL', sizeY: 6.5, sizeX: 4, offsetY: -4.5, offsetX: -1 },
    { name: 'ENGIN_PELLE_RETRO_NORMAL', sizeY: 7.5, sizeX: 5, offsetY: -5.5, offsetX: -1 },
    { name: 'ENGIN_PELLE_RETRO_BIG', sizeY: 8.5, sizeX: 5, offsetY: -6.5, offsetX: -1 },
    { name: 'ENGIN_PELLE_RETRO_GIANT', sizeY: 12.5, sizeX: 6, offsetY: -10.5, offsetX: -1 },
    { name: 'ENGIN_PELLE_PNEU_NORMAL', sizeY: 5, sizeX: 2.8, offsetY: -3, offsetX: -1 },
    { name: 'ENGIN_PELLE_PNEU_BIG', sizeY: 6, sizeX: 3, offsetY: -4, offsetX: -1 },
    { name: 'ENGIN_PELLE_BUTTE_NORMAL', sizeY: 11.5, sizeX: 6, offsetY: -8.5, offsetX: -1.5 },
    { name: 'ENGIN_PELLE_BUTTE_BIG', sizeY: 12.5, sizeX: 6, offsetY: -9.5, offsetX: -1.5 },
    { name: 'ENGIN_PELLE_BUTTE_GIANT', sizeY: 14.7, sizeX: 8, offsetY: -11.7, offsetX: -1.5 },
    { name: 'ENGIN_FOREUSE_NORMAL', sizeY: 6, sizeX: 2.4, offsetY: -4, offsetX: -1.2 },
    { name: 'ENGIN_FOREUSE_BIG', sizeY: 9, sizeX: 4.5, offsetY: -7, offsetX: -2.5 },
    { name: 'ENGIN_NIVELEUSE_NORMAL', sizeY: 10, sizeX: 2.5, offsetY: -5, offsetX: -1.9 },
    { name: 'ENGIN_NIVELEUSE_BIG', sizeY: 11.5, sizeX: 5, offsetY: -5, offsetX: -3 },
    { name: 'ENGIN_ELEVATEUR_TT', sizeY: 6, sizeX: 2.5, offsetY: -3, offsetX: -1.2 },
    { name: 'ENGIN_ELEVATEUR_PORTUAIRE', sizeY: 4.5, sizeX: 2.8, offsetY: -2, offsetX: -1.4 },
    { name: 'ENGIN_ELEVATEUR_INDUS', sizeY: 3.5, sizeX: 1.2, offsetY: -1, offsetX: -1 },
    { name: 'ENGIN_COMPACTEUR', sizeY: 5.8, sizeX: 2.3, offsetY: -3, offsetX: -1.9 },
    { name: 'ENGIN_NACELLE_AUTOMOTRICE', sizeY: 6, sizeX: 2.5, offsetY: -5, offsetX: -1 },
    { name: 'ENGIN_RABOTEUSE', sizeY: 10.5, sizeX: 2, offsetY: -4, offsetX: -0.5 },
    { name: 'ENGIN_SCRAP', sizeY: 13.2, sizeX: 3.5, offsetY: -12, offsetX: -1 },
    { name: 'ENGIN_TRACTEUR_ET_CUVE', sizeY: 15, sizeX: 2.7, offsetY: -11.5, offsetX: -1.35 },
    { name: 'ENGIN_FINISSEUR', sizeY: 5, sizeX: 3, offsetY: -2, offsetX: -3 },
    { name: 'ENGIN_ALIMENTATEUR', sizeY: 13, sizeX: 3, offsetY: -4, offsetX: -1 },
    { name: 'ENGIN_PONT_ROULANT', sizeY: 16, sizeX: 7.5, offsetY: -3, offsetX: -3.75 },
    { name: 'ENGIN_AUTOLEVEUR', sizeY: 21.5, sizeX: 3, offsetY: -20, offsetX: -2.5 },
    { name: 'ENGIN_MINICHARGEUR', sizeY: 3, sizeX: 1, offsetY: -1, offsetX: -1 },
    { name: 'ENGIN_MINIPELLE', sizeY: 2.3, sizeX: 1.9, offsetY: -1, offsetX: -1 },
    { name: 'ENGIN_PORTE_BOBINE_NORMAL', sizeY: 8.5, sizeX: 3.5, offsetY: -3.5, offsetX: -0.75 },
    { name: 'ENGIN_PORTE_BOBINE_BIG', sizeY: 13, sizeX: 4.5, offsetY: -8, offsetX: -2.5 },
    { name: 'ENGIN_BASIQUE_VL', sizeY: 5, sizeX: 5, offsetY: 0, offsetX: 0 },
    { name: 'ENGIN_BASIQUE_PL', sizeY: 15, sizeX: 15, offsetY: 0, offsetX: 0 },
    { name: 'TYPE_PIETON', sizeY: 1, sizeX: 1, offsetY: 0.5, offsetX: 0.5 },
];
