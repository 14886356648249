import React, {useEffect, useRef, useState} from "react";
import { Select, MenuItem, Button } from "@mui/material";
import axios from "axios";
const FilteredMovingDanger = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [selectedSpeed, setSelectedSpeed] = useState("all");
    const [selectedInRearGear, setInRearGear] = useState("all");
    const [selectedCrossing, setSelectedCrossing] = useState("all");
    const [selectedWorksite, setSelectedWorksite] = useState('');
    const [Worksite, setWorksite] = useState([]);
    const stopdate_input_ref = useRef(null);
    const startdate_input_ref = useRef(null);
    const [selectedStopDate, setSelectedStopDate] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [movingDanger, setMovingDanger] = useState([]);
    const handleSpeedChange = (event) => {
        setSelectedSpeed(event.target.value);
    };

    const handleCrossing = (event) => {
        setSelectedCrossing(event.target.value);
    };

    const handleInRearGear = (event) => {
        setInRearGear(event.target.value);
    };

    useEffect(() => {
        axios.get(apiUrl + `/api/user/worksite`, {
            headers: {'x-access-token': sessionStorage.getItem("token")}
        })
            .then(res => {
                const worksite = res.data.sort((a, b) => a.worksiteid - b.worksiteid);
                setWorksite(worksite);
            })
    }, []);

    useEffect(() => {

        var start, stop;
        start = selectedStartDate;
        stop = selectedStopDate;
        stopdate_input_ref.current.value = stop;
        startdate_input_ref.current.value = start;

        const dateStart = new Date(selectedStartDate);
        const dateStop = new Date(selectedStopDate);
        dateStart.setHours(0, 0, 1);
        start = new Date(dateStart);

        dateStop.setHours(23, 59, 59);
        stop = new Date(dateStop);

        if(selectedStopDate !== "" && selectedStopDate !== "" && selectedWorksite !== "") {
            axios.post(apiUrl + `/api/user/movingdangersperiodserialfilter`,
                {startdate: start, stopdate: stop, worksiteid: selectedWorksite},

                {
                    headers: {'x-access-token': sessionStorage.getItem("token")}
                })
                .then(res => {
                    const jsonString = res.data;
                    const data = jsonString.map((data) => {
                        return data.list_movingdangers.split('},{').map((str) => {
                            // Supprime les accolades des extrémités
                            const position = str.replace('{', '').replace('}', '');
                            // Divise les valeurs séparées par des virgules et les stocke dans un tableau
                            const values = position.split(',');
                            // Convertit les valeurs de chaîne de caractères en types appropriés
                            return {
                                serial: data.serial,
                                dateStart: convertUTCDateToLocalDate(new Date(values[0])),
                                dateEnd: convertUTCDateToLocalDate(new Date(values[1])),
                                Type: parseInt(values[2]),
                                SpeedStart: values[3],
                                SpeedStop: values[4],
                                SpeedAverage: values[5],
                                InRearGear: values[6],
                                Angle: values[7],
                                CrossedSerial: values[8],
                                CrossedAngle: values[9],
                                CrossedSpeed: values[10],
                                CrossedTypeEnum: values[11],

                            };
                        });
                    });
                    const allDataArray = data.reduce((acc, curr) => acc.concat(curr), []);
                    setMovingDanger(allDataArray);
                })
        }
    }, [selectedStartDate,selectedStopDate,selectedWorksite]);

    useEffect(() => {
        if(Worksite.length > 0) {
            setSelectedWorksite(Worksite[0].worksiteid)
        }
    }, [Worksite]);

    
    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    const handleWorksiteChange = (event) => {
        setSelectedWorksite(event.target.value);
    };

    if (selectedStopDate === "") {
        const ndate = formatDate();
        setSelectedStopDate(ndate);
        setSelectedStartDate(ndate);

    }

    function formatDate() {
        var date = new Date();

        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;

        var today = year + "-" + month + "-" + day;
        return today;
    }

    const handleStopDateChange = (event) => {
        const date = event.target.value;
        setSelectedStopDate(date);
        if (startdate_input_ref.current.value > date)
            setSelectedStartDate(date);
    };

    const handleStartDateChange = (event) => {
        const date = event.target.value;
        setSelectedStartDate(date);
        if (stopdate_input_ref.current.value < date)
            setSelectedStopDate(date);
    };



    return (
        <div>
            <h1>Page de filtres</h1>
            <Select style={{ marginLeft:'1%'}} value={selectedWorksite}  onChange={handleWorksiteChange}>
                {Worksite.map((worksite, index) => (
                    <MenuItem key={index} value={worksite.worksiteid} >{worksite.worksitename}</MenuItem>
                ))}
            </Select>
            <label style={{marginTop: '1%', marginBottom: '1%' , marginLeft: '2%'}}>Start : </label>
            <input style={{width: '150px', height: '30px', margin: '10px', fontSize: '16px'}} type="date"
                   ref={startdate_input_ref} onChange={handleStartDateChange}/>
            <label style={{marginTop: '1%', marginBottom: '1%'}}>End : </label>
            <input style={{width: '150px', height: '30px', margin: '10px', fontSize: '16px'}} type="date"
                   ref={stopdate_input_ref} onChange={handleStopDateChange}/>

            <br/>
            <Select style={{ marginLeft:'1%' , marginTop:'1%'}} value={selectedSpeed} onChange={handleSpeedChange}>
                <MenuItem value="all">Toutes les vitesses</MenuItem>
                <MenuItem value="10">10 km/h</MenuItem>
                <MenuItem value="20">20 km/h</MenuItem>
                <MenuItem value="30">30 km/h</MenuItem>
                <MenuItem value="40">40 km/h</MenuItem>
                <MenuItem value="50">50 km/h</MenuItem>
            </Select>
            <Select style={{ marginLeft:'1%'}} value={selectedInRearGear} onChange={handleInRearGear}>
                <MenuItem value="all">Tous les sens</MenuItem>
                <MenuItem value="mav">Marche Avant</MenuItem>
                <MenuItem value="mar">Marche arrière</MenuItem>
            </Select>

            <Select style={{ marginLeft:'1%'}} value={selectedCrossing} onChange={handleCrossing}>
                <MenuItem value="all">Tous les croisement</MenuItem>
                <MenuItem value="cdf">croisement de face</MenuItem>
                <MenuItem value="cdc">croisement de côté</MenuItem>
                <MenuItem value="cdd">croisement de derrière</MenuItem>
            </Select>
        </div>
    );
}
export default FilteredMovingDanger;