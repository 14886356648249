// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paperSetting{
    padding: 40px;
    max-width: 500px;
    border-radius: 12px;
}

.selectWorksiteSetting{
    margin-bottom: 20px;
}

.accordionSetting{
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
}

.accordionSummarySettingBlue{
    background: #2196f3 !important;
    color: white !important;
    border-radius: 4px !important;
}
.accordionSummarySettingGreen{
    background: #4caf50 !important;
    color: white !important;
    border-radius: 4px !important;
}

.displayflexAlignCenter{
    display: flex;
    align-items: center;
}

.marginRight10Setting{
    margin-right: 10px;
}

.marginLeftAutoSetting{
    margin-left: auto;
}

.divProductionSetting{
    display: flex;
    align-items: center ;
    margin-top:2%;
}

.formRatioRouleSetting{
    min-width: 180px!important;
    margin-right: 10px!important;
}`, "",{"version":3,"sources":["webpack://./src/components/user/Setting/Setting.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,uBAAuB;IACvB,6BAA6B;AACjC;AACA;IACI,8BAA8B;IAC9B,uBAAuB;IACvB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;AAChC","sourcesContent":[".paperSetting{\n    padding: 40px;\n    max-width: 500px;\n    border-radius: 12px;\n}\n\n.selectWorksiteSetting{\n    margin-bottom: 20px;\n}\n\n.accordionSetting{\n    margin-bottom: 20px;\n    border-radius: 8px;\n    overflow: hidden;\n}\n\n.accordionSummarySettingBlue{\n    background: #2196f3 !important;\n    color: white !important;\n    border-radius: 4px !important;\n}\n.accordionSummarySettingGreen{\n    background: #4caf50 !important;\n    color: white !important;\n    border-radius: 4px !important;\n}\n\n.displayflexAlignCenter{\n    display: flex;\n    align-items: center;\n}\n\n.marginRight10Setting{\n    margin-right: 10px;\n}\n\n.marginLeftAutoSetting{\n    margin-left: auto;\n}\n\n.divProductionSetting{\n    display: flex;\n    align-items: center ;\n    margin-top:2%;\n}\n\n.formRatioRouleSetting{\n    min-width: 180px!important;\n    margin-right: 10px!important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
