import React, {useEffect, useState} from "react";
import axios from "axios";
import {Toast} from "react-bootstrap";
import {Button} from "@mui/material";
import {Link} from "react-router-dom";


const apiUrl = process.env.REACT_APP_API_URL;


function BTDevice() {

    let tmptab = window.location.href.split('/');
    const serial = tmptab[tmptab.length - 1];
    const [enginBt, setEnginBt] = useState([]);
    useEffect(() => {
            axios.post(apiUrl + "/api/admin/EnginGetBT", {serial: serial}, {
                headers: {'x-access-token': sessionStorage.getItem("token")}
            }).then(res => {
                if (res.data.success !== false)
                    setEnginBt(res.data.last_engin_bt)
            })
    }, [])

    function uint32ToHexString(serialNumber) {
        // Assurez-vous que le numéro de série est un nombre entier
        serialNumber = parseInt(serialNumber, 10);

        // Vérifiez si le numéro de série est un nombre valide
        if (isNaN(serialNumber) || serialNumber < 0) {
            return 'Invalid serial number';
        }

        // Convertissez le numéro de série en chaîne hexadécimale
        const hexString = serialNumber.toString(16).toUpperCase();

        // Ajoutez des zéros non significatifs au début si nécessaire
        const paddedHexString = '0'.repeat(6 - hexString.length) + hexString;

        return paddedHexString;
    }



    return (

        <div>
            <>Piétons</>
            <div style={{display:'flex'}}>
            {enginBt.length > 0 ? enginBt.map((item, index) => (
                <Toast key={index} style={{marginLeft : '1%' , marginTop : "1%" }}>
                    <Toast.Header closeButton={false}>
                        {uint32ToHexString(item.serial)}
                    </Toast.Header>
                    <Toast.Body style={{textAlign:'center'}}>
                       <p>type : {item.type}</p>
                        <p> rssi : {item.rssi}</p>
                        <p> flags :  {item.flags}</p>
                        <Link to={'/admin/ReflashVisualization/' + serial + "/" + item.serial}><Button variant="contained">Reflash P1</Button></Link>
                    </Toast.Body>
                </Toast>
                )) : ""}
            </div>
        </div>
    );
}

export default BTDevice;