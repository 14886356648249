import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import axios from "axios";
import Leaflet from "Components/utils/leaflet";
import {
    FormControl, IconButton,
    InputLabel,
    MenuItem,
    Select,Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import Button from "@mui/material/Button";

const DashboardSecurite = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const [selectedData, setSelectedData] = useState(null);
    const [deviceName, setDeviceName] = useState([]);

    const handleOpenModal = (data) => {
        data.worksiteid = location.state.worksiteid;
        setSelectedData(data);
    };

    const handleCloseModal = () => {
        setSelectedData(null);
    };

    useEffect(() => {
        if(sessionStorage.getItem("Roles").includes("ROLE_ADMIN")) {
            axios.get(apiUrl + `/api/admin/device`, {
                headers: {'x-access-token': sessionStorage.getItem("token")}
            })
                .then(res => {
                    setDeviceName(res.data);
                })
        }
        else {
            axios.get(apiUrl + `/api/user/device`, {
                headers: {'x-access-token': sessionStorage.getItem("token")}
            })
                .then(res => {
                    setDeviceName(res.data);
                })
        }
    }, []);

    function getNamebySerial(serial){
        if(deviceName.length >0 && serial !== "") {
            const device = deviceName.find(device => device.username === serial);
            if(device)
                return device.name_device;
            else
                return "noname";
        }
    }
    return(
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Serial</TableCell>
                        <TableCell>DateStart</TableCell>
                        <TableCell>DateEnd</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>SpeedStart</TableCell>
                        <TableCell>SpeedStop</TableCell>
                        <TableCell>SpeedAverage</TableCell>
                        <TableCell>InRearGear</TableCell>
                        <TableCell>AngleSerial</TableCell>
                        <TableCell>CrossedSerial</TableCell>
                        <TableCell>CrossedAngle</TableCell>
                        <TableCell>CrossedSpeed</TableCell>
                        <TableCell>CrossedTypeEnum</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {location.state.movingDanger.map((item,index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Button onClick={() => handleOpenModal(item)}>Open Danger</Button>
                            </TableCell>
                            <TableCell>{getNamebySerial(item.serial)}({item.serial})</TableCell>
                            <TableCell>{item.dateStart.toString()}</TableCell>
                            <TableCell>{item.dateEnd.toString()}</TableCell>
                            <TableCell>{item.Type}</TableCell>
                            <TableCell>{item.SpeedStart}</TableCell>
                            <TableCell>{item.SpeedStop}</TableCell>
                            <TableCell>{item.SpeedAverage}</TableCell>
                            <TableCell>{item.InRearGear}</TableCell>
                            <TableCell>{item.Angle}</TableCell>
                            <TableCell>{getNamebySerial(item.CrossedSerial)}({item.CrossedSerial})</TableCell>
                            <TableCell>{item.CrossedAngle}</TableCell>
                            <TableCell>{item.CrossedSpeed}</TableCell>
                            <TableCell>{item.CrossedTypeEnum}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Modal open={selectedData !== null} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 1000,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <div>
                        <h2>Moving Danger</h2>
                        {selectedData && (
                            <div>
                                <Leaflet movingDanger={selectedData}/>
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>
        </>
    )


}
export default DashboardSecurite;