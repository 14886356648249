import React, {useEffect, useState} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";


const apiUrl = process.env.REACT_APP_API_URL;


function InfoDevice() {

    let tmptab = window.location.href.split('/');
    const serial = tmptab[tmptab.length - 1];
    const [CombinedInf, setCombinedInf] = useState([]);
    const location = useLocation();

    useEffect(() => {

        axios.get(apiUrl + '/api/admin/CombinedInf', {
            headers: {'x-access-token': sessionStorage.getItem('token')}
        })
            .then(res => {
                const foundObject = res.data.Combined_inf.find(item => item.serial === serial);
                setCombinedInf(foundObject)
            })
    }, [])

    return (

        <div>
            <h2>Données</h2>
            <div style={{display: 'flex'}}>
                <pre><h3>Info 1 et 2</h3> {JSON.stringify(CombinedInf, null, 2)}</pre>
                <pre><h3>KeepAlive</h3> {JSON.stringify(location.state.keepAlive, null, 2)}</pre>
            </div>
        </div>
    );
}

export default InfoDevice;